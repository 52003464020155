import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../@core/modules/theme.module';
import { PasswordComponent } from './password.component';

const COMPONENTS = [
	PasswordComponent,
];

@NgModule({
	imports: [
		CommonModule,
		ThemeModule,
	],
	declarations: [...COMPONENTS],
	entryComponents: COMPONENTS
})
export class PasswordModule { }
