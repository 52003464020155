import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpResponse,
	HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	constructor(public auth: AuthService, private router: Router) { }
	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		request = request.clone({
			setHeaders: {
				Authorization: `Bearer ${this.auth.getToken()}`
			}
		});
		return next.handle(request).pipe(tap(
			(event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					// do stuff with response if you want
					if (event.body && ['INVALID_APP_AUTHENTICATION', 'INVALID_USER_AUTHENTICATION', 'UNAUTHENTICATED', 'UNAUTHORIZED'].indexOf(event.body.status_message) > -1 || (!this.auth.getToken() && event.url !== `${environment.apiUrl}/auth/login`)) {
						this.auth.removeToken();
						this.router.navigate(['login']);
					}
				}
			}, (err: any) => {
				if (err instanceof HttpErrorResponse) {
					if ([401].indexOf(err.status) > -1) {
						// redirect to the login route or show a modal
						this.router.navigate(['login']);
					} else {
						return next.handle(request);
					}
				}
			}
		));
	}
}
