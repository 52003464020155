import { LanguagesObject } from '../../@core/data/languages-object';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';

export class Order implements ITable {
	_id: String;

}

export class OrderError implements IError {

}

export class OrderFilter {
	issues?: string = null;
	product_id?: string = null;
	store_id?: string = null;
	supplier_id?: string = null;
	category_id?: string = null;
	customer_no?: string = null;
	employee_no?: string = null;
	coupon?: string = null;
	status?: Number = null;
	zero_products?: boolean = null;
	sort_by_id?: boolean = null;
	from_orders_total?: number = 0;
	to_orders_total?: number = 0;
	discount_by_wallet?: boolean = null;
	is_sort?: boolean = false;
	active?: string = null;
	direction?: string = null;
	ref?: string = null;
	has_offer?: boolean = null;
	offer_sku?: string = null;
	from_date?: Date = null;
	to_date?: Date = null;
}
