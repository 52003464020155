export class Department {

	public _id: Number = 0;
	public name: String = '';

	constructor(_id: Number, name: String) {
		this._id = _id;
		this.name = name;
	}
}
