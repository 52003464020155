import { ITable } from "src/app/@core/libraries/interfaces/table";
import { IError } from "src/app/@core/libraries/interfaces/error";

export class Employee implements ITable {
	_id: String = "";
	name: String = "";
	username: String = "";
	mobile: String = "";
	password: String = "";
	department: String = "";
	store_id: String = "";
	working_times: {
		from: String;
		to: String;
	} = {
		from: "",
		to: "",
	};
	max_orders: Number = 0;
	status: Boolean = false;
}

export class EmployeeError implements IError {
	name: String = "";
	username: String = "";
	mobile: String = "";
	password: String = "";
	department: String = "";
	store_id: String = "";
	working_times: {
		from: String;
		to: String;
	} = {
		from: "",
		to: "",
	};
	max_orders: String = "";
	status: String = "";
}
