<mat-card class="mat-elevation-z8">

	<mat-toolbar>
		<mat-toolbar-row>
			<button mat-raised-button color="primary" (click)="setNotificationForm()">
				<mat-icon>add_box</mat-icon>
				{{ 'add' | i18n }}
			</button>
			<button mat-raised-button color="link" (click)="setSearchForm()">
				<mat-icon>search</mat-icon>
				{{ 'search' | i18n }}
			</button>
			<span class="spacer"></span>
			<h2 class="title"> {{ 'member_notification' | i18n }} </h2>
		</mat-toolbar-row>
	</mat-toolbar>

	<mat-card *ngIf="notificationForm">
		<div class="form-row">
			<mat-form-field appearance="outline" class="form-group col-md-12">
				<mat-label>{{ 'title' | i18n }}</mat-label>
				<input matInput [(ngModel)]="item.title" [errorStateMatcher]='validate.title'
					placeholder="{{ 'title' | i18n }}" />
				<mat-error>{{ errors.title }}</mat-error>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-12">
				<mat-label>{{ 'url' | i18n }}</mat-label>
				<input type="url" matInput [(ngModel)]="item.url" [errorStateMatcher]='validate.url'
					placeholder="{{ 'url' | i18n }}" />
				<mat-error>{{ errors.url }}</mat-error>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-12">
				<mat-label>{{ 'body' | i18n }}</mat-label>
				<input matInput [(ngModel)]="item.body" [errorStateMatcher]='validate.body'
					placeholder="{{ 'body' | i18n }}" />
				<mat-error>{{ errors.body }}</mat-error>
			</mat-form-field>
		</div>
		<div mat-dialog-actions>
			<button mat-flat-button color="primary" (click)="send()" [disabled]="!item.title || !item.body || isLoading">{{ 'send' | i18n }}</button>
			<button mat-flat-button color="warn" (click)="setNotificationForm()">{{ 'close' | i18n }}</button>
		</div>
	</mat-card>


	<mat-card *ngIf="searchForm">
		<div class="form-row">

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'from_date' | i18n }}</mat-label>
				<input matInput [matDatepicker]="from_picker" [(ngModel)]="filter.from_date"
					placeholder="{{ 'from_date' | i18n }}" (keyup)="get()">
				<mat-datepicker-toggle matSuffix [for]="from_picker"></mat-datepicker-toggle>
				<mat-datepicker #from_picker></mat-datepicker>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'to_date' | i18n }}</mat-label>
				<input matInput [matDatepicker]="to_picker" [(ngModel)]="filter.to_date"
					placeholder="{{ 'to_date' | i18n }}" (keyup)="get()">
				<mat-datepicker-toggle matSuffix [for]="to_picker"></mat-datepicker-toggle>
				<mat-datepicker #to_picker></mat-datepicker>
			</mat-form-field>

			<div class="form-group col-md-4">
			</div>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'from_orders_total' | i18n }}</mat-label>
				<input matInput [matorders_totalpicker]="from_picker" [(ngModel)]="filter.from_orders_total"
					placeholder="{{ 'from_orders_total' | i18n }}" (keyup)="get()">
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'to_orders_total' | i18n }}</mat-label>
				<input matInput [matorders_totalpicker]="to_picker" [(ngModel)]="filter.to_orders_total"
					placeholder="{{ 'to_orders_total' | i18n }}" (keyup)="get()">
			</mat-form-field>

		</div>
	</mat-card>

	<mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
		<mat-progress-spinner color="primary" mode="indeterminate">
		</mat-progress-spinner>
	</mat-card>

	<table mat-table [dataSource]="items">

		<!-- Checkbox Column -->
		<ng-container matColumnDef="select">
			<th mat-header-cell *matHeaderCellDef>
				<mat-checkbox (change)="$event ? masterToggle() : null;"
					[checked]="selection.hasValue() && isAllSelected()"
					[indeterminate]="selection.hasValue() && !isAllSelected()">
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row">
				<mat-checkbox (click)="$event.stopPropagation()"
					(change)="$event ? selection.toggle(row) : null;updateSelectionIds()"
					[checked]="selection.isSelected(row)">
				</mat-checkbox>
			</td>
        </ng-container>

		<!-- _id Column -->
		<ng-container matColumnDef="_id">
			<th mat-header-cell *matHeaderCellDef> # </th>
			<td mat-cell *matCellDef="let i"> <b [outerHTML]="i._id | _id"></b> </td>
		</ng-container>

		<!-- Full Name Column -->
		<ng-container matColumnDef="fullname">
			<th mat-header-cell *matHeaderCellDef> {{ 'name' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ i['fullname'] }} </td>
		</ng-container>

		<!-- Mobile Column -->
		<ng-container matColumnDef="mobile">
			<th mat-header-cell *matHeaderCellDef> {{ 'mobile' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{i.mobile}} </td>
		</ng-container>

		<!-- Orders count Column -->
		<ng-container matColumnDef="orders_count">
			<th mat-header-cell *matHeaderCellDef> {{ 'orders_count' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{i.orders_count}} </td>
		</ng-container>

		<!-- Order Total Column -->
		<ng-container matColumnDef="orders_total">
			<th mat-header-cell *matHeaderCellDef> {{ 'orders_total' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ i.orders_total }} </td>
		</ng-container>

		<!-- Tools Column -->
		<ng-container matColumnDef="tools">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let i">
				<div class="btn-tools">

				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="status_{{row.status}}"></tr>
	</table>

	<mat-paginator showFirstLastButtons [length]="pagination.length" [pageSize]="pagination.pageSize"
		[pageSizeOptions]="pagination.pageSizeOptions" (page)="next($event)">
	</mat-paginator>
</mat-card>
