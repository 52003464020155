import { Injectable } from '@angular/core';
import { BaseModelService } from 'src/app/@core/services/base-model.service';

@Injectable({
	providedIn: 'root',
})


export class TickerService extends BaseModelService {

	model: String = 'ticker';

}
