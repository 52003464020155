import { LanguagesObject } from '../../@core/data/languages-object';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';

export class VariantOption {

}

export class Variant {

}

/* export class Option {
	name: LanguagesObject = new LanguagesObject();
	type: 'single' | 'multiple';
	required: Boolean = false;
	choices: OptionChoice[] = [];
}

export class OptionError {
	name: LanguagesObject = new LanguagesObject();
	type: String = '';
	required: String = '';
	choices: OptionChoiceError[];
}

export class OptionChoice {
	name: LanguagesObject = new LanguagesObject();
	price: number = 0
}

export class OptionChoiceError {
	name: LanguagesObject = new LanguagesObject();
	price: String = '';
}
 */
export class Product implements ITable {

	_id: String = '';
	name: LanguagesObject = new LanguagesObject();
	contents: LanguagesObject = new LanguagesObject();
	description: LanguagesObject = new LanguagesObject();
	keywords: LanguagesObject = new LanguagesObject();
	variants: Array<any> = [];
	country_id: String = '';
	rank_id: String = '';
	brand_id: String = '';
	features: Array<Object> = [];
	feature_sorting: Number = 0;
	supplier_id: String = '';
	code: String = '';
	sku: String = '';
	soft_code: String = '';
	uom: String = '';
	barcode: String = '';
	barcode_2: String = '';
	floor: String = '';
	price: Number = 0;
	exchange_with_points: Boolean = false;
	points: Number = 0;
	old_price: Number = 0;
	max_quantity_cart: Number = 0;
	preparation_time: Number = 30;
	weight: Number = 0;
	picture: String = '';
	rack_zone: String = '';
	gallery_pictures: Array<String> = [];
	status: Boolean = false;
	prod_n_categoryObj: Object = {};
	prod_n_categoryArr: Array<Object> = [];
	prod_n_storeObj: Object = {};
	prod_n_storeArr: Array<Object> = [];
	discount_price_valid_until: Date | null = null;
	show_discount_percentage: Boolean = false;
	free_shipping: Boolean = false;
	is_gift: Boolean = false;
	fast_shipping: Boolean = false;
	fast_shipping_cost: Number = null;
	vip_price: Number = 0;
	vip_old_price: Number = 0;
	vip_discount_price_valid_until: Date | null = null;
	pick_sorting: Number = 0;
	single_order_per_user: Boolean = false;
	//options: Option[] = [];
	base_product: any = {
		sku: "",
		item_quantity: null,
	}
}

export class ProductError implements IError {

	name: LanguagesObject = new LanguagesObject();
	contents: LanguagesObject = new LanguagesObject();
	description: LanguagesObject = new LanguagesObject();
	keywords: LanguagesObject = new LanguagesObject();
	country_id: String = '';
	rank_id: String = '';
	brand_id: String = '';
	features: String = '';
	feature_sorting: String = '';
	supplier_id: String = '';
	sku: String = '';
	soft_code: String = '';
	uom: String = '';
	barcode: String = '';
	barcode_2: String = '';
	floor: String = '';
	price: String = '';
	exchange_with_points: String = '';
	points: String = '';
	old_price: String = '';
	max_quantity_cart: String = '';
	weight: String = '';
	picture: String = '';
	rack_zone: String = '';
	gallery_pictures: String = '';
	preparation_time: String = '';
	status: String = '';
	discount_price_valid_until: String = '';
	show_discount_percentage: String = '';
	free_shipping: String = '';
	is_gift: String = '';
	fast_shipping: String = '';
	fast_shipping_cost: String = '';
	vip_price: String = '';
	vip_old_price: String = '';
	vip_discount_price_valid_until: String = '';
	pick_sorting: String = '';
	single_order_per_user: String = '';
	//options: OptionError[];
	base_product: any = {
		sku: "",
		item_quantity: null,
	}
}

export class ProductNCategory {
	category_id: String = '';
	rank_id: String = '';
}

export class ProductNCategoryError implements IError {
	category_id: String = '';
	rank_id: String = '';
	sorting: String = '';
}

export class ProductNStoreError implements IError {
	store_id: String = '';
	feed_from_store_id: String = '';
	quantity: String = '';
	status: String = '';
}

export class ProductFilter {
	name?: string = null;
	supplier_id?: string = null;
	brand_id?: string = null;
	category_id?: string = null;
	rank_id?: string = null;
	out_of_stock_store_id?: string = null;
	status?: boolean = null;
	from_date?: Date = null;
	to_date?: Date = null;
}
