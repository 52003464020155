import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { ITable } from "src/app/@core/libraries/interfaces/table";
import { BaseIndex } from "src/app/@core/libraries/pages/base-index";
import { AuthService } from "src/app/@core/services/auth.service";
import { environment } from "src/environments/environment";
import { RewardMembersFilter } from "../reward.model";
import { RewardService } from "../reward.service";

@Component({
	selector: "app-reward-members",
	templateUrl: "./reward-members.component.html",
	styleUrls: ["./reward-members.component.scss"],
})
export class RewardMembersComponent extends BaseIndex implements OnInit {
	model = "reward";
	displayedColumns: string[] = [
		//"select",
		//"_id",
		"fullname",
		"mobile",
		//"rewarded_at",
		"tools"
	];
	theDetailsComponent = null;
	theFormComponent = null;
	searchForm: Boolean = false;
	filter: RewardMembersFilter = new RewardMembersFilter();
	csv_url: string;
	rewardId: String;
	isClaimed: Boolean = false;

	constructor(
		protected theService: RewardService,
		public auth: AuthService,
		protected router: Router,
		protected dialog: MatDialog,
		public route: ActivatedRoute
	) {
		super(theService, router, dialog);
		const token = `${this.auth.getToken()}`;
		//this.csv_url = `${environment.apiUrl}/offer/member_history?export=true&token=${token}`;
	}

	ngOnInit(): void {
		this.rewardId = this.route.snapshot.params["id"];
		this.get();
	}

	get() {
		this.isLoading = true;
		if (this.sort_data == true) {
			this.filter.is_sort = this.sort_data;
			this.filter.active = this.active;
			this.filter.direction =
				this.direction && this.filter.direction != this.direction
					? this.direction
					: this.filter.direction == "asc"
					? "desc"
					: "asc";
		}
		this.theService
			.getRewardMembers(
				this.rewardId,
				this.filter,
				this.pagination.pageIndex,
				this.pagination.pageSize
			)
			.subscribe((response) => {
				this.items = response.results.data;
				this.dataSource.data = response.results.data;
				this.pagination.length = response.results.total;
				this.selection = new SelectionModel<ITable>(true, []);
				this.SelectionIds = [];
				this.isLoading = false;
				this.after_get();
			});
	}

	markAsReawarded(history_id: String) {
		this.isLoading = true;
		this.theService.markAsRewarded(this.rewardId, history_id).subscribe((response) => {
			this.get();
		});
	}

	after_get() {
		const filter = this.filter;
		const keys = Object.keys(this.filter);
		let params = "";
		for (const k of keys) {
			if (!filter[k]) {
				continue;
			}
			params += `&filter[${k}]=${filter[k]}`;
		}
		const token = `${this.auth.getToken()}`;
		//this.csv_url = `${environment.apiUrl}/offer?token=${token}&export=true&${params}`;
	}

	setSearchForm() {
		this.searchForm = !this.searchForm;
	}
}
