import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../@core/modules/theme.module';
import { MemberComponent } from './member.component';
import { DetailsComponent } from './details/details.component';
import { FormComponent } from './form/form.component';
import { BulkWhatsappComponent } from './bulkmail/bulkmail.component';

const COMPONENTS = [
	MemberComponent,
	DetailsComponent,
	FormComponent,
	BulkWhatsappComponent
];

@NgModule({
	imports: [
		CommonModule,
		ThemeModule,
	],
	declarations: [...COMPONENTS],
	entryComponents: COMPONENTS
})
export class MemberModule { }
