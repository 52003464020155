import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';

@Component({
	selector: 'app-not-found',
	templateUrl: './not-found.component.html',
	styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

	constructor(parent: AppComponent) {
		parent.hasMainStyle = false;
	}

	ngOnInit() {
	}

}
