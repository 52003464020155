import { Component } from '@angular/core';
import { Notification, NotificationError } from '../notification.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { City } from '../../city/city.model';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'notification';
	item: Notification = new Notification();
	errors: NotificationError = new NotificationError();
	validate: NotificationError = new NotificationError();
	cities: City[] = [];

	getEmptyErrors() {
		return new NotificationError();
	}

	initialize() {
		this.loadCities();
	}

	loadCities() {
		this.http.get(`${environment.apiUrl}/city`, {
			params: new HttpParams()
				.set('filter', `status=true`)
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.cities = response['results']['data'];
			});
	}

}
