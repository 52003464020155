import { Component } from '@angular/core';
import { Employee, EmployeeError } from '../employee.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { Store } from '../../store/store.model';
import { Department } from '../department.model';
import { i18n } from '../../../@core/libraries/i18n';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'employee';
	item: Employee = new Employee();
	errors: EmployeeError = new EmployeeError();
	validate: EmployeeError = new EmployeeError();
	stores: Store[] = [];
	departments: Array<Department>;
	timeOptions: string[] = [
		'00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
		'13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00','20:00', '21:00', '22:00', '23:00'
	  ];

	initialize() {
		this.loadStores();
		this.departments = [
			new Department(1, i18n.department_processing),
			new Department(2, i18n.department_delivery),
			new Department(3, i18n.department_store_manager),
		];
	}

	loadStores() {
		this.http.get(`${environment.apiUrl}/store`, {
			params: new HttpParams()
				.set('filter', `status=true`)
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.stores = response['results']['data'];
			});
	}

	formatTime(event: any, field: string) {
		const input = event.target as HTMLInputElement;
		const value = input.value.replace(/\D/g, ''); // Remove non-numeric characters
		const formattedValue = value.replace(/^(\d{2})(\d{2})?/, '$1:$2'); // Add ":" after first two characters
		this[field] = formattedValue;
		input.value = formattedValue;
	  }

	getEmptyErrors() {
		return new EmployeeError();
	}

}
