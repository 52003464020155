import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class LoginService {

	items: any;
	token: string;
	httpOptions: any;

	constructor(public http: HttpClient, private router: Router) { }

	checkLogin(body): any {
		return this.http.post(`${environment.apiUrl}/auth/login`, body);
	}

}
