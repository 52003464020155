<h1 mat-dialog-title>{{ 'bulk_send_emails' | i18n }}</h1>
<div mat-dialog-content>

	<mat-card>
		<mat-card-content>
			<div class="row">

				<mat-form-field appearance="outline" class="form-group col-md-12">
					<mat-label>{{ 'subject' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.subject" [errorStateMatcher]='validate.subject'
						placeholder="{{ 'subject' | i18n }}" />
					<mat-error>{{ errors.subject }}</mat-error>
				</mat-form-field>

				<mat-form-field class="form-group col-md-12">
					<div class="mb-2">{{ 'message' | i18n }}</div>
						<input matInput hidden [(ngModel)]="item.message"
										[errorStateMatcher]='validate.message' name="message" class="border-none " />
						<ckeditor [(ngModel)]="item.message" [editor]="Editor" [config]="editorConfig"></ckeditor>
					<mat-error>{{ errors.message }}</mat-error>
				</mat-form-field>
			</div>
		</mat-card-content>
	</mat-card>


</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'send' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
