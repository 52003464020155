import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../@core/modules/theme.module';
import { InventoryComponent } from './inventory.component';
import { DetailsComponent } from './details/details.component';
import { FormComponent } from './form/form.component';

const COMPONENTS = [
	InventoryComponent,
	DetailsComponent,
	FormComponent,
];

@NgModule({
	imports: [
		CommonModule,
		ThemeModule,
	],
	declarations: [...COMPONENTS],
	entryComponents: COMPONENTS
})

export class InventoryModule { }
