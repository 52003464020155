import { LanguagesObject } from '../../@core/data/languages-object';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';

export class AvailableDeliveryTime {
	id: Number = 0;
	is_available: Boolean = false;
	max_orders: Number = 0;
}


export class Supplier implements ITable {

	_id: String = '';
	name: LanguagesObject = new LanguagesObject();
	//alt_name: LanguagesObject = new LanguagesObject();
	description: LanguagesObject = new LanguagesObject();
	delivery_time: Number = 0;
	delivery_time_text: LanguagesObject = new LanguagesObject();
	app_delivery_time: String = '';
	min_order: Number = 0;
	shipping_cost: Number = null;
	allow_cod: Boolean = false;
	cities: Array<{ city_id: String; shipping_cost: Number; }> = [];
	working_times: Array<any> = [];
	is_external: Boolean = false;
	is_busy: Boolean = false;
	available_delivery_times: Array<any> = [];
	email: String = null;
	password: String = null;
	inventory_id: String = "";
	picture: LanguagesObject = new LanguagesObject();
	logo: LanguagesObject = new LanguagesObject();
	sorting: Number = 0;
	home_sorting: Number = 0;
	discount: Number = 0;
	/* has_picking_time: Boolean = false;
	has_options: Boolean = false;
	display_layout: Number; */
	categories: String[] = [];
	status: Boolean = false;
	mobile: String = "";
	feature_id: String = "";
	feature_sorting: Number = 0;
	features: Array<Object> = [];
}

export class SupplierError implements IError {

	name: LanguagesObject = new LanguagesObject();
	//alt_name: LanguagesObject = new LanguagesObject();
	description: LanguagesObject = new LanguagesObject();
	delivery_time: String = '';
	delivery_time_text: LanguagesObject = new LanguagesObject();
	app_delivery_time: String = '';
	min_order: String = '';
	shipping_cost: String = "";
	allow_cod: String = "";
	working_times: String = "";
	cities: String = "";
	is_external: String = '';
	is_busy: String = '';
	available_delivery_times: "";
	email: String = "";
	password: String = "";
	inventory_id: String = "";
	picture: LanguagesObject = new LanguagesObject();
	logo: LanguagesObject = new LanguagesObject();
	sorting: String = '';
	home_sorting: String = '';
	discount: String = '';
	has_picking_time: String = '';
	/* has_options: String = "";
	display_layout: String = ""; */
	categories: String = "";
	status: String = '';
	mobile: String = "";
	manager_mobile: String = "";
	feature_id: String = "";
	feature_sorting: String = "";
	features: String = "";

}
