import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeModule } from '../../@core/modules/theme.module';
import { CouponComponent } from './coupon.component';
import { DetailsComponent } from './details/details.component';
import { FormComponent } from './form/form.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';


const COMPONENTS = [
	CouponComponent,
	DetailsComponent,
	FormComponent,
];

@NgModule({
	imports: [
		CommonModule,
		ThemeModule,
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		BrowserAnimationsModule,
		MatChipsModule,
		MatInputModule,
		MatIconModule,
	],
	declarations: [...COMPONENTS],
	entryComponents: COMPONENTS
})
export class CouponModule { }
