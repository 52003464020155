import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { MemberService } from './member.service';
import { DetailsComponent } from './details/details.component';
import { FormComponent } from './form/form.component';
import { BulkWhatsappComponent } from './bulkmail/bulkmail.component';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';
import { UserFilter } from './member.model';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/@core/services/auth.service';

@Component({
	selector: 'app-member',
	templateUrl: './member.component.html',
	styleUrls: ['./member.component.scss']
})
export class MemberComponent extends BaseIndex {

	model = 'member';
	displayedColumns: string[] = ['select', '_id', 'fullname', 'mobile', 'email', 'wallet', 'points', 'status', 'tools'];
	theDetailsComponent = DetailsComponent;
	theFormComponent = FormComponent;
	formWidth: String = '800px';
	searchForm: Boolean = false;
	csv_url: string;
	filter: UserFilter = new UserFilter();

	constructor(protected theService: MemberService,
		public auth: AuthService,
		protected router: Router, protected dialog: MatDialog) {
		super(theService, router, dialog);
		const token = `${this.auth.getToken()}`;
		this.csv_url = `${environment.apiUrl}/member?token=${token}&export=true`;
	}

	after_get() {
		const filter = this.filter;
		const keys = Object.keys(this.filter);
		let params = '';
		for (const k of keys) {
			if (!filter[k]) {
				continue;
			}
			params += `&filter[${k}]=${filter[k]}`;
		}
		const token = `${this.auth.getToken()}`;
		this.csv_url = `${environment.apiUrl}/member?token=${token}&export=true&${params}`;
	}

	setSearchForm() {
		this.searchForm = !this.searchForm;
	}

	openBulkMail() {
		const dialogRef = this.dialog.open(BulkWhatsappComponent, {
			minWidth: this.formWidth.toString(),
			minHeight: this.formHeight.toString(),
			data: {
				//action: action,
				id: "",
			},
			disableClose: true
		});

		//dialogRef.afterClosed();
	}

}
