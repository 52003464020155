<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>
	<mat-card>
		<mat-card-subtitle>{{ 'translated_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<mat-tab-group>
				<mat-tab *ngFor="let l of languages">
					<ng-template mat-tab-label>
						<i class="flag-icon flag-icon-{{l.image}}"></i>
						{{l.name}}
					</ng-template>

					<div class="col-12" dir="{{l.direction}}">

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'name' | i18n }} ( {{ l.name }} )</mat-label>
							<input matInput [(ngModel)]="item.name[l.code]" [errorStateMatcher]='validate.name[l.code]'
								placeholder="{{ 'name' | i18n }}" />
							<mat-error>{{ errors.name[l.code] }}</mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-group col-md-6 mat-upload">
							<mat-label>{{'picture' | i18n}} ( {{ l.name }} )</mat-label>
							<input type="file" (change)="upload($event.target.files, 'picture.' + l.code)" />
							{{'upload_percent' | i18n}}: {{percentDone}}%
							<img *ngIf="item.picture[l.code]" src="{{environment.mediaUrl}}{{item.picture[l.code]}}" width="48" />
							<input matInput hidden [(ngModel)]="item.picture[l.code]" [errorStateMatcher]='validate.picture[l.code]'>
							<br />
							<mat-error>{{ errors.picture[l.code] }}</mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-group col-md-6 mat-upload">
							<mat-label>{{'logo' | i18n}} ( {{ l.name }} )</mat-label>
							<input type="file" (change)="upload($event.target.files, 'logo.' + l.code)" />
							{{'upload_percent' | i18n}}: {{percentDone}}%
							<img *ngIf="item.logo[l.code]" src="{{environment.mediaUrl}}{{item.logo[l.code]}}" width="48" />
							<input matInput hidden [(ngModel)]="item.logo[l.code]" [errorStateMatcher]='validate.logo[l.code]'>
							<br />
							<mat-error>{{ errors.logo[l.code] }}</mat-error>
						</mat-form-field>

					</div>

				</mat-tab>
			</mat-tab-group>
		</mat-card-content>
	</mat-card>
	<mat-divider></mat-divider>

	<mat-card>
		<mat-card-subtitle>{{ 'other_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">

				<!-- <mat-form-field appearance="outline" class="form-group col-md-12 mat-upload">
					<mat-label>{{ 'picture' | i18n }}</mat-label>
					<input type="file" (change)="upload($event.target.files)" />
					{{ 'upload_percent' | i18n }}: {{ percentDone }}%
					<img *ngIf="item.picture" src="{{ environment.mediaUrl }}{{ item.picture }}" width="48" />
					<input matInput hidden [(ngModel)]="item.picture" [errorStateMatcher]='validate.picture'
						name="picture">
					<br />
					<mat-error>{{ errors.picture }}</mat-error>
				</mat-form-field> -->

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
						[errorStateMatcher]='validate.status' name="status">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.status }}</mat-error>
				</mat-form-field>

			</div>
		</mat-card-content>
	</mat-card>


</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
