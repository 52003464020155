import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { OrderService } from './order.service';
import { DetailsComponent } from './details/details.component';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Store } from '../store/store.model';
import { OrderFilter } from './order.model';
import { Supplier } from '../supplier/supplier.model';
import { i18n } from 'src/app/@core/libraries/i18n';
import { AuthService } from 'src/app/@core/services/auth.service';
import { Member } from '../member/member.model';
import { Adminmember } from '../adminmember/adminmember.model';
import { adminUser } from 'src/app/@core/libraries/helper';
import { Category } from '../category/category.model';

@Component({
	selector: 'app-order',
	templateUrl: './order.component.html',
	styleUrls: ['./order.component.scss']
})
export class OrderComponent extends BaseIndex {

	model = 'order';
	displayedColumns: string[] = ['select', '_id', 'name', 'total', 'delivery_time', 'created', 'status', 'tools'];
	theDetailsComponent = DetailsComponent;
	theFormComponent = null;
	searchForm: Boolean = false;
	stores: Store[] = [];
	suppliers: Supplier[] = [];
	categories: Category[] = [];
	order_status_list: Array<Object> = [];
	filter: OrderFilter = new OrderFilter();
	canDelete: Boolean = false;
	csv_url: string;
	//today_csv: string;
	csv_store: string;

	constructor(protected theService: OrderService,
		public http: HttpClient,
		public auth: AuthService,
		protected router: Router, protected dialog: MatDialog) {
		super(theService, router, dialog);
		this.loadStores();
		this.loadSuppliers();
		//this.loadCategories();
		this.order_status_list = Object.keys(i18n.order_status_list).map((k) => {
			return {
				key: k,
				value: i18n.order_status_list[k],
			};
		});
		//this.today_csv = `${environment.apiUrl}/order/export_csv_today?token=${token}&store_id=${this.csv_store}`;
	}

	exportCSV() {
		const token = `${this.auth.getToken()}`;

		let filter_queries = '';

		for (let f of Object.keys(this.filter)) {
			filter_queries += `&filter[${f}]=${this.filter[f] ? this.filter[f] : ""}`;
		}

		const csv_url = `${environment.apiUrl}/order/export_csv?token=${token}&store_id=${this.csv_store}${filter_queries}`;
		location.href = csv_url;
	}

	onNavigate(location: string){
		this.router.navigate([location]);
		location=null;
	}
	afterCloseDetails() {
		this.get();
	}

	loadCategories() {
		this.http.get(`${environment.apiUrl}/category`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {
				this.categories = response['results']['data'];
			});
	}

	loadStores() {
		this.http.get(`${environment.apiUrl}/store`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.stores = response['results']['data'];
			});
	}

	loadSuppliers() {
		this.http.get(`${environment.apiUrl}/supplier`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.suppliers = [{ _id: "Jm3eia", name: { en: "Jm3eia", ar: "Jm3eia ar" } }, ...response['results']['data']];
			});
	}

	openBill(id) {
	}

	setSearchForm() {
		this.searchForm = !this.searchForm;
	}

}
