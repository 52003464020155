import { LanguagesObject } from '../../@core/data/languages-object';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';



export enum OptionType {

	COLOR = 'COLOR',
	IMAGE = 'IMAGE',
	NUMBER = 'NUMBER',
	STRING = 'STRING'

}

export class OptionItem implements ITable {

	_id: String = '';
	name: LanguagesObject = new LanguagesObject();
	sku_code: String = '';
	value: String = '';

}

export class OptionItemError implements IError {

	name: LanguagesObject = new LanguagesObject();
	sku_code: String = '';
	value: String = '';

}

export class Option implements ITable {

	_id: String = '';
	name: LanguagesObject = new LanguagesObject();
	type: OptionType = OptionType.STRING;
	options_list: Array<OptionItem> = [];
	status: Boolean = false;

}

export class OptionError implements IError {

	name: LanguagesObject = new LanguagesObject();
	type: String = '';
	status: String = '';

}

