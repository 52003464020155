import { Component } from '@angular/core';
import { SupplierFeature, SupplierFeatureError } from '../supplier_feature.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { Store } from '../../store/store.model';
import { LanguagesObject } from 'src/app/@core/data/languages-object';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'supplier_feature';
	item: SupplierFeature = new SupplierFeature();
	errors: SupplierFeatureError = new SupplierFeatureError();
	validate: SupplierFeatureError = new SupplierFeatureError();

	initialize() {
		
	}

	getEmptyErrors() {
		return new SupplierFeatureError();
	}

}
