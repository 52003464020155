import { IError } from "src/app/@core/libraries/interfaces/error";
import { LanguagesObject } from "src/app/@core/data/languages-object";

export class SocialItem {
	url = "";
	image = "";
}

export class EmailConfig {
	host: String = "";
	port: Number = 465;
	secure: Boolean = true;
	username: String = "";
	password: String = "";
}

export class EmailConfigError {
	host: String = "";
	port: String = "";
	secure: String = "";
	username: String = "";
	password: String = "";
}

export class AllowOrders {
	enable_on_web: Boolean;
	enable_on_android: Boolean;
	enable_on_ios: Boolean;

	cod_for_registered_on_android: Boolean;
	cod_for_visitors_on_android: Boolean;
	knet_for_registered_on_android: Boolean;
	knet_for_visitors_on_android: Boolean;

	cod_for_registered_on_ios: Boolean;
	cod_for_visitors_on_ios: Boolean;
	knet_for_registered_on_ios: Boolean;
	knet_for_visitors_on_ios: Boolean;

	cod_for_registered_on_web: Boolean;
	cod_for_visitors_on_web: Boolean;
	knet_for_registered_on_web: Boolean;
	knet_for_visitors_on_web: Boolean;
}

export class AllowOrdersError {
	enable_on_web: String;
	enable_on_android: String;
	enable_on_ios: String;

	cod_for_registered_on_android: String;
	cod_for_visitors_on_android: String;
	knet_for_registered_on_android: String;
	knet_for_visitors_on_android: String;

	cod_for_registered_on_ios: String;
	cod_for_visitors_on_ios: String;
	knet_for_registered_on_ios: String;
	knet_for_visitors_on_ios: String;

	cod_for_registered_on_web: String;
	cod_for_visitors_on_web: String;
	knet_for_registered_on_web: String;
	knet_for_visitors_on_web: String;
}

export class EvaluationOrders {
	enable_on_web: Boolean;
	enable_on_android: Boolean;
	enable_on_ios: Boolean;
	enable_on_google_play: Boolean;
	enable_on_app_store: Boolean;
}

export class EvaluationOrdersError {
	enable_on_web: String;
	enable_on_android: String;
	enable_on_ios: String;
	enable_on_google_play: String;
	enable_on_app_store: String;
}

export class Jm3eiaPro {
	allow: Boolean = false;
	subscriptions: Array<{
		price: Number;
		duration_months: 3 | 12;
	}> = [{duration_months: 3, price: 0}, {duration_months: 12, price: 0}];
	only_jm3iea: Boolean = false;
	content: Array<{
		image: String;
		title: LanguagesObject;
		description: LanguagesObject;
	}> = [];
}

export class Jm3eiaProError {
	allow: String = "";
	subscriptions: Array<{
		price: String;
		duration_months: String;
	}> = [{duration_months: "", price: ""}, {duration_months: "", price: ""}];
	only_jm3iea: String = "";
	content: Array<{
		image: String;
		title: LanguagesObject;
		description: LanguagesObject;
	}> = [];
}

export class Orders {
	allow: AllowOrders = new AllowOrders();
	closed_message: LanguagesObject = new LanguagesObject();
	evaluation: EvaluationOrders = new EvaluationOrders();
	available_delivery_times: any = {};
	min_delivery_time?: Number = 0;
	enable_immediate_delivery?: Boolean = true;
	min_value: Number = 0;
	vip_min_delivery_time?: Number = 0;
	vip_min_value: Number = 0;
	enable_donation: Boolean = false;
}

export class OrdersError {
	allow?: AllowOrdersError = new AllowOrdersError();
	closed_message?: LanguagesObject = new LanguagesObject();
	evaluation?: EvaluationOrdersError = new EvaluationOrdersError();
	available_delivery_times?: Array<any>;
	min_delivery_time?: String;
	enable_immediate_delivery?: String;
	min_value?: String;
	enable_donation?: String;
}

export class Wallet {
	points_to_wallet: Number = 100;
	user_can_convert_points_to_wallet: Boolean;
	description: LanguagesObject = new LanguagesObject();
}

export class WalletError {
	points_to_wallet: String;
	user_can_convert_points_to_wallet: String;
	description: LanguagesObject = new LanguagesObject();
}

export class Social {
	facebook: SocialItem = new SocialItem();
	twitter: SocialItem = new SocialItem();
	instagram: SocialItem = new SocialItem();
	whatsapp: SocialItem = new SocialItem();
}

export class SenderEmails {
	register: EmailConfig = new EmailConfig();
	reset_password: EmailConfig = new EmailConfig();
	orders: EmailConfig = new EmailConfig();
}

export class SenderEmailsError {
	register: EmailConfigError = new EmailConfigError();
	reset_password: EmailConfigError = new EmailConfigError();
	orders: EmailConfigError = new EmailConfigError();
}

export class DisplayContentJm3eia {
	title: LanguagesObject = new LanguagesObject();
	description: LanguagesObject = new LanguagesObject();
	picture: LanguagesObject = new LanguagesObject();
}

export class DisplayContentJm3eiaError {
	title: LanguagesObject = new LanguagesObject();
	description: LanguagesObject = new LanguagesObject();
	picture: LanguagesObject = new LanguagesObject();
}

export class DisplayContent {
	jm3eia_vip: DisplayContentJm3eia = new DisplayContentJm3eia();
	jm3eia_mart: DisplayContentJm3eia = new DisplayContentJm3eia();
}

export class DisplayContentError {
	jm3eia_vip: DisplayContentJm3eiaError = new DisplayContentJm3eiaError();
	jm3eia_mart: DisplayContentJm3eiaError = new DisplayContentJm3eiaError();
}

export class Display {
	display_order_again: Boolean = true;
	display_best_selling: Boolean = true;
	display_supplier_reviews: Boolean = true;
	content: DisplayContent = new DisplayContent();
}

export class DisplayError {
	display_order_again: String = "";
	display_best_selling: String = "";
	display_supplier_reviews: String = "";
	content: DisplayContentError = new DisplayContentError();
}

export class Donation {
	enable: Boolean = true;
	content: DonationContent = new DonationContent();
}

export class DonationError {
	enable: String = "";
	content: DonationContentError = new DonationContentError();
}

export class DonationContent {
	description: LanguagesObject = new LanguagesObject();
	picture: LanguagesObject = new LanguagesObject();
}
export class DonationContentError {
	description: LanguagesObject = new LanguagesObject();
	picture: LanguagesObject = new LanguagesObject();
}

export class Setting {
	email = "";
	sender_emails: SenderEmails = new SenderEmails();
	phones: any;
	site_name: LanguagesObject = new LanguagesObject();
	social: Social = new Social();
	orders: Orders = new Orders();
	wallet?: Wallet = new Wallet();
	jm3eia_pro: Jm3eiaPro = new Jm3eiaPro();
	display: Display = new Display();
	donation: Donation = new Donation();
}

export class SettingError implements IError {
	email?: String;
	phones?: String;
	site_name?: LanguagesObject = new LanguagesObject();
	social?: Social = new Social();
	sender_emails?: SenderEmailsError = new SenderEmailsError();
	orders?: OrdersError = new OrdersError();
	wallet?: WalletError = new WalletError();
	jm3eia_pro: Jm3eiaProError = new Jm3eiaProError();
	display: DisplayError = new DisplayError();
	donation: DonationError = new DonationError();
}
