import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'date' })
export class DatePipe implements PipeTransform {

	transform(input: string): string {
		const date = new Date(input);
		const year = date.getFullYear().toString();
		const month = date.getMonth().toString();
		const day = date.getDay().toString();
		return `<span title="${input}">${year}-${month}-${day}</span>`;
	}
}
