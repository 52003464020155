import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseModelService } from 'src/app/@core/services/base-model.service';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})


export class RewardService extends BaseModelService {

	model: String = 'reward';

	public getRewardMembers(id: String, filter = {}, skip = 0, limit = 10): any {
		let params = new HttpParams()
			.set('skip', (skip + 1).toString())
			.set('limit', limit.toString());
		const keys = Object.keys(filter);
		for (const k of keys) {
			if (!filter[k] && filter[k] !== false) {
				continue;
			}
			params = params.append(`filter[${k}]`, filter[k]);
		}
		return this.http.get(`${environment.apiUrl}/${this.model}/${id}/members`, {
			params: params
		});
	}

	public getAllRewardsMembers(filter = {}, skip = 0, limit = 10): any {
		let params = new HttpParams()
			.set('skip', (skip + 1).toString())
			.set('limit', limit.toString());
		const keys = Object.keys(filter);
		for (const k of keys) {
			if (!filter[k] && filter[k] !== false) {
				continue;
			}
			console.log('filter: ', filter);
			
			params = params.append(`filter[${k}]`, filter[k]);
			console.log('params: ', params);
		}
		return this.http.get(`${environment.apiUrl}/${this.model}/members`, {
			params: params
		});
	}

	public markAsRewarded(id: String, history_id: String): any {
		return this.http.post(`${environment.apiUrl}/${this.model}/${id}/reward`, { history_id });
	}
}
