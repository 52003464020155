import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { PageService } from './page.service';
import { DetailsComponent } from './details/details.component';
import { FormComponent } from './form/form.component';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';

@Component({
	selector: 'app-page',
	templateUrl: './page.component.html',
	styleUrls: ['./page.component.scss']
})
export class PageComponent extends BaseIndex {

	model = 'page';
	displayedColumns: string[] = ['select', '_id', 'name', 'created', 'status', 'tools'];
	theDetailsComponent = DetailsComponent;
	theFormComponent = FormComponent;
	formWidth: String = '800px';
	detailsWidth: String = '600px';

	constructor(protected theService: PageService,
		protected router: Router, protected dialog: MatDialog) {
		super(theService, router, dialog);
	}

}
