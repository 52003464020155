import { Component } from '@angular/core';
import { BulkMail, BulkMailError } from '../member.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import * as ClassicEditor from 'src/assets/vendors/ckeditor/ckeditor';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';

class MyUploadAdapter {
	url: string;
	loader: any;
	xhr: any;
	public http: HttpClient;
    constructor( loader, http ) {
        this.loader = loader;

        this.url = `${environment.apiUrl}/upload`;

		this.http = http;
    }

    upload() {
        return new Promise( ( resolve, reject ) => {
			this.loader.file.then(file => {
				const formData = new FormData();
			formData.append('picture', file);
			this.http.post(`${environment.apiUrl}/upload`, formData, { reportProgress: true, observe: 'events' })
				.subscribe((event: any) => {
					if (event instanceof HttpResponse) {
						resolve({ default: `${environment.mediaUrl}${event.body.results.path}` });
					}
				});
			})

        } );
    }
}

function MyCustomUploadAdapterPlugin( editor/* , http */ ) {
	editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
		return new MyUploadAdapter( loader, editor.config._config.customValues.http );
	};
}

@Component({
	selector: 'app-bulkmail',
	templateUrl: './bulkmail.component.html',
	styleUrls: ['./bulkmail.component.scss']
})
export class BulkWhatsappComponent extends BaseForm {

	model = 'bulkmail';
	item: BulkMail = new BulkMail();
	errors: BulkMailError = new BulkMailError();
	validate: BulkMailError = new BulkMailError();
	public Editor = ClassicEditor;
	public editorConfig = {
		customValues: { http: this.http },
		extraPlugins: [MyCustomUploadAdapterPlugin]
	}


	getEmptyErrors() {
		return new BulkMailError();
	}

}
