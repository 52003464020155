import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';
import { AuthService } from 'src/app/@core/services/auth.service';
import { environment } from 'src/environments/environment';
import { ReportFilter } from '../report.model';
import { ReportService } from '../report.service';

@Component({
	selector: 'app-member-history',
	templateUrl: './member-history.component.html',
	styleUrls: ['./member-history.component.scss']
})
export class MemberHistoryComponent extends BaseIndex {

	model = 'report';
	displayedColumns: string[] = ['select', '_id', 'member_name', 'member_mobile', 'points', 'total_orders', 'last_order_date'];
	theDetailsComponent = null;
	theFormComponent = null;
	searchForm: Boolean = false;
	filter: ReportFilter = new ReportFilter();
	csv_url: string;

	constructor(protected theService: ReportService,
		public auth: AuthService,
		protected router: Router, protected dialog: MatDialog) {
		super(theService, router, dialog);
		const token = `${this.auth.getToken()}`;
		this.csv_url = `${environment.apiUrl}/report/member_history?export=true&token=${token}`;
	}

	ngOnInit(): void {
		this.get();
	}

	get() {
		this.isLoading = true;
		if (this.sort_data == true) {
			this.filter.is_sort = this.sort_data;
			this.filter.active = this.active;
			this.filter.direction = this.direction && this.filter.direction != this.direction ? this.direction : (this.filter.direction == "asc" ? "desc" : "asc");
		}
		this.theService.getMemberHistory(this.filter, this.pagination.pageIndex, this.pagination.pageSize).subscribe((response) => {
			this.items = response.results.data;
			this.dataSource.data = response.results.data;
			this.pagination.length = response.results.total;
			this.selection = new SelectionModel<ITable>(true, []);
			this.SelectionIds = [];
			this.isLoading = false;
			this.after_get();
		});
	}

	after_get() {
		const filter = this.filter;
		const keys = Object.keys(this.filter);
		let params = '';
		for (const k of keys) {
			if (!filter[k]) {
				continue;
			}
			params += `&filter[${k}]=${filter[k]}`;
		}
		const token = `${this.auth.getToken()}`;
		this.csv_url = `${environment.apiUrl}/report/member_history?token=${token}&export=true&${params}`;
	}

	setSearchForm() {
		this.searchForm = !this.searchForm;
	}

}
