<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>
	<mat-card>
		<mat-card-subtitle>{{ 'translated_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<mat-tab-group>
				<mat-tab *ngFor="let l of languages">
					<ng-template mat-tab-label>
						<i class="flag-icon flag-icon-{{l.image}}"></i>
						{{l.name}}
					</ng-template>

					<div class="col-12" dir="{{l.direction}}">

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'name' | i18n }} ( {{ l.name }} )</mat-label>
							<input matInput [(ngModel)]="item.name[l.code]" [errorStateMatcher]='validate.name[l.code]'
								placeholder="{{ 'name' | i18n }}" />
							<mat-error>{{ errors.name[l.code] }}</mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'expiration_date_message' | i18n }} ( {{ l.name }} )
							</mat-label>
							<textarea matInput [(ngModel)]="item.expiration_date_message[l.code]" [errorStateMatcher]='validate?.expiration_date_message[l.code]'
								placeholder="{{ 'expiration_date_message' | i18n }}"></textarea>
							<mat-error>{{ errors.expiration_date_message[l.code] }}</mat-error>
						</mat-form-field>

					</div>

				</mat-tab>
			</mat-tab-group>
		</mat-card-content>
	</mat-card>
	<mat-divider></mat-divider>

	<mat-card>
		<mat-card-subtitle>{{ 'other_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'supplier' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'supplier' | i18n }}" [(ngModel)]="item.supplier_id"
						[errorStateMatcher]='validate.supplier_id'>
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of suppliers" [value]="c._id">
							{{ c.name[config.lang] }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<!-- <mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'parent' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'parent' | i18n }}" [(ngModel)]="item.parent_id"
						[errorStateMatcher]='validate.parent_id'>
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of parents" [value]="c._id">
							{{c.name[config.lang]}}
						</mat-option>
					</mat-select>
				</mat-form-field> -->

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'sorting' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.sorting" [errorStateMatcher]='validate.sorting'
						placeholder="{{ 'sorting' | i18n }}" />
					<mat-error>{{ errors.sorting }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'show_in_vip' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'show_in_vip' | i18n }}" [(ngModel)]='item.show_in_vip'
						[errorStateMatcher]='validate.show_in_vip' name="show_in_vip">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.show_in_vip }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'only_vip' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'only_vip' | i18n }}" [(ngModel)]='item.only_vip'
						[errorStateMatcher]='validate.only_vip' name="only_vip">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.only_vip }}</mat-error>
				</mat-form-field>

				<mat-checkbox [(ngModel)]="enableExpirationDate" (change)="resetExpirationDate()" class="col-md-6">Enable Expiration Date</mat-checkbox>

				<mat-form-field *ngIf="enableExpirationDate" appearance="outline" class="form-group col-md-4">
					<mat-label>{{ 'expiration_date' | i18n }}</mat-label>
					<input matInput [min]="minDate" [matDatepicker]="picker" [(ngModel)]="item.expiration_date"
						[errorStateMatcher]='validate.expiration_date' placeholder="{{ 'expiration_date' | i18n }}" (dateChange)="setValidUntilTime()" />
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-datepicker #picker></mat-datepicker>
					<mat-error>{{ errors.expiration_date }}</mat-error>
				</mat-form-field>

				<mat-form-field *ngIf="enableExpirationDate" appearance="outline" class="form-group col-md-1">
					<mat-label>{{ 'hours' | i18n }}</mat-label>
					<input type="number" matInput [(ngModel)]="valid_until_time.hours" (change)="timeChangedHandeler()" min="0" max="23" maxlength="2"
						placeholder="{{ 'hours' | i18n }}" />
				</mat-form-field>

				<mat-form-field *ngIf="enableExpirationDate" appearance="outline" class="form-group col-md-1">
					<mat-label>{{ 'minutes' | i18n }}</mat-label>
					<input type="number" matInput [(ngModel)]="valid_until_time.minutes" (change)="timeChangedHandeler()" min="0" max="59" maxlength="2"
						placeholder="{{ 'minutes' | i18n }}" />
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
						[errorStateMatcher]='validate.status' name="status">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.status }}</mat-error>
				</mat-form-field>
			</div>
		</mat-card-content>
	</mat-card>

</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
