import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Log } from "./log.model";
import { LogService } from "./log.service";
import { HttpClient } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LanguagesService } from "src/app/@core/data/languages.service";

@Component({
	selector: "app-log",
	templateUrl: "./log.component.html",
	styleUrls: ["./log.component.scss"],
})
export class LogComponent implements OnInit {
	model = "log";
	isLoading = false;
	item: Log = new Log();
	languages: any;
	collection: String;
	documentId: String;
	page: number = 1;
	total: number = 0;
	show: 'changes' | 'all' = 'changes';
	action: String = 'all';
	changedFields: Object = {};
	noData: Boolean = true;
	fields = [];

	constructor(
		protected theService: LogService,
		public http: HttpClient,
		public _snackBar: MatSnackBar,
		protected router: Router,
		private languagesService: LanguagesService,
		public route: ActivatedRoute
	) {
		this.languages = this.languagesService.get();
	}
	ngOnInit(): void {
		this.collection = this.route.snapshot.params["collection"];
		this.documentId = this.route.snapshot.params["id"];
		this.get();
	}

	get() {
		this.isLoading = true;
		this.theService
			.getLogs(this.collection, this.documentId, this.page, this.action)
			.subscribe((response) => {
				if (response.results.count > 0) {
					this.item = response.results.data[0];
					//this.item.old_data = JSON.stringify(this.item.old_data);
					this.item.new_data = this.sortData(this.item.new_data, this.item.old_data);
					this.total = response.results.total;
					//this.highlightChanges();
					this.changedFields = this.getChangedFields(this.item.new_data, this.item.old_data);
					this.fields = Object.keys(this.changedFields);
					this.noData = false;
				} else {
					this.noData = true;
				}
				this.isLoading = false;
			});
	}

	next() {
		if (this.page < this.total) {
			this.page += 1;
			this.get();
		}
	}

	previous() {
		if (this.page > 1) {
			this.page -= 1;
			this.get();
		}
	}

	isObjectOrArray(value: any): boolean {
		return typeof value === 'object' && value !== null;
	  }

	/* getChangedFields(currentDoc, oldDoc, path = '') {
		let fields = {};
		for (let key in currentDoc) {
			const currentPath = path ? `${path}.${key}` : key;
			if (typeof currentDoc[key] === 'object' && currentDoc[key] !== null) {
			const nestedFields = this.getChangedFields(currentDoc[key], oldDoc[key], currentPath);
			fields = { ...fields, ...nestedFields };
			} else if (currentDoc[key] !== oldDoc[key]) {
			fields[currentPath] = { from: oldDoc[key], to: currentDoc[key] };
			}
		}
		return fields;
	} */

	getChangedFields(currentDoc, oldDoc, path = '') {
		let fields = {};

		for (let key in currentDoc) {
		  const currentPath = path ? `${path}.${key}` : key;
		  const currentValue = currentDoc[key];
		  const oldValue = oldDoc[key];

		  if (typeof currentValue === 'object' && currentValue !== null && !Array.isArray(currentValue)) {
			const nestedFields = this.getChangedFields(currentValue, oldValue, currentPath);
			fields = { ...fields, ...nestedFields };
		  } else if (Array.isArray(currentValue) && Array.isArray(oldValue)) {
			if (currentValue.length !== oldValue.length) {
			  fields[currentPath] = { from: oldValue, to: currentValue };
			} else {
			  for (let i = 0; i < currentValue.length; i++) {
				const nestedFields = this.getChangedFields(currentValue[i], oldValue[i], `${currentPath}.${i}`);
				fields = { ...fields, ...nestedFields };
			  }
			}
		  } else if (currentValue !== oldValue) {
			fields[currentPath] = { from: oldValue, to: currentValue };
		  }
		}

		return fields;
	  }

	  sortData(data, referenceData) {
		const sortedData = {};
		for (let key in referenceData) {
		  if (data.hasOwnProperty(key)) {
			sortedData[key] = data[key];
		  }
		}
		return sortedData;
	  }

}

