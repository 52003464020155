import { Component, OnInit } from '@angular/core';
import {OrderService} from 'src/app/pages/order/order.service';
import { DetailsComponent } from '../details/details.component';
import { Store } from '../../store/store.model';
import { OrderFilter } from '../order.model';
import { Supplier } from '../../supplier/supplier.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { adminUser } from 'src/app/@core/libraries/helper';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';
import { environment } from 'src/environments/environment';
import { i18n } from 'src/app/@core/libraries/i18n';
import { AuthService } from 'src/app/@core/services/auth.service';
import {Sort} from '@angular/material/sort';

@Component({
  selector: 'app-by-zero-products',
  templateUrl: './by-zero-products.component.html',
  styleUrls: ['./by-zero-products.component.scss']
})
export class ByZeroProductsComponent extends BaseIndex {

  model = 'order';
	displayedColumns: string[] = ['select', '_id', 'name', 'total', 'delivery_time', 'created', 'status', 'tools'];
	theDetailsComponent = DetailsComponent;
	theFormComponent = null;
	searchForm: Boolean = false;
	stores: Store[] = [];
	suppliers: Supplier[] = [];
	order_status_list: Array<Object> = [];
	filter: OrderFilter = new OrderFilter();
	canDelete: Boolean = false;
	csv_url: string;
	csv_store: string;

	constructor(protected theService: OrderService,
		public http: HttpClient,
		public auth: AuthService,
		protected router: Router, protected dialog: MatDialog) {
		super(theService, router, dialog);

		this.canDelete = adminUser().group == 'admin';

		this.loadStores();
		this.loadSuppliers();
		this.order_status_list = Object.keys(i18n.order_status_list).map((k) => {
			return {
				key: k,
				value: i18n.order_status_list[k],
			};
		});
    this.filter.zero_products=true;

	const token = `${this.auth.getToken()}`;
		this.csv_url = `${environment.apiUrl}/order/export_csv?token=${token}&store_id=${this.csv_store}`;

	}

    onNavigate(location: string){
      this.router.navigate([location]);
      location=null;
    }
	afterCloseDetails() {
		this.get();
	}

	loadStores() {
		this.http.get(`${environment.apiUrl}/store`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.stores = response['results']['data'];
			});
	}

	loadSuppliers() {
		this.http.get(`${environment.apiUrl}/supplier`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.suppliers = response['results']['data'];
			});
	}

	openBill(id) {
	}

	setSearchForm() {
		this.searchForm = !this.searchForm;
	}
}
