<mat-card class="mat-elevation-z8">

	<mat-toolbar>
		<mat-toolbar-row>
			<button mat-raised-button color="link" (click)="setSearchForm()">
				<mat-icon>search</mat-icon>
				{{ 'search' | i18n }}
			</button>
			<a href="{{ csv_url }}" mat-raised-button color="link">
				<mat-icon>cloud_download</mat-icon>
				{{ 'export' | i18n }}
			</a>
			<span class="spacer"></span>
			<h2 class="title"> {{ 'members' | i18n }} </h2>
		</mat-toolbar-row>
	</mat-toolbar>

	<mat-card *ngIf="searchForm">
		<div class="form-row">

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'from_date' | i18n }}</mat-label>
				<input matInput [matDatepicker]="from_picker" [(ngModel)]="filter.from_date"
					placeholder="{{ 'from_date' | i18n }}" (keyup)="get()">
				<mat-datepicker-toggle matSuffix [for]="from_picker"></mat-datepicker-toggle>
				<mat-datepicker #from_picker></mat-datepicker>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'to_date' | i18n }}</mat-label>
				<input matInput [matDatepicker]="to_picker" [(ngModel)]="filter.to_date"
					placeholder="{{ 'to_date' | i18n }}" (keyup)="get()">
				<mat-datepicker-toggle matSuffix [for]="to_picker"></mat-datepicker-toggle>
				<mat-datepicker #to_picker></mat-datepicker>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'mobile' | i18n }}</mat-label>
				<input matInput [(ngModel)]="filter.mobile" placeholder="{{ 'mobile' | i18n }}" (keyup)="get()" />
			</mat-form-field>

		</div>
	</mat-card>

	<mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
		<mat-progress-spinner color="primary" mode="indeterminate">
		</mat-progress-spinner>
	</mat-card>

	<table mat-table [dataSource]="items">

		<!-- Checkbox Column -->
		<ng-container matColumnDef="select">
			<th mat-header-cell *matHeaderCellDef>
				<mat-checkbox (change)="$event ? masterToggle() : null;"
					[checked]="selection.hasValue() && isAllSelected()"
					[indeterminate]="selection.hasValue() && !isAllSelected()">
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row">
				<mat-checkbox (click)="$event.stopPropagation()"
					(change)="$event ? selection.toggle(row) : null;updateSelectionIds()"
					[checked]="selection.isSelected(row)">
				</mat-checkbox>
			</td>
		</ng-container>

		<!-- _id Column -->
		<ng-container matColumnDef="_id">
			<th mat-header-cell *matHeaderCellDef> # </th>
			<td mat-cell *matCellDef="let i"> <b [outerHTML]="i._id | _id"></b> </td>
		</ng-container>

		<!-- FullName Column -->
		<ng-container matColumnDef="member_name">
			<th mat-header-cell *matHeaderCellDef> {{ 'member_name' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ i.member_name }} </td>
		</ng-container>

		<!-- Mobile Column -->
		<ng-container matColumnDef="member_mobile">
			<th mat-header-cell *matHeaderCellDef> {{ 'member_mobile' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ i.member_mobile }} </td>
		</ng-container>

		<!-- Old wallet Column -->
		<ng-container matColumnDef="old_points">
			<th mat-header-cell *matHeaderCellDef> {{ 'old_points' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ i.old_points }} </td>
		</ng-container>

		<!-- New wallet Column -->
		<ng-container matColumnDef="new_points">
			<th mat-header-cell *matHeaderCellDef> {{ 'new_points' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ i.new_points }} </td>
		</ng-container>

		<!-- Old wallet Column -->
		<ng-container matColumnDef="old_wallet">
			<th mat-header-cell *matHeaderCellDef> {{ 'old_wallet' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ i.old_wallet }} </td>
		</ng-container>

		<!-- New wallet Column -->
		<ng-container matColumnDef="new_wallet">
			<th mat-header-cell *matHeaderCellDef> {{ 'new_wallet' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ i.new_wallet }} </td>
		</ng-container>

		<ng-container matColumnDef="notes">
			<th mat-header-cell *matHeaderCellDef> {{ 'notes' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ i.notes }} </td>
		</ng-container>

		<!-- Created Column -->
		<ng-container matColumnDef="created">
			<th mat-header-cell *matHeaderCellDef> {{ 'created' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ i.created }} </td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
	</table>

	<mat-paginator showFirstLastButtons [length]="pagination.length" [pageSize]="pagination.pageSize"
		[pageSizeOptions]="pagination.pageSizeOptions" (page)="next($event)">
	</mat-paginator>
</mat-card>
