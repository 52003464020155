import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';
import { Notification, NotificationError } from '../notification/notification.model';
import { OrderService } from '../order/order.service';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { OrderFilter } from '../order/order.model';
import { MemberNotificationService } from './member-notification.service';

@Component({
	selector: 'app-member-notification',
	templateUrl: './member-notification.component.html',
	styleUrls: ['./member-notification.component.scss']
})
export class MemberNotificationComponent extends BaseIndex {

	model = 'member';
	displayedColumns: string[] = ['select', '_id', 'fullname', 'mobile', 'orders_count', 'orders_total', 'tools'];
	formWidth: String = '800px';
	searchForm: Boolean = false;
	notificationForm: Boolean = false;
	item: Notification = new Notification();
	errors: NotificationError = new NotificationError();
	validate: NotificationError = new NotificationError();
	filter: OrderFilter = new OrderFilter();

	constructor(
		protected theService: OrderService,
		protected service: MemberNotificationService,
		protected router: Router, protected dialog: MatDialog) {
		super(theService, router, dialog);
	}

	get() {
		this.isLoading = true;
		this.theService.getByUser(this.filter, '', this.pagination.pageIndex, this.pagination.pageSize).subscribe((response) => {
			this.items = response.results.data;
			this.dataSource.data = response.results.data;
			this.pagination.length = response.results.total;
			this.selection = new SelectionModel<ITable>(true, []);
			this.SelectionIds = [];
			this.isLoading = false;
		});
	}

	send() {
		this.isLoading = true;
		this.service.send_notification(this.filter, this.item).subscribe((response) => {
			this.isLoading = false;
			this.notificationForm = !this.notificationForm;
			this.item = new Notification();
		});
	}

	setSearchForm() {
		this.searchForm = !this.searchForm;
		this.notificationForm = false;
	}

	setNotificationForm() {
		this.notificationForm = !this.notificationForm;
		this.searchForm = false;
	}

	getEmptyErrors() {
		return new NotificationError();
	}

}
