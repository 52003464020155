import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';
import { PasswordService } from './password.service';

@Component({
	selector: 'app-password',
	templateUrl: './password.component.html',
	styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit {

	item = {
		'old_password': '',
		'password': '',
	};
	errors = {
		message: null,
		old_password: null,
		password: null,
	};
	messages = {
		message: null,
	};

	constructor(private parent: AppComponent, private service: PasswordService, private router: Router) {
	}

	ngOnInit() {
	}

	updatePassword() {
		this.service.update_password(this.item).subscribe((response: any) => {
			this.messages.message = null;
			this.errors.message = null;
			if (response.success === true) {
				localStorage.setItem('token', response.results.token);
				localStorage.setItem('auth.user', JSON.stringify(response.results.user));
				this.parent.user = response.results.user;
				this.parent.pages = this.parent.get_pages();
				this.router.navigate(['logout']);
			} else {
				this.errors = response.errors;
				console.log(this.errors);
			}
		});
	}

}
