import { Adminmember } from "../../pages/adminmember/adminmember.model"

export function adminUser(): Adminmember {
	try {
		let _user = localStorage.getItem('auth.user');
		let __user = _user = _user ? JSON.parse(_user) : {};
		return __user as Adminmember;
	} catch (error) {
		return null;
	}
};

export function getCircularReplacer(excludeArrays = false) {
	const seen = new WeakSet();
	return (key, value) => {
		const arrCond = excludeArrays ? !Array.isArray(value) && isNaN(key) : true;
		if (typeof value === "object" && value !== null && arrCond) {
			if (seen.has(value)) {
				return;
			}
			seen.add(value);
		}
		return value;
	};
};


export function getCircularReplacerImproved() {
    const ancestors = [];
    return function (key, value) {
        if (typeof value !== "object" || value === null) {
            return value;
        }
        // `this` is the object that value is contained in,
        // i.e., its direct parent.
        while (ancestors.length > 0 && ancestors[ancestors.length - 1] !== this) {
            ancestors.pop();
        }
        if (ancestors.includes(value)) {
            return "[Circular]";
        }
        ancestors.push(value);
        return value;
    };
}
