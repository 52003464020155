<form class="form-signin">

	<h1>{{ 'update_password' | i18n }}</h1>

	<div *ngIf="messages.message" class="alert alert-success" role="alert">
		{{ messages.message }}
	</div>
	<div *ngIf="errors.message || errors.old_password || errors.password" class="alert alert-danger" role="alert">
		{{ errors.message }} <br />
		{{ errors.old_password }} <br />
		{{ errors.password }}
	</div>

	<mat-card>

		<mat-form-field>
			<input matInput type="password" type="old_password" [(ngModel)]="item.old_password" name="old_password" class="form-control"
				placeholder="{{ 'old_password' | i18n }}" required>
		</mat-form-field>
		<br />

		<mat-form-field>
			<input matInput type="password" [(ngModel)]="item.password" name="password" class="form-control"
				placeholder="{{ 'password' | i18n }}" required>
		</mat-form-field>
		<br />

		<a mat-raised-button color="primary" href="javascript:void(0)" (click)="updatePassword()">{{ 'update_password' | i18n }}</a>
	</mat-card>
</form>
