<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>
	<mat-card *ngIf="!isDuplicateAction">
		<mat-card-subtitle>{{ 'translated_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<mat-tab-group>
				<mat-tab *ngFor="let l of languages">
					<ng-template mat-tab-label>
						<i class="flag-icon flag-icon-{{l.image}}"></i>
						{{l.name}}
					</ng-template>
					<div class="col-12" dir="{{l.direction}}">

						<mat-form-field appearance="outline" class="form-group col-md-6">
							<mat-label>{{ 'name' | i18n }} ( {{ l.name }} )</mat-label>
							<input matInput [(ngModel)]="item.name[l.code]" [errorStateMatcher]='validate.name[l.code]'
								placeholder="{{ 'name' | i18n }}" />
							<mat-error>{{ errors.name[l.code] }}</mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-group col-md-6 mat-upload">
							<mat-label>{{'picture' | i18n}} ( {{ l.name }} )</mat-label>
							<input type="file" (change)="upload($event.target.files, 'picture_lang.' + l.code)" />
							{{'upload_percent' | i18n}}: {{percentDone}}%
							<img *ngIf="item.picture_lang[l.code]" src="{{environment.mediaUrl}}{{item.picture_lang[l.code]}}" width="48" />
							<input matInput hidden [(ngModel)]="item.picture_lang[l.code]" [errorStateMatcher]='validate.picture_lang[l.code]'>
							<br />
							<mat-error>{{ errors.picture_lang[l.code] }}</mat-error>
						</mat-form-field>

					</div>
				</mat-tab>
			</mat-tab-group>
		</mat-card-content>
	</mat-card>
	<mat-divider></mat-divider>

	<mat-card>
		<mat-card-subtitle>{{ 'other_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'parent' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'parent' | i18n }}" [(ngModel)]="item.parent_id"
						[errorStateMatcher]='validate.parent_id'>
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of parents" [value]="c._id">
							{{c.name[config.lang]}}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field *ngIf="!isDuplicateAction" appearance="outline" class="form-group col-md-6 mat-upload">
					<mat-label>{{'picture' | i18n}}</mat-label>
					<input type="file" (change)="upload($event.target.files)" />
					{{'upload_percent' | i18n}}: {{percentDone}}%
					<img *ngIf="item.picture" src="{{environment.mediaUrl}}{{item.picture}}" width="48" />
					<input matInput hidden [(ngModel)]="item.picture" [errorStateMatcher]='validate.picture'
						name="picture">
					<br />
					<mat-error>{{ errors.picture }}</mat-error>
				</mat-form-field>

				<mat-form-field *ngIf="!isDuplicateAction" appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'featured' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.featured" [errorStateMatcher]='validate.featured'
						placeholder="{{ 'featured' | i18n }}" />
					<mat-error>{{ errors.featured }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'supplier' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'supplier' | i18n }}" [(ngModel)]="item.supplier_id"
						[errorStateMatcher]='validate.supplier_id' (ngModelChange)="supplierChangeHander()">
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of suppliers" [value]="c._id">
							{{ c.name[config.lang] }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field *ngIf="!isDuplicateAction" appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
						[errorStateMatcher]='validate.status' name="status">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.status }}</mat-error>
				</mat-form-field>

			</div>
		</mat-card-content>
	</mat-card>

	<mat-divider></mat-divider>

	<mat-card *ngIf="!isDuplicateAction">
		<mat-card-subtitle>{{'sorting_n_bills' | i18n}}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">

				<mat-form-field appearance="outline" class="form-group col-md-5">
					<mat-label>{{ 'store' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'store' | i18n }}" [(ngModel)]="categorynbill['store_id']"
						[errorStateMatcher]='validate_categorynstore["store_id"]' name="store_id">
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of stores" [value]="c._id">
							{{c.name[config.lang]}}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-5">
					<mat-label>{{ 'sorting' | i18n }}</mat-label>
					<input matInput [(ngModel)]="categorynbill['sorting']"
						[errorStateMatcher]='validate_categorynbill["sorting"]' name="sorting"
						placeholder="{{ 'sorting' | i18n }}" />
				</mat-form-field>

				<div class="form-group col-md-2">
					<button mat-raised-button color="primary"
						[disabled]="!categorynbill['store_id'] || !categorynbill['sorting'] || +categorynbill['sorting'] <= 0 || categorynbill['status'] === null"
						(click)="add_categorynbill()">
						<mat-icon>add</mat-icon>
						{{ 'add' | i18n }}
					</button>
				</div>

			</div>
			<div class="form-row">
				<table class="table table-bordered"
					*ngIf="item.category_n_billArr && item.category_n_billArr.length > 0">
					<tr>
						<td>{{ 'store' | i18n }}</td>
						<td>{{ 'sorting' | i18n }}</td>
						<td></td>
					</tr>
					<tr *ngFor="let p of item.category_n_billArr; let i = index;">
						<td>{{ storesObj[p['store_id']] }}</td>
						<td>
							<mat-form-field appearance="outline" class="form-group col-md-12">
								<mat-label>{{ 'sorting' | i18n }}</mat-label>
								<input matInput [(ngModel)]="item.category_n_billArr[i]['sorting']" />
							</mat-form-field>
						</td>
						<td>
							<button mat-mini-fab color="warn" (click)="updateCnB(p.store_id)"
								title="{{ 'delete' | i18n }}">
								<mat-icon>delete</mat-icon>
							</button>
						</td>
					</tr>
				</table>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-divider></mat-divider>

	<mat-card *ngIf="!isDuplicateAction">
		<mat-card-subtitle>{{'sorting_n_stores' | i18n}}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'store' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'store' | i18n }}" [(ngModel)]="categorynstore['store_id']"
						[errorStateMatcher]='validate_categorynstore["store_id"]' name="store_id">
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of stores" [value]="c._id">
							{{c.name[config.lang]}}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'sorting' | i18n }}</mat-label>
					<input matInput [(ngModel)]="categorynstore['sorting']"
						[errorStateMatcher]='validate_categorynstore["sorting"]' name="sorting"
						placeholder="{{ 'sorting' | i18n }}" />
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]="categorynstore['status']"
						name="status">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.status }}</mat-error>
				</mat-form-field>

				<div appearance="outline" class="form-group col-md-6">
					<button mat-raised-button color="primary"
						[disabled]="!categorynstore['store_id'] || !categorynstore['sorting'] || +categorynstore['sorting'] <= 0 || categorynstore['status'] === null"
						(click)="add_categorynstore()">
						<mat-icon>add</mat-icon>
						{{ 'add' | i18n }}
					</button>
				</div>

			</div>
			<div class="form-row">
				<table class="table table-bordered"
					*ngIf="item.category_n_storeArr && item.category_n_storeArr.length > 0">
					<tr>
						<td>{{ 'store' | i18n }}</td>
						<td>{{ 'sorting' | i18n }}</td>
						<td>{{ 'status' | i18n }}</td>
						<td></td>
					</tr>
					<tr *ngFor="let p of item.category_n_storeArr; let i = index;">
						<td>
							{{ storesObj[p['store_id']] }}
						</td>
						<td>
							<mat-form-field appearance="outline" class="form-group col-md-12">
								<mat-label>{{ 'sorting' | i18n }}</mat-label>
								<input matInput [(ngModel)]="item.category_n_storeArr[i]['sorting']" />
							</mat-form-field>
						</td>
						<td>
							<mat-slide-toggle [(ngModel)]="item.category_n_storeArr[i]['status']"
								[checked]="item.category_n_storeArr[i]['status']">
							</mat-slide-toggle>
						</td>
						<td>
							<button mat-mini-fab color="warn" (click)="updateCnS(p.store_id)"
								title="{{ 'delete' | i18n }}">
								<mat-icon>delete</mat-icon>
							</button>
						</td>
					</tr>
				</table>
			</div>
		</mat-card-content>
	</mat-card>

</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
