import { Component } from '@angular/core';
import { BaseDetails } from 'src/app/@core/libraries/pages/base-details';

@Component({
	selector: 'app-details',
	templateUrl: './details.component.html',
	styleUrls: ['./details.component.scss']
})
export class DetailsComponent extends BaseDetails {

	model: String = 'page';

}
