import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { SlideService } from './slide.service';
import { DetailsComponent } from './details/details.component';
import { FormComponent } from './form/form.component';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';

@Component({
	selector: 'app-slide',
	templateUrl: './slide.component.html',
	styleUrls: ['./slide.component.scss']
})
export class SlideComponent extends BaseIndex {

	model = 'slide';
	displayedColumns: string[] = ['select', '_id', 'name', 'created', 'status', 'tools'];
	theDetailsComponent = DetailsComponent;
	theFormComponent = FormComponent;

	constructor(protected theService: SlideService,
		protected router: Router, protected dialog: MatDialog) {
		super(theService, router, dialog);
	}

}
