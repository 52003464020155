
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';

export class DiscountPercentPerTotal {
	min_total: Number;
	discount: Number;
}

export class DiscountPercentPerTotalError {
	min_total: String = '';
	discount: String = '';
}

export class Coupon implements ITable {

	_id: String = '';
	name: String = '';
	code: String = '';
	products: String[] = [];
	categories: String[] = [];
	discount_value: Number = 0;
	member_id: String = '';
	max_uses: Number = 0;
	max_uses_per_user: Number = 0;
	number_of_uses: Number = 0;
	percent_value: Number = 0;
	valid_until: Date = new Date();
	apply_on_discounted_products: Boolean = false;
	supplier_id: String = '';
	suppliers: String[] = [];
	only_for_jm3eia: Boolean = false;
	status: Boolean = false;
	discount_percent_per_total: DiscountPercentPerTotal[] = [];
	free_shipping: Boolean = false;

}

export class CouponError implements IError {

	name: String = '';
	code: String = '';
	products: String = '';
	categories: String = '';
	discount_value: String = '';
	member_id: String = '';
	max_uses: String = '';
	max_uses_per_user: String = '';
	percent_value: String = '';
	valid_until: String = '';
	apply_on_discounted_products: String = '';
	supplier_id: String = '';
	suppliers: String = '';
	only_for_jm3eia: String = '';
	status: String = '';
	discount_percent_per_total: String = '';
	free_shipping: String = '';

}

export class CouponFilter {
	code?: string = null;
	from_date?: Date = null;
	to_date?: Date = null;
}
