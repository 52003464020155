<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>

	<mat-card>
		<mat-card-subtitle>{{ 'other_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'language_code' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'language_code' | i18n }}" [(ngModel)]='item.language_code'
						[errorStateMatcher]='validate.language_code' name="language_code">
						<mat-option [value]="'ar'">{{ 'languages_list_ar' | i18n }}</mat-option>
						<mat-option [value]="'en'">{{ 'languages_list_en' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.language_code }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'name' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.name" [errorStateMatcher]='validate.name'
						placeholder="{{ 'name' | i18n }}" />
					<mat-error>{{ errors.name }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'url' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.url" [errorStateMatcher]='validate.url'
						placeholder="{{ 'url' | i18n }}" />
					<mat-error>{{ errors.url }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6 mat-upload">
					<mat-label>{{ 'picture' | i18n }}</mat-label>
					<input type="file" (change)="upload($event.target.files)" />
					{{ 'upload_percent' | i18n }}: {{ percentDone }}%
					<img *ngIf="item.picture" src="{{ environment.mediaUrl }}{{ item.picture }}" width="48" />
					<input matInput hidden [(ngModel)]="item.picture" [errorStateMatcher]='validate.picture' name="picture">
					<br />
					<mat-error>{{ errors.picture }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'sorting' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.sorting" [errorStateMatcher]='validate.sorting'
						placeholder="{{ 'sorting' | i18n }}" />
					<mat-error>{{ errors.sorting }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'color' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.color" [errorStateMatcher]='validate.color'
						placeholder="{{ 'color' | i18n }}" />
					<mat-error>{{ errors.color }}</mat-error>
				</mat-form-field>


				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
						[errorStateMatcher]='validate.status' name="status">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.status }}</mat-error>
				</mat-form-field>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card>
		<mat-card-subtitle>{{ 'features' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">
				<mat-selection-list [(ngModel)]='item.features'>
					<mat-list-option *ngFor="let p of features" [value]="p._id">
						{{ p.name[config.lang] }}
					</mat-list-option>
				</mat-selection-list>
			</div>
		</mat-card-content>
	</mat-card>

</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
