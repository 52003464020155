import { Injectable } from '@angular/core';
import { BaseModelService } from 'src/app/@core/services/base-model.service';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})


export class MemberNotificationService extends BaseModelService {

	model: String = 'member';

	send_notification(filter = {}, body = {}): any {
		let query = '';
		const keys = Object.keys(filter);
		for (const k of keys) {
			if (!filter[k]) {
				continue;
			}
			query += `filter[${k}]=${filter[k]}&`;
		}
		return this.http.post(`${environment.apiUrl}/${this.model}/send_notification?${query}`, body);
	}

}
