import { LanguagesObject } from '../../@core/data/languages-object';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';

export class Slide implements ITable {

	_id: String = '';
	name: String = '';
	language_code: String = '';
	picture: String = '';
	url: String = '';
	features: Array<any> = [];
	sorting: Number = 0;
	color: String = '';
	status: Boolean = false;

}

export class SlideError implements IError {

	name: String = '';
	language_code: String = '';
	picture: String = '';
	url: String = '';
	features: String = '';
	sorting: String = '';
	color: String = '';
	status: String = '';

}
