import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';


export class Address {
	id: String = new Date().getTime().toString();
	name: String = '';
	city_id: String = '';
	country_id: String = '';
	widget: String = '';
	street: String = '';
	gada: String = '';
	house: String = '';
	latitude: String = '';
	longitude: String = '';
}

export class Member implements ITable {

	_id: String = '';
	fullname: String = '';
	username: String = '';
	password: String = '';
	email: String = '';
	mobile: String = '';
	wallet: Number = 0;
	box_number: String = '';
	status: Boolean = false;
	address: Address = new Address();
	addresses: Array<Address> = [];
}

export class MemberError implements IError {

	fullname: String = '';
	username: String = '';
	password: String = '';
	email: String = '';
	mobile: String = '';
	wallet: String = '';
	box_number: String = '';
	status: String = '';
	address: Address = new Address();
}

export class UserFilter {
	fullname?: string = null;
	email?: string = null;
	mobile?: string = null;
	has_wallet?: string = null;
	has_points?: string = null;
}

export class BulkMail {
	_id: String = "";
	subject: String = "";
	message: String = "";
}

export class BulkMailError implements IError {
	subject: String = "";
	message: String = "";
}

