import { Component } from '@angular/core';
import { SupplierCategory, SupplierCategoryError } from '../supplier_category.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { Store } from '../../store/store.model';
import { LanguagesObject } from 'src/app/@core/data/languages-object';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'supplier_category';
	item: SupplierCategory = new SupplierCategory();
	errors: SupplierCategoryError = new SupplierCategoryError();
	validate: SupplierCategoryError = new SupplierCategoryError();

	initialize() {
		
	}

	getEmptyErrors() {
		return new SupplierCategoryError();
	}

}
