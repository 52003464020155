import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	constructor() { }

	public getToken(): string {
		return localStorage.getItem('token');
	}

	public removeToken(): void {
		localStorage.removeItem('token');
	}

	public isAuthenticated(): boolean {
		return this.getToken ? true : false;
	}

}
