import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { AdminmemberService } from './adminmember.service';
import { DetailsComponent } from './details/details.component';
import { FormComponent } from './form/form.component';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';

@Component({
	selector: 'app-adminmember',
	templateUrl: './adminmember.component.html',
	styleUrls: ['./adminmember.component.scss']
})
export class AdminmemberComponent extends BaseIndex {

	model = 'adminmember';
	displayedColumns: string[] = ['select', '_id', 'fullname', 'group', 'email', 'created', 'status', 'tools'];
	theDetailsComponent = DetailsComponent;
	theFormComponent = FormComponent;

	constructor(protected theService: AdminmemberService,
		protected router: Router, protected dialog: MatDialog) {
		super(theService, router, dialog);
	}

}
