import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Display, DisplayContent, Setting, SettingError } from "./setting.model";
import { SettingService } from "./setting.service";
import { i18n } from "../../@core/libraries/i18n";
import { HttpClient, HttpEventType, HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MessageComponent } from "src/app/@theme/message/message.component";
import * as moment from "moment";
import * as ClassicEditor from "src/assets/vendors/ckeditor/ckeditor";
import { LanguagesService } from "src/app/@core/data/languages.service";
import { LanguagesObject } from "src/app/@core/data/languages-object";
import { BaseForm } from "src/app/@core/libraries/pages/base-form";

@Component({
	selector: "app-setting",
	templateUrl: "./setting.component.html",
	styleUrls: ["./setting.component.scss"],
})
export class SettingComponent implements OnInit {
	model = "setting";
	isLoading = false;
	percentDone: Number = 0;
	uploadSuccess: boolean;
	item: Setting = new Setting();
	errors: SettingError = new SettingError();
	validate: SettingError = new SettingError();
	order_minDate = new Date();
	public Editor = ClassicEditor;
	languages: any;
	min_delivery_times: Array<any> = [];
	days: Array<any> = [];
	times: Array<any> = [];
	jm3eia_pro_content = {
		image: "",
		title: { en: "", ar: "" },
		description: { en: "", ar: "" },
	};
	public readonly environment: Object = environment;

	constructor(
		protected theService: SettingService,
		public http: HttpClient,
		public _snackBar: MatSnackBar,
		protected router: Router,
		private languagesService: LanguagesService
	) {
		this.languages = this.languagesService.get();
	}

	ngOnInit(): void {
		this.isLoading = true;
		const item = new Setting();

		this.days = moment.weekdays().map((i, idx) => {
			return {
				id: idx,
				text: i,
			};
		});
		for (let i = 0; i < 24; i++) {
			this.times.push({
				id: i,
				text: `${i18n.from} ${i.toString().padStart(2, "0")} ${
					i18n.to
				} ${(i + 1).toString().padStart(2, "0")}`,
			});
		}

		let available_times = {};
		for (const i of this.times) {
			available_times[i.id] = {
				id: i.id,
				is_available: false,
				max_orders: 0,
			};
		}

		if (this.item.wallet.points_to_wallet == undefined) {
			this.item.wallet.points_to_wallet = 100;
		}

		this.item.orders.available_delivery_times = {};

		for (const i of this.days) {
			this.item.orders.available_delivery_times[i.id] = available_times;
		}

		this.theService.getAll().subscribe((response) => {
			for (const i of response.results.data) {
				if (i.key === "phones") {
					i.value = i.value.join(",");
				}
				item[i.key] = i.value;
			}
			this.item = item;
			if (!this.item.jm3eia_pro.subscriptions || this.item.jm3eia_pro.subscriptions.length === 0) {
				this.item.jm3eia_pro.subscriptions = [{duration_months: 3, price: 0}, {duration_months: 12, price: 0}];
			}

			if (this.item.display && !this.item.display.content) {
				this.item.display.content = new DisplayContent();
			}

			this.isLoading = false;
		});
		this.min_delivery_times = [];
		for (let i = 1; i <= 6; i++) {
			this.min_delivery_times.push(i * 30);
		}

		for (let i = 4; i <= 24; i++) {
			this.min_delivery_times.push(i * 60);
		}

	}
	

	time_convert(num) {
		const hours = ("0" + Math.floor(num / 60)).slice(-2);
		const minutes = ("0" + (num % 60)).slice(-2);
		return `${hours}:${minutes}`;
	}

	save() {
		const item = this.item;
		item.phones =
			typeof item.phones === "string"
				? item.phones.split(",")
				: item.phones;
		this.http.put(`${environment.apiUrl}/${this.model}`, item).subscribe(
			(response: any) => {
				if (response.errors) {
					return this.showMessage(i18n.unexpected_error);
				}
				return this.showMessage(i18n.saved_done);
			},
			(e) => {}
		);
	}

	showMessage(message) {
		this._snackBar.openFromComponent(MessageComponent, {
			duration: 5 * 1000,
			data: message,
		});
	}

	add_pro_content() {
		if (this.item.jm3eia_pro.content === undefined) {
			this.item.jm3eia_pro.content = [];
		}
		this.item.jm3eia_pro.content.push(
			Object.assign({}, this.jm3eia_pro_content)
		);
		this.jm3eia_pro_content = {
			image: "",
			title: { en: "", ar: "" },
			description: { en: "", ar: "" },
		};
		this.item.jm3eia_pro.content = this.item.jm3eia_pro.content;
	}

	delete_pro_content(index) {
		this.item.jm3eia_pro.content.splice(index, 1);
	}

	upload(files: File[], picture = 'picture') {
		// pick from one of the 4 styles of file uploads below
		this.uploadAndProgress(files, picture);
	}

	uploadAndProgress(files: File[], picture = 'picture') {
		this.uploadImagesAndProgress(files, picture).then((res: any) => {
			if (picture.includes('.')) {
				const arr = picture.split('.');
				console.log(arr.length);
				if (!this.item[arr[0]]) {
					this.item[arr[0]] = {};
				}
				if (arr.length < 3) {
					this.item[arr[0]][arr[1]] = res.path;
				}
				if (arr.length === 3) {
					this.item[arr[0]][arr[1]][arr[2]] = res.path;
				}
				if (arr.length === 4) {
					this.item[arr[0]][arr[1]][arr[2]][arr[3]] = res.path;
				}
				if (arr.length === 5) {
					this.item[arr[0]][arr[1]][arr[2]][arr[3]][arr[4]] = res.path;
				}
			} else {
				this.item[picture] = res.path;
			}
		});
	}

	uploadImagesAndProgress(files: File[], elm: string) {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			Array.from(files).forEach((f) => formData.append("picture", f));
			this.http
				.post(`${environment.apiUrl}/upload`, formData, {
					reportProgress: true,
					observe: "events",
				})
				.subscribe((event: any) => {
					if (event.type === HttpEventType.UploadProgress) {
						this.percentDone = Math.round(
							(100 * event.loaded) / event.total
						);
					} else if (event instanceof HttpResponse) {
						this.uploadSuccess = true;
						resolve(event.body.results);
					}
				});
		});
	}
}
