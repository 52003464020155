import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ProductService } from './product.service';
import { DetailsComponent } from './details/details.component';
import { FormComponent } from './form/form.component';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';
import { ProductFilter } from './product.model';
import { Category } from '../category/category.model';
import { HttpClient, HttpParams, HttpEventType, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Rank } from '../rank/rank.model';
import { Brand } from '../brand/brand.model';
import { MessageComponent } from 'src/app/@theme/message/message.component';
import { i18n } from '../../@core/libraries/i18n';
import { AuthService } from 'src/app/@core/services/auth.service';
import { Store } from '../store/store.model';
import { Feature } from '../feature/feature.model';
import { Supplier } from '../supplier/supplier.model';

@Component({
	selector: 'app-product',
	templateUrl: './product.component.html',
	styleUrls: ['./product.component.scss']
})
export class ProductComponent extends BaseIndex {

	model = 'product';
	displayedColumns: string[] = ['select', '_id', 'name', 'price', 'created', 'orders_count', 'status', 'total_sells_price', 'tools'];
	theDetailsComponent = DetailsComponent;
	theFormComponent = FormComponent;
	formWidth: String = '800px';
	detailsWidth: String = '600px';
	searchForm: Boolean = false;
	filter: ProductFilter = new ProductFilter();
	brands: Brand[] = [];
	suppliers: Supplier[] = [];
	features: Feature[] = [];
	categories: Category[] = [];
	ranks: Rank[] = [];
	percentDone: Number = 0;
	uploadSuccess: boolean;
	importForm: boolean;
	csv_url: string;
	csv_url_filtered: string;
	csv_store: string;
	stores: Store[] = [];
	is_csv_processing: boolean = false;
	is_csv_update: boolean = false;
	order_status_list: Array<Object> = [];

	constructor(protected theService: ProductService,
		public http: HttpClient,
		public _snackBar: MatSnackBar,
		public auth: AuthService,
		protected router: Router, protected dialog: MatDialog) {
		super(theService, router, dialog);
		this.loadBrands();
		this.loadSuppliers();
		this.loadFeatures();
		this.loadCategories();
		this.loadStores();

		const token = `${this.auth.getToken()}`;
		this.csv_url = `${environment.apiUrl}/product/export_csv?token=${token}&store_id=${this.csv_store}`;

		let filter_queries = '';

		for (let f of Object.keys(this.filter)) {
			filter_queries += `&filter[${f}]=${this.filter[f] ? this.filter[f] : ""}`;
		}

		this.csv_url_filtered = `${environment.apiUrl}/product/export_csv_filtered?token=${this.auth.getToken()}${filter_queries}`;

		this.order_status_list = Object.keys(i18n.order_status_list).map((k) => {
			return {
				key: k,
				value: i18n.order_status_list[k],
			};
		});

	}

	after_get() {
		let filter_queries = '';

		for (let f of Object.keys(this.filter)) {
			filter_queries += `&filter[${f}]=${this.filter[f] ? this.filter[f] : ""}`;
		}

		this.csv_url_filtered = `${environment.apiUrl}/product/export_csv_filtered?token=${this.auth.getToken()}${filter_queries}`;
	}

	loadBrands() {
		this.http.get(`${environment.apiUrl}/brand`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {
				this.brands = response['results']['data'];
			});
	}

	loadSuppliers() {
		this.http.get(`${environment.apiUrl}/supplier`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {
				this.suppliers = [{ _id: "Jm3eia", name: { en: "Jm3eia", ar: "Jm3eia ar" } }, ...response['results']['data']];
			});
	}

	loadFeatures() {
		this.http.get(`${environment.apiUrl}/feature`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {
				this.features = response['results']['data'];
			});
	}

	loadCategories() {
		this.http.get(`${environment.apiUrl}/category`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {
				this.categories = response['results']['data'];
			});
	}

	loadStores() {
		this.http.get(`${environment.apiUrl}/store`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {
				this.stores = response['results']['data'];
				const token = `${this.auth.getToken()}`;
				this.csv_store = this.stores[0]['_id'].toString();
				this.csv_url = `${environment.apiUrl}/product/export_csv?token=${token}&store_id=${this.csv_store}`;
			});
	}

	loadRanks() {
		this.http.get(`${environment.apiUrl}/rank`, {
			params: new HttpParams()
				.set('filter', `status=true&category_id=${this.filter.category_id}`)
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {
				this.ranks = response['results']['data'];
			});
	}

	setSearchForm() {
		this.searchForm = !this.searchForm;
	}

	showImportForm() {
		this.importForm = !this.importForm;
	}

	uploadAndImport(files: File[]) {
		const formData = new FormData();
		Array.from(files).forEach(f => formData.append('file', f));
		formData.append("is_update", this.is_csv_update.toString());
		this.is_csv_processing = true;
		this.http.post(`${environment.apiUrl}/product/import_csv?store_id=${this.csv_store}`, formData, { reportProgress: true, observe: 'events' })
			.subscribe((event: any) => {
				this.is_csv_processing = false;
				if (event.type === HttpEventType.UploadProgress) {
					this.percentDone = Math.round(100 * event.loaded / event.total);
				} else if (event instanceof HttpResponse) {
					this.uploadSuccess = true;
					this.showMessage(i18n.saved_done);
					this.get();
				}
			});
	}

	showMessage(message) {
		this._snackBar.openFromComponent(MessageComponent, {
			duration: 5 * 1000,
			data: message
		});
	}

}
