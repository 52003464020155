import { Component } from '@angular/core';
import { Rank, RankError } from '../rank.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Category } from '../../category/category.model';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'rank';
	item: Rank = new Rank();
	errors: RankError = new RankError();
	validate: RankError = new RankError();
	categories: Category[] = [];
	categoriesObj: any[] = [];

	initialize() {
		this.loadCategories();
	}

	loadCategories() {
		this.http.get(`${environment.apiUrl}/category`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '5000')
		})
			.subscribe((response) => {
				this.categories = response['results']['data'];
				for (const i of this.categories) {
					this.categoriesObj[i._id.toString()] = i.name[this.config.lang];
				}
			});
	}

	getEmptyErrors() {
		return new RankError();
	}

}
