<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>

	<mat-card>
		<mat-card-subtitle>{{ 'details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'fullname' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.fullname" [errorStateMatcher]='validate.fullname'
						placeholder="{{ 'fullname' | i18n }}" />
					<mat-error>{{ errors.fullname }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'username' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.username" [errorStateMatcher]='validate.username'
						placeholder="{{ 'username' | i18n }}" />
					<mat-error>{{ errors.username }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'email' | i18n }}</mat-label>
					<input type="email" matInput [(ngModel)]="item.email" [errorStateMatcher]='validate.email'
						placeholder="{{ 'email' | i18n }}" />
					<mat-error>{{ errors.email }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'mobile' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.mobile" [errorStateMatcher]='validate.mobile'
						placeholder="{{ 'mobile' | i18n }}" />
					<mat-error>{{ errors.mobile }}</mat-error>
				</mat-form-field>

				<mat-form-field *ngIf="user.can_update_wallet" appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'wallet' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.wallet" [errorStateMatcher]='validate.wallet'
						placeholder="{{ 'wallet' | i18n }}" />
					<mat-error>{{ errors.wallet }}</mat-error>
				</mat-form-field>
				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'box_number' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.box_number" [errorStateMatcher]='validate.box_number'
						placeholder="{{ 'box_number' | i18n }}" />
					<mat-error>{{ errors.box_number }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'password' | i18n }}</mat-label>
					<input type="password" matInput [(ngModel)]="item.password" [errorStateMatcher]='validate.password'
						placeholder="{{ 'password' | i18n }}" />
					<mat-error>{{ errors.password }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
						[errorStateMatcher]='validate.status' name="status">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.status }}</mat-error>
				</mat-form-field>

			</div>
		</mat-card-content>
	</mat-card>

	<mat-card>
		<mat-card-subtitle>{{ 'user_address' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'city' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'city' | i18n }}" [(ngModel)]="item.address.city_id"
						[errorStateMatcher]='validate.address.city_id'>
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of cities" [value]="c._id">
							{{c.name[config.lang]}}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'address.widget' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.address.widget" [errorStateMatcher]='validate.address.widget'
						placeholder="{{ 'address.widget' | i18n }}" />
					<mat-error>{{ errors.address.widget }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'address.gada' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.address.gada" [errorStateMatcher]='validate.address.gada'
						placeholder="{{ 'address.gada' | i18n }}" />
					<mat-error>{{ errors.address.gada }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'address.street' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.address.street" [errorStateMatcher]='validate.address.street'
						placeholder="{{ 'address.street' | i18n }}" />
					<mat-error>{{ errors.address.street }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'address.house' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.address.house" [errorStateMatcher]='validate.address.house'
						placeholder="{{ 'address.house' | i18n }}" />
					<mat-error>{{ errors.address.house }}</mat-error>
				</mat-form-field>

			</div>
		</mat-card-content>
	</mat-card>


	<mat-card>
		<mat-card-subtitle>{{'addresses' | i18n}}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'country' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'country' | i18n }}" [(ngModel)]="new_address['country_id']" (change)="loadCities()"
						[errorStateMatcher]='validate_new_address["country_id"]' name="country_id">
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of countries" [value]="c._id">
							{{c.name[config.lang]}}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'city' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'city' | i18n }}" [(ngModel)]="new_address['city_id']"
						[errorStateMatcher]='validate_new_address["city_id"]' name="city_id">
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of cities" [value]="c._id">
							{{c.name[config.lang]}}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'address name' | i18n }}</mat-label>
					<input matInput [(ngModel)]="new_address['name']"
						[errorStateMatcher]='validate_new_address["name"]' name="name"
						placeholder="{{ 'name' | i18n }}" />
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'widget' | i18n }}</mat-label>
					<input matInput [(ngModel)]="new_address['widget']"
						[errorStateMatcher]='validate_new_address["widget"]' name="widget"
						placeholder="{{ 'widget' | i18n }}" />
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'gada' | i18n }}</mat-label>
					<input matInput [(ngModel)]="new_address['gada']"
						[errorStateMatcher]='validate_new_address["gada"]' name="gada"
						placeholder="{{ 'gada' | i18n }}" />
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'street' | i18n }}</mat-label>
					<input matInput [(ngModel)]="new_address['street']"
						[errorStateMatcher]='validate_new_address["street"]' name="sorting"
						placeholder="{{ 'street' | i18n }}" />
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'house' | i18n }}</mat-label>
					<input matInput [(ngModel)]="new_address['house']"
						[errorStateMatcher]='validate_new_address["house"]' name="sorting"
						placeholder="{{ 'house' | i18n }}" />
				</mat-form-field>

				<div class="form-group col-md-2">
					<button mat-raised-button color="primary"
						[disabled]="!new_address['city_id'] || !new_address['widget'] || !new_address['gada'] || !new_address['street'] || !new_address['house']"
						(click)="add_new_address()">
						<mat-icon>add</mat-icon>
						{{ 'save' | i18n }}
					</button>
				</div>

			</div>
			<div class="form-row">
				<table class="table table-bordered"
					*ngIf="item.addresses && item.addresses.length > 0">
					<tr>
						<td>{{ 'address_name' | i18n }}</td>
						<td>{{ 'details' | i18n }}</td>
						<td></td>
					</tr>
					<tr *ngFor="let p of item.addresses; let i = index;">
						<td>{{ p.name }}</td>
						<td>
							<b>{{ 'widget' | i18n }}</b> : {{ p.widget }},
							<b>{{ 'street' | i18n }}</b> : {{ p.street }},
							<b>{{ 'gada' | i18n }}</b> : {{ p.gada }},
							<b>{{ 'house' | i18n }}</b> : {{ p.house }},
							<b>{{ 'city' | i18n }}</b> : {{ p.city_name }}
						</td>
						<td>
							<button mat-mini-fab color="link" (click)="edit_address(p)"
								title="{{ 'edit' | i18n }}">
								<mat-icon>edit</mat-icon>
							</button>
							<button mat-mini-fab color="warn" (click)="delete_address(p.id)"
								title="{{ 'delete' | i18n }}">
								<mat-icon>delete</mat-icon>
							</button>
						</td>
					</tr>
				</table>
			</div>
		</mat-card-content>
	</mat-card>



</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
