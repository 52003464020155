import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { EmployeeService } from './employee.service';
import { DetailsComponent } from './details/details.component';
import { FormComponent } from './form/form.component';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { MessageComponent } from 'src/app/@theme/message/message.component';
import { i18n } from '../../@core/libraries/i18n';

@Component({
	selector: 'app-employee',
	templateUrl: './employee.component.html',
	styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent extends BaseIndex {

	model = 'employee';
	displayedColumns: string[] = ['select', '_id', 'name', 'username', 'mobile', 'created', 'status', 'tools'];
	theDetailsComponent = DetailsComponent;
	theFormComponent = FormComponent;
	percentDone: Number = 0;
	uploadSuccess: boolean;
	importForm: boolean;
	csv_url: string;
	is_csv_processing: boolean = false;

	constructor(protected theService: EmployeeService,
		protected router: Router, public http: HttpClient, public _snackBar: MatSnackBar, protected dialog: MatDialog) {
		super(theService, router, dialog);
	}

	showImportForm() {
		this.importForm = !this.importForm;
	}

	uploadAndImport(files: File[]) {
		const formData = new FormData();
		Array.from(files).forEach(f => formData.append('file', f));
		this.is_csv_processing = true;
		this.http.post(`${environment.apiUrl}/employee/import_csv`, formData, { reportProgress: true, observe: 'events' })
			.subscribe((event: any) => {
				this.is_csv_processing = false;
				if (event.type === HttpEventType.UploadProgress) {
					this.percentDone = Math.round(100 * event.loaded / event.total);
				} else if (event instanceof HttpResponse) {
					this.uploadSuccess = true;
					this.showMessage(i18n.saved_done);
					this.get();
				}
			});
	}

	showMessage(message) {
		this._snackBar.openFromComponent(MessageComponent, {
			duration: 5 * 1000,
			data: message
		});
	}

}
