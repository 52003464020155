<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>
	<mat-card>
		<mat-card-subtitle>{{ 'translated_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<mat-tab-group>
				<mat-tab *ngFor="let l of languages">
					<ng-template mat-tab-label>
						<i class="flag-icon flag-icon-{{ l.image }}"></i>
						{{ l.name }}
					</ng-template>

					<div class="col-12" dir="{{ l.direction }}">

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'name' | i18n }} ( {{ l.name }} )</mat-label>
							<input matInput [(ngModel)]="item.name[l.code]" [errorStateMatcher]='validate.name[l.code]'
								placeholder="{{ 'name' | i18n }}" />
							<mat-error>{{ errors.name[l.code] }}</mat-error>
						</mat-form-field>

					</div>

				</mat-tab>
			</mat-tab-group>
		</mat-card-content>
	</mat-card>
	<mat-divider></mat-divider>

	<mat-card>
		<mat-card-subtitle>{{ 'other_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'option_type' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'type' | i18n }}" [(ngModel)]='item.type'
						[errorStateMatcher]='validate.type' name="type">
						<mat-option [value]="option_type.STRING">
							{{ 'option_type.string' | i18n }}</mat-option>
						<mat-option [value]="option_type.COLOR">{{ 'option_type.color' | i18n }}
						</mat-option>
						<mat-option [value]="option_type.NUMBER">
							{{ 'option_type.number' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.type }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
						[errorStateMatcher]='validate.status' name="status">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.status }}</mat-error>
				</mat-form-field>

			</div>
		</mat-card-content>
	</mat-card>

	<mat-card>
		<mat-card-subtitle>{{ 'options_list' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">

				<mat-form-field *ngFor="let l of languages" appearance="outline" class="form-group col-md-6">
					<mat-label>
						{{ 'name' | i18n }}
						<i class="flag-icon flag-icon-{{ l.image }}"></i>
						{{ l.name }}
					</mat-label>
					<input matInput [(ngModel)]="new_option['name'][l.code]"
						[errorStateMatcher]='validate_new_option["name"][l.code]' name="name"
						placeholder="{{ 'name' | i18n }}" />
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>
						{{ 'sku_code' | i18n }}
					</mat-label>
					<input matInput [(ngModel)]="new_option['sku_code']"
						[errorStateMatcher]='validate_new_option["sku_code"]' name="sku_code"
						placeholder="{{ 'sku_code' | i18n }}" />
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>
						{{ 'value' | i18n }}
					</mat-label>
					<input matInput [(ngModel)]="new_option['value']" [errorStateMatcher]='validate_new_option["value"]'
						name="value"
						type="{{ item.type == option_type.NUMBER ? 'number' : item.type == option_type.COLOR ? 'color' : 'text' }}"
						placeholder="{{ 'value' | i18n }}" />
				</mat-form-field>

				<div class="form-group col-md-2">
					<button mat-raised-button color="primary"
						[disabled]="!new_option.name[config.lang] || !new_option.value || !new_option.sku_code"
						(click)="add_new_option()">
						<mat-icon>add</mat-icon>
						{{ 'add' | i18n }}
					</button>
				</div>

			</div>
			<div class="form-row">
				<table class="table table-bordered" *ngIf="item.options_list && item.options_list.length > 0">
					<tr>
						<td>{{ 'name' | i18n }}</td>
						<td>{{ 'sku_code' | i18n }}</td>
						<td>{{ 'value' | i18n }}</td>
						<td></td>
					</tr>
					<tr *ngFor="let p of item.options_list; let i = index;">
						<td>{{ p.name[config.lang] }}</td>
						<td>
							<input matInput [(ngModel)]="p.sku_code"
								placeholder="{{ 'sku_code' | i18n }}" />
						</td>
						<td>{{ p.value }}</td>
						<td>
							<button mat-mini-fab color="warn" (click)="updateO(p.value)"
								title="{{ 'delete' | i18n }}">
								<mat-icon>delete</mat-icon>
							</button>
						</td>
					</tr>
				</table>
			</div>
		</mat-card-content>
	</mat-card>

</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
