import { Component } from '@angular/core';
import { Page, PageError } from '../page.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import * as ClassicEditor from 'src/assets/vendors/ckeditor/ckeditor';


@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'page';
	item: Page = new Page();
	errors: PageError = new PageError();
	validate: PageError = new PageError();

	public Editor = ClassicEditor;

	initialize() {
	}

	getEmptyErrors() {
		return new PageError();
	}

}
