<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>
	<mat-card>
		<mat-card-subtitle>{{ 'translated_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<mat-tab-group>
				<mat-tab *ngFor="let l of languages">
					<ng-template mat-tab-label>
						<i class="flag-icon flag-icon-{{ l.image }}"></i>
						{{ l.name }}
					</ng-template>
					<div class="col-12" dir="{{ l.direction }}">

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'name' | i18n }} ( {{ l.name }} )
							</mat-label>
							<input matInput [(ngModel)]="item.name[l.code]" [errorStateMatcher]='validate.name[l.code]'
								placeholder="{{ 'name' | i18n }}" />
							<mat-error>{{ errors.name[l.code] }}</mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'description' | i18n }} ( {{ l.name }} )
							</mat-label>
							<textarea matInput [(ngModel)]="item.description[l.code]" [errorStateMatcher]='validate?.description[l.code]'
								placeholder="{{ 'description' | i18n }}"></textarea>
							<mat-error>{{ errors.description[l.code] }}</mat-error>
						</mat-form-field>

					</div>
				</mat-tab>
			</mat-tab-group>
		</mat-card-content>
	</mat-card>
	<mat-card>
		<mat-card-subtitle>{{ 'offer' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">
				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'min_amount' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.min_amount" [errorStateMatcher]='validate.min_amount'
						placeholder="{{ 'min_amount' | i18n }}" />
					<mat-error>{{ errors.min_amount }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'target_amount' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.target_amount" [errorStateMatcher]='validate.target_amount'
						placeholder="{{ 'target_amount' | i18n }}" />
					<mat-error>{{ errors.target_amount }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'type' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'type' | i18n }}" [(ngModel)]='item.type'
						[errorStateMatcher]='validate.type' name="type">
						<!-- <mat-option value="free_shipping">{{ 'shipping' | i18n }}</mat-option> -->
						<mat-option value="free_product">{{ 'product' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.type }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'ignore_target_amount' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'ignore_target_amount' | i18n }}" [(ngModel)]='item.ignore_target_amount'
						[errorStateMatcher]='validate.ignore_target_amount' name="ignore_target_amount">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.ignore_target_amount }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'expires_at' | i18n }}</mat-label>
					<input matInput [min]="minDate" [matDatepicker]="picker" [(ngModel)]="item.expires_at"
						[errorStateMatcher]='validate.expires_at' placeholder="{{ 'expires_at' | i18n }}" />
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-datepicker #picker></mat-datepicker>
					<mat-error>{{ errors.expires_at }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'product_sku' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.product_sku" [errorStateMatcher]='validate.product_sku'
						placeholder="{{ 'product_sku' | i18n }}" />
					<mat-error>{{ errors.product_sku }}</mat-error>
				</mat-form-field>

				<!-- <mat-form-field appearance="outline" class=" form-group col-md-6">
					<mat-label>{{ 'products' | i18n }}</mat-label>
					<div class="tag-input-container">
						<div *ngFor="let product of item.products; let i = index" class="tag">
						  {{ product }}
						  <span class="remove-icon" (click)="removeTag(i)">&times;</span>
						</div>
					</div>
					<input
					  matInput
					  type="text"
					  [(ngModel)]="currentTag"
					  (keyup.enter)="addTag()"
					  placeholder="Enter tags..."
					/>
					<mat-error>{{ errors.product_sku }}</mat-error>
				  </mat-form-field> -->

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
						[errorStateMatcher]='validate.status' name="status">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.status }}</mat-error>
				</mat-form-field>

			</div>
		</mat-card-content>
	</mat-card>

</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
