<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>
	<mat-card>
		<mat-card-subtitle>{{ 'translated_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<mat-tab-group>
				<mat-tab *ngFor="let l of languages">
					<ng-template mat-tab-label>
						<i class="flag-icon flag-icon-{{l.image}}"></i>
						{{l.name}}
					</ng-template>
					<div class="form-row" dir="{{l.direction}}">
						<mat-form-field>
							<input matInput [(ngModel)]="item.name[l.code]" [errorStateMatcher]='validate.name[l.code]'
								name="name[l.code]" placeholder="{{ 'name' | i18n }} ( {{ l.name }} )">
							<mat-error>{{ errors.name[l.code] }}</mat-error>
						</mat-form-field>
					</div>
				</mat-tab>
			</mat-tab-group>
		</mat-card-content>
	</mat-card>
	<mat-divider></mat-divider>

	<mat-card>
		<mat-card-subtitle>{{ 'other_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="form-row">
				<mat-form-field>
					<mat-select placeholder="{{ 'parent' | i18n }}" [(ngModel)]="item.parent_id"
						[errorStateMatcher]='validate.parent_id' name="parent_id">
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of parents" [value]="c._id">
							{{c.name[config.lang]}}
						</mat-option>
					</mat-select>
					<mat-error>{{ errors.parent_id }}</mat-error>
				</mat-form-field>
			</div>

			<div class="form-row">
				<mat-form-field>
					<input matInput [(ngModel)]="item.sorting" [errorStateMatcher]='validate.sorting' name="sorting"
						placeholder="{{ 'sorting' | i18n }}">
					<mat-error>{{ errors.sorting }}</mat-error>
				</mat-form-field>
			</div>

			<div class="form-row">
				<mat-radio-group [(ngModel)]="item.enable_chatting">
					<mat-label>{{ 'enable_chatting' | i18n }}</mat-label>
					<mat-radio-button [value]="true">{{ 'true' | i18n }}</mat-radio-button>
					<mat-radio-button [value]="false">{{ 'false' | i18n }}</mat-radio-button>
				</mat-radio-group>
				<mat-error>{{ errors.enable_chatting }}</mat-error>
			</div>

			<div class="form-row">
				<mat-radio-group [(ngModel)]="item.enable_rating">
					<mat-label>{{ 'enable_rating' | i18n }}</mat-label>
					<mat-radio-button [value]="true">{{ 'true' | i18n }}</mat-radio-button>
					<mat-radio-button [value]="false">{{ 'false' | i18n }}</mat-radio-button>
				</mat-radio-group>
				<mat-error>{{ errors.enable_rating }}</mat-error>
			</div>

			<div class="form-row">
				<mat-radio-group [(ngModel)]="item.status">
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-radio-button [value]="true">{{ 'true' | i18n }}</mat-radio-button>
					<mat-radio-button [value]="false">{{ 'false' | i18n }}</mat-radio-button>
				</mat-radio-group>
				<mat-error>{{ errors.status }}</mat-error>
			</div>

		</mat-card-content>
	</mat-card>

</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
