<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>
	<mat-card>
		<mat-card-subtitle>{{ 'details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'name' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.name" [errorStateMatcher]='validate.name'
						placeholder="{{ 'name' | i18n }}" />
					<mat-error>{{ errors.name }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'code' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.code" [errorStateMatcher]='validate.code'
						placeholder="{{ 'code' | i18n }}" />
					<mat-error>{{ errors.code }}</mat-error>
				</mat-form-field>


				<div class="col-md-6">
					<mat-form-field appearance="outline" class="form-group col-md-12 mb-0" [hidden]="item.max_uses_per_user > 0">
						<mat-label>{{ 'member' | i18n }}</mat-label>
						<input matInput [(ngModel)]="query" id="query" (keyup)="loadMembers()" [errorStateMatcher]='validate.member_id'
							placeholder="{{ 'member' | i18n }}" />
						<mat-error>{{ errors.member_id }}</mat-error>
					</mat-form-field>
	
					<div class="col-md-12">
						<ul class="list-group pt-0 mt-0">
							<li class="list-group-item" *ngFor="let m of members">
								<a href="javascript:void(0)" (click)="set_member(m)">
									{{m.username}} ({{m.email}})
								</a>
							</li>
						</ul>
					</div>
				</div>

				<div class="col-md-6 form-group" style="border: 1px solid #ccc; border-radius: 10px; display: flex; align-items: center;" *ngIf="!item.percent_value && !item.discount_value && (!item.discount_percent_per_total || item.discount_percent_per_total.length === 0)">
					<mat-chip-list #chipList>
						<mat-chip
						  *ngFor="let p of item.products"
						  [removable]="true"
						  (removed)="removeValue(p)"
						  class="border"
						>
						  {{ p }}
						  <mat-icon matChipRemove>cancel</mat-icon>
						</mat-chip>
						<input
						  placeholder="Enter sku"
						  [(ngModel)]="product"
						  (keydown.backspace)="removeLastChip()"
						  (keydown.enter)="addValue()"
						  (blur)="addValue()"
						  [matChipInputFor]="chipList"
						  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
						/>
					  </mat-chip-list>
				</div>
				  

				<mat-form-field *ngIf="!item.percent_value && (!item.products || item.products.length === 0) && (!item.discount_percent_per_total || item.discount_percent_per_total.length === 0)" appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'discount_value' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.discount_value" [errorStateMatcher]='validate.discount_value'
						placeholder="{{ 'discount_value' | i18n }}" />
					<mat-error>{{ errors.discount_value }}</mat-error>
				</mat-form-field>

				<mat-form-field *ngIf="!item.discount_value && (!item.products || item.products.length === 0) && (!item.discount_percent_per_total || item.discount_percent_per_total.length === 0)" appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'percent_value' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.percent_value" [errorStateMatcher]='validate.percent_value'
						placeholder="{{ 'percent_value' | i18n }}" />
					<mat-error>{{ errors.percent_value }}</mat-error>
				</mat-form-field>

				<mat-card *ngIf="!item.discount_value && (!item.products || item.products.length === 0) && !item.percent_value" class="col-md-12 my-3">
					<mat-card-subtitle>{{'discount_per_total' | i18n}}</mat-card-subtitle>
					<mat-card-content>
						<div class="row">
							<mat-form-field appearance="outline" class="form-group col-md-5">
								<mat-label>{{ 'min_total' | i18n }}</mat-label>
								<input matInput [(ngModel)]="discount_per_total.min_total"
									   placeholder="{{ 'min_total' | i18n }}" />
							  </mat-form-field>
							  <mat-form-field appearance="outline" class="form-group col-md-5">
								<mat-label>{{ 'discount_percent' | i18n }}</mat-label>
								<input matInput [(ngModel)]="discount_per_total.discount"
									   placeholder="{{ 'discount_percent' | i18n }}" />
							  </mat-form-field>
							  <div appearance="outline" class="form-group col-md-2">
								<button mat-raised-button color="primary"
									[disabled]="!discount_per_total"
									(click)="add_discount_percent_per_total()"
									>
									<mat-icon>add</mat-icon>
									{{ 'add' | i18n }}
								</button>
							</div>
						</div>
						<div class="form-row" *ngIf="item.discount_percent_per_total" style="max-height: 300px; overflow-y: scroll;">
							<table class="table table-bordered">
								<tr>
									<td>{{ 'min_total' | i18n }}</td>
									<td>{{ 'discount_percent' | i18n }}</td>
									<td></td>
								</tr>
								<tr *ngFor="let p of item.discount_percent_per_total; let i = index;">
									<td>{{ p.min_total }}</td>
									<td>{{ p.discount }}</td>
									<td>
										<button mat-mini-fab color="warn" (click)="remove_discount_percent_per_total(p)"
											title="{{ 'delete' | i18n }}">
											<mat-icon>delete</mat-icon>
										</button>
									</td>
								</tr>
							</table>
						</div>
					</mat-card-content>
				</mat-card>

				<mat-form-field appearance="outline" class="form-group col-md-6" [hidden]="item.max_uses_per_user > 0">
					<mat-label>{{ 'max_uses' | i18n }}</mat-label>
					<input type="number" matInput [(ngModel)]="item.max_uses" [errorStateMatcher]='validate.max_uses'
						placeholder="{{ 'max_uses' | i18n }}" />
					<mat-error>{{ errors.max_uses }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6" [hidden]="item.max_uses > 0 || item.member_id">
					<mat-label>{{ 'max_uses_per_user' | i18n }}</mat-label>
					<input type="number" matInput [(ngModel)]="item.max_uses_per_user" [errorStateMatcher]='validate.max_uses_per_user'
						placeholder="{{ 'max_uses_per_user' | i18n }}" />
					<mat-error>{{ errors.max_uses_per_user }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'valid_until' | i18n }}</mat-label>
					<input matInput [min]="minDate" [matDatepicker]="picker" [(ngModel)]="item.valid_until"
						[errorStateMatcher]='validate.valid_until' placeholder="{{ 'valid_until' | i18n }}" />
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-datepicker #picker></mat-datepicker>
					<mat-error>{{ errors.valid_until }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'apply_on_discounted_products' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'apply_on_discounted_products' | i18n }}" [(ngModel)]='item.apply_on_discounted_products'
						[errorStateMatcher]='validate.apply_on_discounted_products' name="apply_on_discounted_products">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.apply_on_discounted_products }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'free_shipping' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'free_shipping' | i18n }}" [(ngModel)]='item.free_shipping'
						[errorStateMatcher]='validate.free_shipping' name="free_shipping">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.free_shipping }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'only_for_jm3eia' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'only_for_jm3eia' | i18n }}" [(ngModel)]='item.only_for_jm3eia'
						[errorStateMatcher]='validate.only_for_jm3eia' name="only_for_jm3eia">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.only_for_jm3eia }}</mat-error>
				</mat-form-field>

				<!-- <mat-form-field *ngIf="!item.only_for_jm3eia" appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'supplier' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'supplier' | i18n }}" [(ngModel)]="item.supplier_id"
						[errorStateMatcher]='validate.supplier_id' name="supplier_id">
						<mat-option *ngFor="let s of suppliers" [value]="s._id">
							{{s.name[config.lang]}}
						</mat-option>
					</mat-select>
				</mat-form-field> -->

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'suppliers' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'suppliers' | i18n }}" [(ngModel)]="item.suppliers" name="suppliers" (ngModelChange)="supplierChangeHander()" multiple>
					  <mat-option *ngFor="let c of suppliers" [value]="c._id">
						{{ c.name[config.lang] }}
					  </mat-option>
					</mat-select>
				  </mat-form-field>

				  <mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'categories' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'category' | i18n }}" [(ngModel)]="item.categories" name="categories" multiple>
					  <mat-option *ngFor="let c of categories" [value]="c._id">
						{{ c.name[config.lang] }}
					  </mat-option>
					</mat-select>
				  </mat-form-field>


				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
						[errorStateMatcher]='validate.status' name="status">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.status }}</mat-error>
				</mat-form-field>

			</div>
		</mat-card-content>
	</mat-card>

</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
