import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Setting, SettingError } from "./additional_settings.model";
import { AdditionalSettingService } from "./additional_settings.service";
import { i18n } from "../../@core/libraries/i18n";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MessageComponent } from "src/app/@theme/message/message.component";
import * as moment from "moment";
import * as ClassicEditor from "src/assets/vendors/ckeditor/ckeditor";
import { LanguagesService } from "src/app/@core/data/languages.service";
import { LanguagesObject } from "src/app/@core/data/languages-object";

@Component({
	selector: "app-additional_settings",
	templateUrl: "./additional_settings.component.html",
	styleUrls: ["./additional_settings.component.scss"],
})
export class AdditionalSettingComponent implements OnInit {
	model = "setting";
	isLoading = false;
	item: Setting = new Setting();
	errors: SettingError = new SettingError();
	validate: SettingError = new SettingError();
	order_minDate = new Date();
	public Editor = ClassicEditor;
	languages: any;
	min_delivery_times: Array<any> = [];
	days: Array<any> = [];
	times: Array<any> = [];

	constructor(
		protected theService: AdditionalSettingService,
		public http: HttpClient,
		public _snackBar: MatSnackBar,
		protected router: Router,
		private languagesService: LanguagesService
	) {
		this.languages = this.languagesService.get();
	}
	ngOnInit(): void {
		this.isLoading = true;
		const item = new Setting();
	}

	save() {
		const item = this.item;
		this.http
			.put(`${environment.apiUrl}/${this.model}/additional`, item)
			.subscribe(
				(response: any) => {
					if (response.errors) {
						return this.showMessage(i18n.unexpected_error);
					}
					return this.showMessage(i18n.saved_done);
				},
				(e) => {}
			);
	}

	showMessage(message) {
		this._snackBar.openFromComponent(MessageComponent, {
			duration: 5 * 1000,
			data: message,
		});
	}
}
