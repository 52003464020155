import { Component } from '@angular/core';
import { Option, OptionError, OptionItemError, OptionItem, OptionType } from '../option.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'option';
	item: Option = new Option();
	new_option: OptionItem = new OptionItem();
	validate_new_option: OptionItemError = new OptionItemError();
	errors: OptionError = new OptionError();
	validate: OptionError = new OptionError();
	option_type: typeof OptionType = OptionType;

	getEmptyErrors() {
		return new OptionError();
	}

	add_new_option(){
		if (this.item.options_list === undefined) {
			this.item.options_list = [];
		}
		this.item.options_list.push(Object.assign({}, this.new_option));
		this.new_option = new OptionItem();
		this.item.options_list = this.removeDuplicates(this.item.options_list, 'value');
	}

	updateO(value) {
		this.item.options_list = this.deleteFromObj(this.item.options_list, 'value', value);
	}

}
