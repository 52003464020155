import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { InvoiceService } from "./invoice.service";
import { HttpClient, HttpEventType, HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MessageComponent } from "src/app/@theme/message/message.component";
import { LanguagesService } from "src/app/@core/data/languages.service";
import { i18n } from "../../@core/libraries/i18n";
import * as QRCode from "qrcode";
import * as moment from "moment";

@Component({
	selector: "app-invoice",
	templateUrl: "./invoice.component.html",
	styleUrls: ["./invoice.component.scss"],
})
export class InvoiceComponent implements OnInit {
	model = "invoice";
	isLoading = false;
	orderNumber: string = "";
	languages: any;
	order: any;
	jm3eiaData: any = {};
	public readonly environment: Object = environment;
	logoUrl = environment.logoUrl;
	qrCodeDataUrl: String = "";
	outOfStockProducts: any = [];
	missingQuantityProducts: any = [];
	jm3eiaProducts: any = [];
	isPrinted: Boolean = false;

	constructor(
		protected theService: InvoiceService,
		public http: HttpClient,
		public _snackBar: MatSnackBar,
		protected router: Router,
		private languagesService: LanguagesService
	) {
		this.languages = this.languagesService.get();
	}

	ngOnInit(): void {}

	save() {
		this.http
			.get(`${environment.apiUrl}/order/${this.orderNumber}/order_number`)
			.subscribe(
				(response: any) => {
					if (response.errors) {
						return this.showMessage(i18n.unexpected_error);
					}
					this.order = response.results;
					const jm3eiaData = this.order.data.find(
						(d) => d.supplier._id === "Jm3eia"
					);
					if (jm3eiaData) this.jm3eiaData = jm3eiaData;
					this.jm3eiaProducts = this.jm3eiaData.products.filter(
						(p) => p.quantity > 0
					);
					this.missingQuantityProducts =
						this.jm3eiaData.products.filter((p) => p.quantity == 0);
					this.outOfStockProducts = this.jm3eiaData.products.filter(
						(p) => p.cart_quantity > p.quantity && p.quantity > 0
					);
					QRCode.toDataURL(this.orderNumber)
						.then((url) => {
							this.qrCodeDataUrl = url;
						})
						.catch((error) => {
							console.error("Error generating QR code:", error);
						});
				},
				(e) => {}
			);
	}

	print() {
		window.print();
		this.isPrinted = true;
	}

	showMessage(message) {
		this._snackBar.openFromComponent(MessageComponent, {
			duration: 5 * 1000,
			data: message,
		});
	}

	getFixedPrice(price) {
		return parseFloat(price || 0) ? parseFloat(price).toFixed(3) : "0.000";
	}

	getInvoiceDate() {
		const currentDate = new Date();

		const day = currentDate.getDate();
		const month = currentDate.getMonth() + 1;
		const year = currentDate.getFullYear();

		const formattedDate = `${month}/${day}/${year}`;
		return formattedDate;
	}

	getDeliveryTime(date) {
		const momentDate = moment.utc(date);
		const formattedDate = momentDate.format('D, YYYY - hh:mm A MMMM');
		return formattedDate;
	}
}
