<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>
	<mat-card>
		<mat-card-subtitle>{{ 'translated_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<mat-tab-group>
				<mat-tab *ngFor="let l of languages">
					<ng-template mat-tab-label>
						<i class="flag-icon flag-icon-{{ l.image }}"></i>
						{{ l.name }}
					</ng-template>
					<div class="col-12" dir="{{ l.direction }}">

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'title' | i18n }} ( {{ l.name }} )</mat-label>
							<input matInput [(ngModel)]="item.title[l.code]" [errorStateMatcher]='validate.title[l.code]'
								placeholder="{{ 'title' | i18n }}" />
							<mat-error>{{ errors.title[l.code] }}</mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'body' | i18n }} ( {{ l.name }} )</mat-label>
							<textarea matInput [(ngModel)]="item.body[l.code]" [errorStateMatcher]='validate?.body[l.code]'
								placeholder="{{ 'body' | i18n }}"></textarea>
							<mat-error>{{ errors.body[l.code] }}</mat-error>
						</mat-form-field>

					</div>
				</mat-tab>
			</mat-tab-group>
		</mat-card-content>
	</mat-card>
	<mat-divider></mat-divider>
	<mat-card>
		<mat-card-subtitle>{{ 'details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="col-12">

				<mat-form-field appearance="outline" class="form-group col-md-12">
					<mat-label>{{ 'url' | i18n }}</mat-label>
					<input type="url" matInput [(ngModel)]="item.url" [errorStateMatcher]='validate.url'
						placeholder="{{ 'url' | i18n }}" />
					<mat-error>{{ errors.url }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-12">
					<mat-label>{{ 'city' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'city' | i18n }}" [(ngModel)]="item.city_id"
						[errorStateMatcher]='validate.city_id' name="city_id">
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of cities" [value]="c._id">
							{{c.name[config.lang]}}
						</mat-option>
					</mat-select>
				</mat-form-field>

			</div>
		</mat-card-content>
	</mat-card>

</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
