import { LanguagesObject } from '../../@core/data/languages-object';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';

export class City implements ITable {

	_id: String = '';
	name: LanguagesObject = new LanguagesObject();
	country_id: String = '';
	parent_id: String = '';
	store_id: String = '';
	shipping_cost: Number = 0;
	preparation_time: Number = 30;
	enable_immediate_delivery: Boolean = false;
	sorting: Number = 0;
	city_number: Number = 0;
	status: Boolean = false;
	available_delivery_times: any =  [];
	enable_custom_delivery_times = false;

}

export class CityError implements IError {

	name: LanguagesObject = new LanguagesObject();
	country_id: String = '';
	parent_id: String = '';
	store_id: String = '';
	shipping_cost: String = '';
	preparation_time: String = '';
	enable_immediate_delivery: String = '';
	sorting: String = '';
	city_number: String = '';
	status: String = '';
	available_delivery_times: any =  [];

}
