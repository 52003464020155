<div>
	<mat-card>
		<mat-card-subtitle>{{ 'issues' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<mat-card>

				<mat-toolbar *ngIf="order && order.issues">
					<mat-toolbar-row>
						<button mat-raised-button color="warn" [disabled]="order.issues.solved" (click)="addForm()">
							<mat-icon>add_box</mat-icon>
							{{ 'add' | i18n }}
						</button>
						<button mat-raised-button color="link" [disabled]="order.issues.solved" (click)="closeIssue()">
							<mat-icon>{{ order.issues.solved ? 'lock' : 'lock_open' }}</mat-icon>
							{{ order.issues.solved ? 'closed' : 'close' | i18n }}
						</button>

						<a mat-raised-button color="primary" [routerLink]="'/order/' + order._id" title="{{ 'bill' | i18n }}">
							<mat-icon>format_list_numbered</mat-icon>
							{{ 'bill' | i18n }}
						</a>

						<span class="spacer"></span>
						<h2 class="title"> {{ 'issues' | i18n }} </h2>
					</mat-toolbar-row>
				</mat-toolbar>

			</mat-card>
			<mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
				<mat-progress-spinner color="primary" mode="indeterminate">
				</mat-progress-spinner>
			</mat-card>

			<mat-card *ngIf="createForm">
				<div class="form-row">

					<mat-form-field appearance="outline" class="form-group col-md-4">
						<mat-label>{{ 'employee_name' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.employee_name" placeholder="{{ 'employee_name' | i18n }}" />
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-4">
						<mat-label>{{ 'employee_no' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.employee_no" placeholder="{{ 'employee_no' | i18n }}" />
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-4">
						<mat-label>{{ 'customer_no' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.customer_no" placeholder="{{ 'customer_no' | i18n }}" />
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-4">
						<mat-label>{{ 'issue_type' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.issue_type" placeholder="{{ 'issue_type' | i18n }}" />
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-4">
						<mat-label>{{ 'solved' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'solved' | i18n }}" [(ngModel)]='item.solved'
							[errorStateMatcher]='validate.solved' name="solved">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.solved }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-12">
						<mat-label>{{ 'details' | i18n }}</mat-label>
						<textarea matInput [(ngModel)]="item.details" placeholder="{{ 'details' | i18n }}"></textarea>
					</mat-form-field>

					<div mat-dialog-actions>
						<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
						<button mat-flat-button color="warn" (click)="addForm()">{{ 'close' | i18n }}</button>
					</div>


				</div>

			</mat-card>
			<div *ngIf="order">
				<mat-card class="col-xs-8 offset-xs-2">
					<mat-card-content>
						<mat-divider></mat-divider>
						<div class="table-responsive">
							<table class="table table-bordered" *ngIf="order.issues && order.issues.history">
								<tr>
									<td>{{ 'employee_name' | i18n }}</td>
									<td>{{ 'employee_no' | i18n }}</td>
									<td>{{ 'customer_no' | i18n }}</td>
									<td>{{ 'issue_type' | i18n }}</td>
									<td>{{ 'details' | i18n }}</td>
									<td>{{ 'created' | i18n }}</td>
								</tr>
								<tr *ngFor="let i of order.issues.history">
									<td>{{ i.employee_name }}</td>
									<td>{{ i.employee_no }}</td>
									<td>{{ i.customer_no }}</td>
									<td>{{ i.issue_type }}</td>
									<td>{{ i.details }}</td>
									<td>{{ i.created }}</td>
								</tr>
							</table>
						</div>
					</mat-card-content>
				</mat-card>
			</div>
		</mat-card-content>
	</mat-card>
</div>
