import { Component } from '@angular/core';
import { Offer, OfferError } from '../offer.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Category } from '../../category/category.model';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'offer';
	item: Offer = new Offer();
	errors: OfferError = new OfferError();
	validate: OfferError = new OfferError();
	minDate: Date = new Date();
	currentTag: string = '';

	afterInit(): void {
		if (!this.item.products) this.item.products = [];
	}

	addTag() {
		if (this.currentTag.trim() !== '') {
		this.item.products.push(this.currentTag.trim());
		this.currentTag = '';
		}
	}

	removeTag(index: number) {
		this.item.products.splice(index, 1);
	}

	getEmptyErrors() {
		return new OfferError();
	}

}
