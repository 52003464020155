import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { FeatureRankService } from './feature_rank.service';
import { DetailsComponent } from './details/details.component';
import { FormComponent } from './form/form.component';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';

@Component({
	selector: 'app-feature_rank',
	templateUrl: './feature_rank.component.html',
	styleUrls: ['./feature_rank.component.scss']
})
export class FeatureRankComponent extends BaseIndex {

	model = 'feature_rank';
	displayedColumns: string[] = ['select', '_id', 'name', 'created', 'status', 'tools'];
	theDetailsComponent = DetailsComponent;
	theFormComponent = FormComponent;

	constructor(protected theService: FeatureRankService,
		protected router: Router, protected dialog: MatDialog) {
		super(theService, router, dialog);
	}

}
