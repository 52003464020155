<h1 mat-dialog-title>{{ 'body' | i18n }}</h1>
<div mat-dialog-content *ngIf="item">
	<mat-card>
		<mat-card-content>
			<table class="table table-bordered">
				<tbody>
					<tr>
						<td>#</td>
						<td>{{ item._id }}</td>
					</tr>
					<!-- <tr>
						<td>{{ 'title' | i18n }}</td>
						<td>{{ item.title[l.code] }}</td>
					</tr>
					<tr>
						<td>{{ 'body' | i18n }}</td>
						<td>{{ item.body[l.code] }}</td>
					</tr> -->
					<tr>
						<td>{{ 'opened_count' | i18n }}</td>
						<td>{{ item.opened_count || 0 }}</td>
					</tr>
					<tr>
						<td>{{ 'created' | i18n }}</td>
						<td>{{ item.created }}</td>
					</tr>
				</tbody>
			</table>
		</mat-card-content>
	</mat-card>
</div>
<div mat-dialog-actions>
	<button mat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
