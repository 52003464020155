import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ThemeModule } from './@core/modules/theme.module';
import { TokenInterceptor } from './@core/services/token.interceptor.service';

import { LanguagesService } from './@core/data/languages.service';

import { HomeComponent } from './pages/home/home.component';

import { BrandModule } from './pages/brand/brand.module';
import { CountryModule } from './pages/country/country.module';

import { NotFoundComponent } from './pages/not-found/not-found.component';

import { LoginComponent } from './pages/login/login.component';

import { AdminmemberModule } from './pages/adminmember/adminmember.module';
import { AppSettings } from './@core/services/app.settings.service';
import { BannerModule } from './pages/banner/banner.module';
import { BaseDeleteComponent } from './@core/theme/base-delete/base-delete.component';
import { BaseModelService } from './@core/services/base-model.service';
import { CategoryModule } from './pages/category/category.module';
import { SupplierCategoryModule } from './pages/supplier_category/supplier_category.module';
import { SupplierFeatureModule } from './pages/supplier_feature/supplier_feature.module';
import { CityModule } from './pages/city/city.module';
import { CouponModule } from './pages/coupon/coupon.module';
import { EmployeeModule } from './pages/employee/employee.module';
import { FaqModule } from './pages/faq/faq.module';
import { TickerModule } from './pages/ticker/ticker.module';
import { FeatureModule } from './pages/feature/feature.module';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material';
import { MemberModule } from './pages/member/member.module';
import { MemberNotificationModule } from './pages/member-notification/member-notification.module';
import { MessageComponent } from './@theme/message/message.component';
import { NotificationModule } from './pages/notification/notification.module';
import { OptionModule } from './pages/option/option.module';
import { OrderModule } from './pages/order/order.module';
import { PageModule } from './pages/page/page.module';
import { PasswordModule } from './pages/password/password.module';
import { ProductModule } from './pages/product/product.module';
import { RankModule } from './pages/rank/rank.module';
import { FeatureRankModule } from './pages/feature_rank/feature_rank.module';
import { OfferModule } from './pages/offer/offer.module';
import { RewardModule } from './pages/reward/reward.module';
import { SettingModule } from './pages/setting/setting.module';
import { AdditionalSettingModule } from './pages/additional_settings/additional_settings.module';
import { BulkWhatsappModule } from './pages/whatsapp/bulkmail.module';
import { InvoiceModule } from './pages/invoice/invoice.module';
import { LogModule } from './pages/log/log.module';
import { SlideModule } from './pages/slide/slide.module';
import { AppPopupModule } from './pages/app_popup/app_popup.module';
import { StatisticOrdersComponent } from './pages/home/statistic-orders/statistic-orders.component';
import { StatisticUsersComponent } from './pages/home/statistic-users/statistic-users.component';
import { StoreModule } from './pages/store/store.module';
import { SupplierModule } from './pages/supplier/supplier.module';
import { InventoryModule } from './pages/inventory/inventory.module';
import { ReviewModule } from './pages/review/review.module';
import { SupportModule } from './pages/support/support.module';
import { ReportComponent } from './pages/report/report.component';
import { WalletHistoryComponent } from './pages/report/wallet-history/wallet-history.component';
import { PointHistoryComponent } from './pages/report/point-history/point-history.component';
import { MemberHistoryComponent } from './pages/report/member-history/member-history.component';
import { GiveawayEntriesComponent } from './pages/offer/giveaway-entries/giveaway-entries.component';
import { RewardMembersComponent } from './pages/reward/members/reward-members.component';
import { RewardAllComponent } from './pages/reward/all/reward-all.component';

export function appInit(appConfigService: AppSettings) {
	return () => appConfigService.load();
}
@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		NotFoundComponent,
		LoginComponent,
		BaseDeleteComponent,
		MessageComponent,
		StatisticOrdersComponent,
		StatisticUsersComponent,
		ReportComponent,
		WalletHistoryComponent,
		PointHistoryComponent,
		MemberHistoryComponent,
		GiveawayEntriesComponent,
		RewardMembersComponent,
		RewardAllComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		ThemeModule.forRoot(),
		AdminmemberModule,
		BannerModule,
		BrandModule,
		CategoryModule,
		SupplierCategoryModule,
		SupplierFeatureModule,
		CityModule,
		CountryModule,
		CouponModule,
		EmployeeModule,
		FaqModule,
		TickerModule,
		FeatureModule,
		MemberModule,
		MemberNotificationModule,
		NotificationModule,
		OptionModule,
		OrderModule,
		PageModule,
		ProductModule,
		PasswordModule,
		RankModule,
		FeatureRankModule,
		OfferModule,
		RewardModule,
		SettingModule,
		AdditionalSettingModule,
		BulkWhatsappModule,
		InventoryModule,
		LogModule,
		StoreModule,
		SlideModule,
		AppPopupModule,
		SupplierModule,
		InventoryModule,
		ReviewModule,
		SupportModule,
		BrowserAnimationsModule,
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor,
			multi: true
		},
		AppSettings,
		{
			provide: APP_INITIALIZER,
			useFactory: appInit,
			multi: true,
			deps: [AppSettings],
		}, {
			provide: MatSnackBarRef,
			useValue: {}
		}, {
			provide: MAT_SNACK_BAR_DATA,
			useValue: {}
		},
		LanguagesService,
		BaseModelService
	],
	entryComponents: [BaseDeleteComponent, MessageComponent],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	bootstrap: [AppComponent]
})
export class AppModule { }
