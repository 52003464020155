import { Component } from '@angular/core';
import { Banner, BannerError } from '../banner.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { i18n } from '../../../@core/libraries/i18n';
import { Bannertype } from '../bannertype.model';
import { Bannertypes } from 'src/app/@core/data/bannertype.enum';
import { BannerPosition } from '../bannerposition.model';
import { BannerPositions } from 'src/app/@core/data/bannerposition.enum';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'banner';
	item: Banner = new Banner();
	errors: BannerError = new BannerError();
	validate: BannerError = new BannerError();
	bannertypes: Array<Bannertype>;
	positions: Array<any>;
	categories: Array<any>;
	features: Array<any>;
	_BannerTypes = Bannertypes;

	initialize() {
		this.bannertypes = [
			new Bannertype(Bannertypes.CODE, i18n.bannertype_code),
			new Bannertype(Bannertypes.IMAGE, i18n.bannertype_picture),
		];
		this.positions = [
			new BannerPosition(BannerPositions.MOBILE_TOP_OF_RANKS, BannerPositions.MOBILE_TOP_OF_RANKS),
			new BannerPosition(BannerPositions.WEB_TOP_OF_RANKS, BannerPositions.WEB_TOP_OF_RANKS),
		];
		this.loadCategories();
		this.loadFeatures();
	}

	loadCategories() {
		this.http.get(`${environment.apiUrl}/category`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '9999')
		})
			.subscribe((response) => {
				this.categories = response['results']['data'];
			});
	}

	loadFeatures() {
		this.http.get(`${environment.apiUrl}/feature`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '9999')
		})
			.subscribe((response) => {
				this.features = response['results']['data'];
			});
	}

	getEmptyErrors() {
		return new BannerError();
	}

}
