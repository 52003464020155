import { Component } from "@angular/core";
import { BaseForm } from "src/app/@core/libraries/pages/base-form";
import { Review, ReviewError } from "../review.model";
import { environment } from "src/environments/environment";
import { HttpParams } from "@angular/common/http";
import { Supplier } from "../../supplier/supplier.model";

@Component({
	selector: "app-form",
	templateUrl: "./form.component.html",
	styleUrls: ["./form.component.scss"],
})
export class FormComponent extends BaseForm {
	model = "review";
	item: Review = new Review();
	errors: ReviewError = new ReviewError();
	validate: ReviewError = new ReviewError();
	suppliers: Supplier[] = [];

	initialize() {
		this.loadSuppliers();
	}

	loadSuppliers() {
		this.http.get(`${environment.apiUrl}/supplier`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.suppliers = response['results']['data'];
			});
	}

	getEmptyErrors() {
		return new ReviewError();
	}
}
