export class Log {
	action: String;
	url: String;
	collection: String;
	user_id: String;
	document_id: String;
	old_data: any;
	new_data: any;
	created: String;
}

