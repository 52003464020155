import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { BulkMail, BulkMailError } from "./whatsapp.model";
import { BulkMailService } from "./whatsapp.service";
import { i18n } from "../../@core/libraries/i18n";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MessageComponent } from "src/app/@theme/message/message.component";
import * as moment from "moment";
import * as ClassicEditor from "src/assets/vendors/ckeditor/ckeditor";
import { LanguagesService } from "src/app/@core/data/languages.service";
import { LanguagesObject } from "src/app/@core/data/languages-object";

class MyUploadAdapter {
	url: string;
	loader: any;
	xhr: any;
	public http: HttpClient;
    constructor( loader, http ) {
        this.loader = loader;

        this.url = `${environment.apiUrl}/upload`;

		this.http = http;
    }

    upload() {
        return new Promise( ( resolve, reject ) => {
			this.loader.file.then(file => {
				const formData = new FormData();
			formData.append('picture', file);
			this.http.post(`${environment.apiUrl}/upload`, formData, { reportProgress: true, observe: 'events' })
				.subscribe((event: any) => {
					if (event instanceof HttpResponse) {
						resolve({ default: `${environment.mediaUrl}${event.body.results.path}` });
					}
				});
			})

        } );
    }
}

function MyCustomUploadAdapterPlugin( editor/* , http */ ) {
	editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
		return new MyUploadAdapter( loader, editor.config._config.customValues.http );
	};
}

@Component({
	selector: "app-whatsapp",
	templateUrl: "./whatsapp.component.html",
	styleUrls: ["./whatsapp.component.scss"],
})
export class BulkWhatsappComponent implements OnInit {
	model = 'whatsapp';
	item: BulkMail = new BulkMail();
	errors: BulkMailError = new BulkMailError();
	validate: BulkMailError = new BulkMailError();
	isLoading = false;
	languages: any;
	public Editor = ClassicEditor;
	public editorConfig = {
		customValues: { http: this.http },
		extraPlugins: [MyCustomUploadAdapterPlugin]
	}

	constructor(
		protected theService: BulkMailService,
		public http: HttpClient,
		public _snackBar: MatSnackBar,
		protected router: Router,
		private languagesService: LanguagesService
	) {
		this.languages = this.languagesService.get();
	}
	ngOnInit(): void {
		//this.isLoading = true;
		this.item = new BulkMail();
	}

	save() {
		const item = this.item;
		this.http
			.post(`${environment.apiUrl}/${this.model}`, item)
			.subscribe(
				(response: any) => {
					if (response.errors) {
						return this.showMessage(i18n.unexpected_error);
					}
					return this.showMessage(i18n.saved_done);
				},
				(e) => {}
			);
	}

	getEmptyErrors() {
		return new BulkMailError();
	}

	showMessage(message) {
		this._snackBar.openFromComponent(MessageComponent, {
			duration: 5 * 1000,
			data: message,
		});
	}
}
