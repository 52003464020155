<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>
	<mat-card>
		<mat-card-subtitle>{{ 'translated_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<mat-tab-group>
				<mat-tab *ngFor="let l of languages">
					<ng-template mat-tab-label>
						<i class="flag-icon flag-icon-{{ l.image }}"></i>
						{{ l.name }}
					</ng-template>
					<div class="col-12" dir="{{ l.direction }}">

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'title' | i18n }} ( {{ l.name }} )
							</mat-label>
							<input matInput [(ngModel)]="item.title[l.code]" [errorStateMatcher]='validate.title[l.code]'
								placeholder="{{ 'title' | i18n }}" />
							<mat-error>{{ errors.title[l.code] }}</mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'description' | i18n }} ( {{ l.name }} )
							</mat-label>
							<textarea matInput [(ngModel)]="item.description[l.code]" [errorStateMatcher]='validate?.description[l.code]'
								placeholder="{{ 'description' | i18n }}"></textarea>
							<mat-error>{{ errors.description[l.code] }}</mat-error>
						</mat-form-field>

					</div>
				</mat-tab>
			</mat-tab-group>
		</mat-card-content>
	</mat-card>
	<mat-card>
		<mat-card-subtitle>{{ 'Reward' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">
				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'old_points' | i18n }}</mat-label>
					<input matInput type="number" [(ngModel)]="item.old_points" [errorStateMatcher]='validate.old_points'
						placeholder="{{ 'old_points' | i18n }}" />
					<mat-error>{{ errors.old_points }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'points' | i18n }}</mat-label>
					<input matInput type="number" [(ngModel)]="item.points" [errorStateMatcher]='validate.points'
						placeholder="{{ 'points' | i18n }}" />
					<mat-error>{{ errors.points }}</mat-error>
				</mat-form-field>

				<mat-card class="col-12">
					<mat-card-subtitle>{{ 'product' | i18n }}</mat-card-subtitle>
					<mat-card-content>
						<mat-tab-group>
							<mat-tab *ngFor="let l of languages">
								<ng-template mat-tab-label>
									<i class="flag-icon flag-icon-{{ l.image }}"></i>
									{{ l.name }}
								</ng-template>
								<div class="col-12" dir="{{ l.direction }}">
			
									<mat-form-field appearance="outline" class="form-group col-md-6">
										<mat-label>{{ 'name' | i18n }} ( {{ l.name }} )
										</mat-label>
										<input matInput [(ngModel)]="item.product.name[l.code]" [errorStateMatcher]='validate.product.name[l.code]'
											placeholder="{{ 'name' | i18n }}" />
										<mat-error>{{ errors.product.name[l.code] }}</mat-error>
									</mat-form-field>
			
									<mat-form-field appearance="outline" class="form-group col-md-6 mat-upload">
										<mat-label>{{'picture' | i18n}} ( {{ l.name }} )</mat-label>
										<input type="file" (change)="upload($event.target.files, 'product.picture.' + l.code)" />
										{{'upload_percent' | i18n}}: {{percentDone}}%
										<img *ngIf="item.product.picture[l.code]" src="{{environment.mediaUrl}}{{item.product.picture[l.code]}}" width="48" />
										<input matInput hidden [(ngModel)]="item.product.picture[l.code]" [errorStateMatcher]='validate.product.picture[l.code]'>
										<br />
										<mat-error>{{ errors.product.picture[l.code] }}</mat-error>
									</mat-form-field>
			
								</div>
							</mat-tab>
						</mat-tab-group>
					</mat-card-content>
				</mat-card>

				<mat-form-field appearance="outline" class="form-group col-md-6 mt-5">
					<mat-label>{{ 'expires_at' | i18n }}</mat-label>
					<input matInput [min]="minDate" [matDatepicker]="picker" [(ngModel)]="item.expires_at"
						[errorStateMatcher]='validate.expires_at' placeholder="{{ 'expires_at' | i18n }}" />
					<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
					<mat-datepicker #picker></mat-datepicker>
					<mat-error>{{ errors.expires_at }}</mat-error>
				</mat-form-field>
				
				<mat-form-field appearance="outline" class="form-group col-md-6 mt-5">
					<mat-label>{{ 'sorting' | i18n }}</mat-label>
					<input matInput type="number" [(ngModel)]="item.sorting" [errorStateMatcher]='validate.sorting'
						placeholder="{{ 'sorting' | i18n }}" />
					<mat-error>{{ errors.sorting }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
						[errorStateMatcher]='validate.status' name="status">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.status }}</mat-error>
				</mat-form-field>

			</div>
		</mat-card-content>
	</mat-card>

</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
