import { Component } from '@angular/core';
import { Supplier, SupplierError } from '../supplier.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { City } from '../../city/city.model';
import * as moment from 'moment';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Inventory } from '../../inventory/inventory.model';
import { i18n } from '../../../@core/libraries/i18n';
import { LanguagesObject } from 'src/app/@core/data/languages-object';
import { SupplierCategory } from '../../supplier_category/supplier_category.model';
import { SupplierFeature } from '../../supplier_feature/supplier_feature.model';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {
	model = 'supplier';
	item: Supplier = new Supplier();
	errors: SupplierError = new SupplierError();
	validate: SupplierError = new SupplierError();
	min_delivery_times: Array<any> = [];

	days: Array<any> = [];
	times: Array<any> = [];
	cities: City[] = [];
	city = { city_id: "", shipping_cost: 0 };
	validate_city: { city_id: "", shipping_cost: "" };
	citiesObj: any[] = [];
	inventories: Inventory[] = [];
	supplier_categories: SupplierCategory[] = [];
	selectedCategory: string;
	features: Array<SupplierFeature> = [];
	suppliernfeature: Object = {
		'feature_id': '',
		'sorting': 0
	};
	validate_suppliernfeature: Object = {};
	featuresObj: any[] = [];


	initialize() {
		this.loadCities();
		this.loadInventories();
		this.loadSupplierCategories();
		this.loadFeatures();
		this.min_delivery_times = [];
		for (let i = 1; i <= 6; i++) {
			this.min_delivery_times.push(i * 30);
		}

		for (let i = 4; i <= 24; i++) {
			this.min_delivery_times.push(i * 60);
		}
	}

	afterInit() {
		/* if (this.item.inventory_id) {
			const inventory = this.inventories.find(i => i._id == this.item.inventory_id);
			if (inventory) {
				if (inventory.use_range_times) this.isTimeRange = true;
				else this.isTimeRange = false;
			}
		} */

		if (!this.item.description) {
			this.item.description = new LanguagesObject("", "");
		}
		this.item.password = '';
		this.days = moment.weekdays().map((i, idx) => {
			return {
				id: idx,
				text: i
			}
		});

		for (let i = 0; i < 24; i++) {
			this.times.push({
				id: i,
				text: `${i18n.from} ${i.toString().padStart(2, '0')} ${i18n.to} ${(i + 1).toString().padStart(2, '0')}`,
			});
		}

		if (!this.item.available_delivery_times || this.item.available_delivery_times.length < 1) {
			let available_times = [];
			for (const i of this.times) {
				available_times[i.id] = {
					id: i.id,
					is_available: false,
					max_orders: 0,
					is_enabled: true
				};
			}

			this.item.available_delivery_times = [];

			for (const i of this.days) {
				this.item.available_delivery_times[i.id] = available_times;
			}
		} else {
			if (this.item.available_delivery_times.filter(d => d).length < 7) {
				let available_times = [];
				for (const i of this.times) {
					available_times[i.id] = {
						id: i.id,
						is_available: false,
						max_orders: 0,
						is_enabled: true
					};
				}
				for (const i of this.days) {
					if (!this.item.available_delivery_times[i.id]) {
						this.item.available_delivery_times[i.id] = available_times;
					}
				}
			}
		}


		if (!this.item.working_times || this.item.working_times == undefined || this.item.working_times.length < 7) {
			this.item.working_times = [];
			for (const d of this.days) {
				this.item.working_times.push({
					week_day: d.id,
					from: 0,
					to: 23
				})
			}
		}

	}

	loadFeatures() {
		this.http.get(`${environment.apiUrl}/supplier_feature`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.featuresObj = [];
				this.features = response['results']['data'];
				for (const i of this.features) {
					this.featuresObj[i._id.toString()] = i.name[this.config.lang];
				}
			});
	}

	loadInventories() {
		this.http.get(`${environment.apiUrl}/inventory`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.inventories = response['results']['data'];
				/* for (const i of this.inventories) {
					this.inventoriesObj[i._id.toString()] = i.name[this.config.lang];
				} */
			});
	}

	loadSupplierCategories() {
		this.http.get(`${environment.apiUrl}/supplier_category`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.supplier_categories = response['results']['data'];
			});
	}

	loadCities() {
		this.http.get(`${environment.apiUrl}/city`, {
			params: new HttpParams()
				.set('filter', `status=true`)
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.cities = response['results']['data'];
				for (const i of this.cities) {
					this.citiesObj[i._id.toString()] = i.name[this.config.lang];
				}
			});
	}

	add_city() {
		if (this.item.cities === undefined) {
			this.item.cities = [];
		}
		if (!this.item.cities.find(c => c.city_id == this.city.city_id)) {
			this.item.cities.push(this.city);
		}
		this.city = { city_id: "", shipping_cost: 0 };
	}

		remove_city(city) {
			this.item.cities = this.item.cities.filter(c => c !== city);
		}

		add_all_cities() {
			this.item.cities = this.cities.map(c => ({ city_id: c._id, shipping_cost: 0 }));
		}

		remove_all_cities() {
			this.item.cities = [];
		}

	time_convert(num) {
		const hours = ('0' + Math.floor(num / 60)).slice(-2);
		const minutes = ('0' + num % 60).slice(-2);
		return `${hours}:${minutes}`;
	}

	/* handleInventoryChange() {
		const inventory = this.inventories.find(i => i._id == this.item.inventory_id);
		if (inventory) {
			if (inventory.use_range_times) this.isTimeRange = true;
			else this.isTimeRange = false;
		}
	} */

	getEmptyErrors() {
		return new SupplierError();
	}

	addCategory() {
		if (this.selectedCategory && !this.item.categories.includes(this.selectedCategory)) {
		  this.item.categories.push(this.selectedCategory);
		}
		this.selectedCategory = '';
	  }
	  
	removeCategory(categoryId: string) {
		const index = this.item.categories.indexOf(categoryId);
		if (index !== -1) {
		  this.item.categories.splice(index, 1);
		}
	  }
	  
	getCategoryNameById(categoryId: string): string {
		const category = this.supplier_categories.find(c => c._id === categoryId);
		return category ? category.name[this.config.lang] : '';
	  }

	  add_suppliernfeature() {
		if (this.item.features === undefined) {
			this.item.features = [];
		}
		this.item.features.push(Object.assign({}, this.suppliernfeature));
		this.suppliernfeature = {};
		this.item.features = this.removeDuplicates(this.item.features, 'feature_id');
	}

	updateSnF(feature_id) {
		this.item.features = this.deleteFromObj(this.item.features, 'feature_id', feature_id);
	}

}
