import { LanguagesObject } from '../../@core/data/languages-object';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';

export class Feature implements ITable {

	_id: String = '';
	name: LanguagesObject = new LanguagesObject();
	sorting: Number = 0;
	supplier_id: String = '';
	show_in_vip: Boolean = false;
	only_vip: Boolean = false;
	expiration_date: Date = new Date();
	expiration_date_message: LanguagesObject = new LanguagesObject();
	parent_id: String = '';
	status: Boolean = false;

}

export class FeatureError implements IError {

	name: LanguagesObject = new LanguagesObject();
	sorting: String = '';
	supplier_id: String = '';
	show_in_vip: String = '';
	only_vip: String = '';
	expiration_date = '';
	expiration_date_message: LanguagesObject = new LanguagesObject();
	parent_id: String = '';
	status: String = '';

}
