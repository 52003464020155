import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { App_popupService } from './app_popup.service';
import { DetailsComponent } from './details/details.component';
import { FormComponent } from './form/form.component';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';

@Component({
	selector: 'app-app_popup',
	templateUrl: './app_popup.component.html',
	styleUrls: ['./app_popup.component.scss']
})
export class AppPopupComponent extends BaseIndex {

	model = 'app_popup';
	displayedColumns: string[] = ['select', '_id', 'title', 'created', 'status', 'tools'];
	theDetailsComponent = DetailsComponent;
	theFormComponent = FormComponent;

	constructor(protected theService: App_popupService,
		protected router: Router, protected dialog: MatDialog) {
		super(theService, router, dialog);
	}

}
