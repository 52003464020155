²²²<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>
	<mat-card>
		<mat-card-subtitle>{{ 'translated_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<mat-tab-group>
				<mat-tab *ngFor="let l of languages">
					<ng-template mat-tab-label>
						<i class="flag-icon flag-icon-{{l.image}}"></i>
						{{l.name}}
					</ng-template>

					<div class="col-12" dir="{{l.direction}}">

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'name' | i18n }} ( {{ l.name }} )</mat-label>
							<input matInput [(ngModel)]="item.name[l.code]" [errorStateMatcher]='validate.name[l.code]'
								placeholder="{{ 'name' | i18n }}" />
							<mat-error>{{ errors.name[l.code] }}</mat-error>
						</mat-form-field>

						<!-- <mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'alternative_name' | i18n }} ( {{ l.name }} )</mat-label>
							<input matInput [(ngModel)]="item.alt_name[l.code]" [errorStateMatcher]='validate.alt_name[l.code]'
								placeholder="{{ 'alternative_name' | i18n }}" />
							<mat-error>{{ errors.alt_name[l.code] }}</mat-error>
						</mat-form-field> -->

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'description' | i18n }} ( {{ l.name }} )
							</mat-label>
							<textarea matInput [(ngModel)]="item.description[l.code]" [errorStateMatcher]='validate?.description[l.code]'
								placeholder="{{ 'description' | i18n }}"></textarea>
							<mat-error>{{ errors.description[l.code] }}</mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'delivery_time_text' | i18n }} ( {{ l.name }} )</mat-label>
							<input matInput [(ngModel)]="item.delivery_time_text[l.code]" [errorStateMatcher]='validate.delivery_time_text[l.code]'
								placeholder="{{ 'delivery_time_text' | i18n }}" />
							<mat-error>{{ errors.delivery_time_text[l.code] }}</mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-group col-md-6 mat-upload">
							<mat-label>{{'picture' | i18n}} ( {{ l.name }} )</mat-label>
							<input type="file" (change)="upload($event.target.files, 'picture.' + l.code)" />
							{{'upload_percent' | i18n}}: {{percentDone}}%
							<img *ngIf="item.picture[l.code]" src="{{environment.mediaUrl}}{{item.picture[l.code]}}" width="48" />
							<input matInput hidden [(ngModel)]="item.picture[l.code]" [errorStateMatcher]='validate.picture[l.code]'>
							<br />
							<mat-error>{{ errors.picture[l.code] }}</mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-group col-md-6 mat-upload">
							<mat-label>{{'logo' | i18n}} ( {{ l.name }} )</mat-label>
							<input type="file" (change)="upload($event.target.files, 'logo.' + l.code)" />
							{{'upload_percent' | i18n}}: {{percentDone}}%
							<img *ngIf="item.logo[l.code]" src="{{environment.mediaUrl}}{{item.logo[l.code]}}" width="48" />
							<input matInput hidden [(ngModel)]="item.logo[l.code]" [errorStateMatcher]='validate.logo[l.code]'>
							<br />
							<mat-error>{{ errors.logo[l.code] }}</mat-error>
						</mat-form-field>

					</div>

				</mat-tab>
			</mat-tab-group>
		</mat-card-content>
	</mat-card>
	<mat-divider></mat-divider>

	<mat-card>
		<mat-card-subtitle>{{ 'other_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'delivery_time' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'delivery_time' | i18n }}" [(ngModel)]='item.delivery_time'
						[errorStateMatcher]='validate.delivery_time' name="delivery_time">
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let dt of min_delivery_times" [value]="dt">{{ time_convert(dt) }}
						</mat-option>
					</mat-select>
					<mat-error>{{ errors.delivery_time }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'min_order' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.min_order" [errorStateMatcher]='validate.min_order'
						placeholder="{{ 'min_order' | i18n }}" />
					<mat-error>{{ errors.min_order }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'app_delivery_time' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.app_delivery_time" [errorStateMatcher]='validate.app_delivery_time'
						placeholder="{{ 'app_delivery_time' | i18n }}" />
					<mat-error>{{ errors.app_delivery_time }}</mat-error>
				</mat-form-field>


				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'shipping_cost' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.shipping_cost" [errorStateMatcher]='validate.shipping_cost'
						placeholder="{{ 'shipping_cost' | i18n }}" />
					<mat-error>{{ errors.shipping_cost }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'allow_cod' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'allow_cod' | i18n }}" [(ngModel)]='item.allow_cod'
						[errorStateMatcher]='validate.allow_cod' name="allow_cod">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.allow_cod }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'sorting' | i18n }}</mat-label>
					<input matInput [(ngModel)]="item.sorting" [errorStateMatcher]='validate.sorting'
						placeholder="{{ 'sorting' | i18n }}" />
					<mat-error>{{ errors.sorting }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
						[errorStateMatcher]='validate.status' name="status">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.status }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>Is external supplier</mat-label>
					<mat-select placeholder="Is external supplier" [(ngModel)]='item.is_external'
						[errorStateMatcher]='validate.is_external' name="is_external">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.is_external }}</mat-error>
				</mat-form-field>

				<div *ngIf="item.is_external">
					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'inventory' | i18n }}</mat-label>
						<mat-select placeholder="{{ 'inventory' | i18n }}" [(ngModel)]="item.inventory_id"
							[errorStateMatcher]='validate.inventory_id' name="item.inventory_id" (selectionChange)="handleInventoryChange($event)">
							<mat-option *ngFor="let inventory of inventories" [value]="inventory._id">
								{{inventory.name[config.lang]}}
							</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{'is_busy' | i18n}}</mat-label>
						<mat-select placeholder="{{'is_busy' | i18n}}" [(ngModel)]='item.is_busy'
							[errorStateMatcher]='validate.is_busy' name="is_busy">
							<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
							<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
						</mat-select>
						<mat-error>{{ errors.is_busy }}</mat-error>
					</mat-form-field>

					<mat-form-field appearance="outline" class="form-group col-md-6">
						<mat-label>{{ 'home_sorting' | i18n }}</mat-label>
						<input matInput [(ngModel)]="item.home_sorting" [errorStateMatcher]='validate.home_sorting'
							placeholder="{{ 'home_sorting' | i18n }}" />
						<mat-error>{{ errors.home_sorting }}</mat-error>
					</mat-form-field>

					<mat-card *ngIf="item.is_external" class="col-md-12 my-3">
						<mat-card-subtitle>{{'cities' | i18n}}</mat-card-subtitle>
						<mat-card-content>
							<div class="row">
								<div class="col-12">
									<button mat-raised-button color="link" (click)="add_all_cities()">
										<mat-icon>check_box</mat-icon>
										{{ 'select_all' | i18n }}
									</button>
									<button mat-raised-button color="link" (click)="remove_all_cities()">
										<mat-icon>check_box_outline_blank</mat-icon>
										{{ 'deselect_all' | i18n }}
									</button>
								</div>
								<mat-form-field appearance="outline" class="form-group col-md-6">
									<mat-label>{{ 'city' | i18n }}</mat-label>
									<mat-select placeholder="{{ 'city' | i18n }}" [(ngModel)]="city.city_id" name="city_id">
										<mat-option *ngFor="let c of cities" [value]="c._id">
											{{c.name[config.lang]}}
										</mat-option>
									</mat-select>
								</mat-form-field>

								<mat-form-field appearance="outline" class="form-group col-md-6">
									<mat-label>{{ 'shipping_cost' | i18n }}</mat-label>
									<input matInput [(ngModel)]="city.shipping_cost"
										   placeholder="{{ 'shipping_cost' | i18n }}" />
								  </mat-form-field>

								<div appearance="outline" class="form-group col-md-6">
									<button mat-raised-button color="primary"
										[disabled]="!city"
										(click)="add_city()"
										>
										<mat-icon>add</mat-icon>
										{{ 'add' | i18n }}
									</button>
								</div>

							</div>
							<div class="form-row" *ngIf="item.cities" style="max-height: 300px; overflow-y: scroll;">
								<table class="table table-bordered">
									<tr>
										<td>{{ 'city' | i18n }}</td>
									</tr>
									<tr *ngFor="let p of item.cities; let i = index;">
										<td>{{ citiesObj[p.city_id] }}</td>
										<td>{{ p.shipping_cost }}</td>
										<td>
											<button mat-mini-fab color="warn" (click)="remove_city(p)"
												title="{{ 'delete' | i18n }}">
												<mat-icon>delete</mat-icon>
											</button>
										</td>
									</tr>
								</table>
							</div>
						</mat-card-content>
					</mat-card>

					<div class="col-md-12 row" *ngIf="item.is_external">
						<mat-form-field appearance="outline" class="form-group col-md-6">
							<mat-label>{{ 'email' | i18n }}</mat-label>
							<input matInput [(ngModel)]="item.email" [errorStateMatcher]='validate.email'
								placeholder="{{ 'email' | i18n }}" />
							<mat-error>{{ errors.email }}</mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-group col-md-6">
							<mat-label>{{ 'password' | i18n }}</mat-label>
							<input matInput [(ngModel)]="item.password" type="password" [errorStateMatcher]='validate.password'
								placeholder="{{ 'password' | i18n }}" />
							<mat-error>{{ errors.password }}</mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-group col-md-6">
							<mat-label>{{ 'has_picking_time' | i18n }}</mat-label>
							<mat-select placeholder="{{ 'has_picking_time' | i18n }}" [(ngModel)]='item.has_picking_time'
								[errorStateMatcher]='validate.has_picking_time' name="has_picking_time">
								<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
								<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
							</mat-select>
							<mat-error>{{ errors.has_picking_time }}</mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-group col-md-6">
							<mat-label>{{ 'mobile' | i18n }}</mat-label>
							<input matInput [(ngModel)]="item.mobile" [errorStateMatcher]='validate.mobile'
								placeholder="{{ 'mobile' | i18n }}" />
							<mat-error>{{ errors.mobile }}</mat-error>
						</mat-form-field>

						<mat-form-field appearance="outline" class="form-group col-md-6">
							<mat-label>{{ 'manager_mobile' | i18n }}</mat-label>
							<input matInput [(ngModel)]="item.manager_mobile" [errorStateMatcher]='validate.manager_mobile'
								placeholder="{{ 'manager_mobile' | i18n }}" />
							<mat-error>{{ errors.manager_mobile }}</mat-error>
						</mat-form-field>

						<!-- <mat-form-field appearance="outline" class="form-group col-md-6">
							<mat-label>{{ 'has_options' | i18n }}</mat-label>
							<mat-select placeholder="{{ 'has_options' | i18n }}" [(ngModel)]='item.has_options'
								[errorStateMatcher]='validate.has_options' name="has_options">
								<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
								<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
							</mat-select>
							<mat-error>{{ errors.has_options }}</mat-error>
						</mat-form-field>

						<mat-form-field *ngIf="item.has_options" appearance="outline" class="form-group col-md-6">
							<mat-label>{{ 'display_layout' | i18n }}</mat-label>
							<mat-select placeholder="{{ 'display_layout' | i18n }}" [(ngModel)]='item.display_layout'
								[errorStateMatcher]='validate.display_layout' name="display_layout">
								<mat-option [value]="1">1</mat-option>
								<mat-option [value]="2">2</mat-option>
								<mat-option [value]="3">3</mat-option>
								<mat-option [value]="4">4</mat-option>
							</mat-select>
							<mat-error>{{ errors.display_layout }}</mat-error>
						</mat-form-field> -->

						<!-- <mat-form-field appearance="outline" class="form-group col-md-6">
							<mat-label>{{ 'categories' | i18n }}</mat-label>
							<mat-select placeholder="{{ 'feature' | i18n }}"
								[(ngModel)]="item.categories" name="categories">
								<mat-option *ngFor="let c of supplier_categories" [value]="c._id">
									{{ c.name[config.lang] }}
								</mat-option>
							</mat-select>
						</mat-form-field> -->

						<mat-form-field appearance="outline" class="form-group col-md-6">
							<mat-label>{{ 'categories' | i18n }}</mat-label>
							<mat-select placeholder="{{ 'feature' | i18n }}" [(ngModel)]="item.categories" name="categories" multiple>
							  <mat-option *ngFor="let c of supplier_categories" [value]="c._id">
								{{ c.name[config.lang] }}
							  </mat-option>
							</mat-select>
						  </mat-form-field>

						  <!-- <mat-form-field appearance="outline" class="form-group col-md-6">
								<mat-label>{{ 'feature' | i18n }}</mat-label>
								<mat-select placeholder="{{ 'feature' | i18n }}" [(ngModel)]="item.feature_id"
									[errorStateMatcher]='validate.feature_id'>
									<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
									<mat-option *ngFor="let c of features" [value]="c._id">
										{{ c.name[config.lang] }}
									</mat-option>
								</mat-select>
							</mat-form-field>

							<mat-form-field appearance="outline" class="form-group col-md-6">
								<mat-label>{{ 'feature_sorting' | i18n }}</mat-label>
								<input type="number" matInput [(ngModel)]="item.feature_sorting" [errorStateMatcher]='validate.feature_sorting'
									placeholder="{{ 'feature_sorting' | i18n }}" />
								<mat-error>{{ errors.feature_sorting }}</mat-error>
							</mat-form-field> -->

							<mat-card class="col-md-12">
								<mat-card-subtitle>{{ 'feature' | i18n }}
								</mat-card-subtitle>
								<mat-card-content>
									<div class="row">

										<mat-form-field appearance="outline" class="form-group col-md-6">
											<mat-label>{{ 'feature' | i18n }}</mat-label>
											<mat-select placeholder="{{ 'feature' | i18n }}"
												[(ngModel)]="suppliernfeature['feature_id']"
												[errorStateMatcher]='validate_suppliernfeature["feature_id"]' name="feature_id" (selectionChange)="loadFeatureRanksByCategory()">
												<mat-option *ngFor="let c of features" [value]="c._id">
													{{ c.name[config.lang] }}
												</mat-option>
											</mat-select>
										</mat-form-field>

										<mat-form-field appearance="outline" class="form-group col-md-6">
											<mat-label>{{ 'sorting' | i18n }}</mat-label>
											<input matInput [(ngModel)]="suppliernfeature['sorting']"
												[errorStateMatcher]='validate_suppliernfeature["sorting"]' name="sorting"
												placeholder="{{ 'sorting' | i18n }}" />
										</mat-form-field>

										<div class="form-group col-md-6">
											<button mat-raised-button color="primary"
												[disabled]="!suppliernfeature['feature_id'] || !suppliernfeature['sorting']"
												(click)="add_suppliernfeature()">
												<mat-icon>add</mat-icon>
												{{ 'add' | i18n }}
											</button>
										</div>
									</div>
									<div class="form-row" *ngIf="item.features && item.features.length > 0">
										<table class="table table-bordered">
											<tr>
												<td>{{ 'feature' | i18n }}</td>
												<td>{{ 'sorting' | i18n }}</td>
												<td></td>
											</tr>
											<tr *ngFor="let p of item.features; let i = index;">
												<td>{{ featuresObj[p['feature_id']] }}</td>
												<td>
													<mat-form-field appearance="outline" class="form-group col-md-12">
														<mat-label>{{ 'sorting' | i18n }}
														</mat-label>
														<input matInput [(ngModel)]="item.features[i]['sorting']"
															placeholder="{{ 'sorting' | i18n }}" />
													</mat-form-field>
												</td>
												<td>
													<button mat-mini-fab color="warn" (click)="updateSnF(p.feature_id)"
														title="{{ 'delete' | i18n }}">
														<mat-icon>delete</mat-icon>
													</button>
												</td>
											</tr>
										</table>
									</div>
								</mat-card-content>
							</mat-card>

							<mat-form-field appearance="outline" class="form-group col-md-6">
								<mat-label>{{ 'discount' | i18n }}</mat-label>
								<input matInput [(ngModel)]="item.discount" [errorStateMatcher]='validate.discount'
									placeholder="{{ 'discount' | i18n }}" />
								<mat-error>{{ errors.discount }}</mat-error>
							</mat-form-field>
						  

						<!-- <mat-form-field appearance="outline" class="form-group col-md-6">
							<mat-label>{{ 'categories' | i18n }}</mat-label>
							<mat-chip-list>
							  <mat-chip *ngFor="let categoryId of item.categories" [removable]="true" (removed)="removeCategory(categoryId)">
								{{ getCategoryNameById(categoryId) }}
								<mat-icon matChipRemove>cancel</mat-icon>
							  </mat-chip>
							</mat-chip-list>
							<mat-select placeholder="{{ 'categories' | i18n }}"
										[(ngModel)]="selectedCategory" (selectionChange)="addCategory()">
							  <mat-option *ngFor="let c of supplier_categories" [value]="c._id">
								{{ c.name[config.lang] }}
							  </mat-option>
							</mat-select>
						  </mat-form-field> -->
						  

						<!-- <mat-form-field *ngIf="isTimeRange" appearance="outline" class="form-group col-md-6">
							<mat-label>{{ 'pick_at' | i18n }}</mat-label>
							<mat-select placeholder="{{ 'pick_at' | i18n }}" [(ngModel)]='item.pick_at'
								[errorStateMatcher]='validate.pick_at' name="pick_at">
								<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
								<mat-option *ngFor="let t of timeOptions" [value]="t">{{ t }}
								</mat-option>
							</mat-select>
							<mat-error>{{ errors.pick_at }}</mat-error>
						</mat-form-field>
		
						<mat-form-field *ngIf="isTimeRange" appearance="outline" class="form-group col-md-6">
							<mat-label>{{ 'deliver_at' | i18n }}</mat-label>
							<mat-select placeholder="{{ 'deliver_at' | i18n }}" [(ngModel)]='item.deliver_at'
								[errorStateMatcher]='validate.deliver_at' name="deliver_at">
								<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
								<mat-option *ngFor="let t of timeOptions" [value]="t">{{ t }}
								</mat-option>
							</mat-select>
							<mat-error>{{ errors.deliver_at }}</mat-error>
						</mat-form-field> -->
					</div>

					<mat-card *ngIf="item.is_external" class="col-md-12">
						<mat-card-subtitle>{{'working_times' | i18n}}</mat-card-subtitle>
						<mat-card-content>
							<div *ngFor="let d of days">
								<div class="container">
									<div class="row">
										<div class="col-md-4"><strong>{{ d.text }}</strong></div>
										<div class="col-md-4">
											<mat-form-field  class="form-group" appearance="outline">
												<mat-label>{{ 'from' | i18n }} </mat-label>
												<input type="number" matInput [(ngModel)]="this.item.working_times[d.id]['from']" min="0" max="23" maxlength="2"
													placeholder="{{ 'from' | i18n }}" />
											</mat-form-field>
										</div>
										<div class="col-md-4">
											<mat-form-field  class="form-group" appearance="outline">
												<mat-label>{{ 'to' | i18n }} </mat-label>
												<input type="number" matInput [(ngModel)]="this.item.working_times[d.id]['to']" min="0" max="23" maxlength="2"
													placeholder="{{ 'to' | i18n }}" />
											</mat-form-field>
										</div>
									</div>
								</div>
							</div>
						</mat-card-content>
					</mat-card>

					<mat-accordion class="col-md-12" *ngFor="let d of days">
						<mat-expansion-panel>
							<mat-expansion-panel-header>
								<mat-panel-title>
									{{ d.text }}
								</mat-panel-title>
								<mat-panel-description>
								</mat-panel-description>
							</mat-expansion-panel-header>

							<div class="container">
								<div class="row">
									<div class="col-md-12" *ngFor="let p of times">
										<div class="row">
											<div class="col-md-3">
												<div appearance="outline" class="form-group">
													<mat-label>{{ 'enable' | i18n }} </mat-label>
													<mat-slide-toggle [(ngModel)]="item.available_delivery_times[d.id][p.id]['is_enabled']" [checked]="item.available_delivery_times[d.id][p.id]['is_enabled']"></mat-slide-toggle>
												</div>
											</div>
											<div class="col-md-3">
												{{ p.text }}
											</div>
											<div class="col-md-3">
												<mat-checkbox [(ngModel)]="item.available_delivery_times[d.id][p.id]['is_available']">
													{{ 'is_available' | i18n }}</mat-checkbox>
											</div>
											<div class="col-md-3">
												<mat-form-field appearance="outline" class="form-group">
													<mat-label>{{ 'max_orders' | i18n }}</mat-label>
													<input type="number" matInput
														[(ngModel)]="item.available_delivery_times[d.id][p.id]['max_orders']"
														placeholder="{{ 'max_orders' | i18n }}" />
												</mat-form-field>
											</div>
										</div>
									</div>
								</div>
							</div>


						</mat-expansion-panel>
					</mat-accordion>
				</div>
			</div>
		</mat-card-content>
	</mat-card>

</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
