import { LanguagesObject } from '../../@core/data/languages-object';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';

export class Faq implements ITable {

	_id: String = '';
	question: LanguagesObject = new LanguagesObject();
	answer: LanguagesObject = new LanguagesObject();
	sorting: Boolean = false;
	status: Boolean = false;

}

export class FaqError implements IError {

	question: LanguagesObject = new LanguagesObject();
	answer: LanguagesObject = new LanguagesObject();
	sorting: String = '';
	status: String = '';

}
