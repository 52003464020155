import { Component } from '@angular/core';
import { FeatureRank, FeatureRankError } from '../feature_rank.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Category } from '../../category/category.model';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'feature_rank';
	item: FeatureRank = new FeatureRank();
	errors: FeatureRankError = new FeatureRankError();
	validate: FeatureRankError = new FeatureRankError();
	features: Category[] = [];
	featuresObj: any[] = [];

	initialize() {
		this.loadCategories();
	}

	loadCategories() {
		this.http.get(`${environment.apiUrl}/feature`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.features = response['results']['data'];
				for (const i of this.features) {
					this.featuresObj[i._id.toString()] = i.name[this.config.lang];
				}
			});
	}

	getEmptyErrors() {
		return new FeatureRankError();
	}

}
