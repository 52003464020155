import { Component } from '@angular/core';
import { Brand, BrandError } from '../brand.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'brand';
	item: Brand = new Brand();
	errors: BrandError = new BrandError();
	validate: BrandError = new BrandError();

	getEmptyErrors() {
		return new BrandError();
	}

}
