<mat-card class="mat-elevation-z8">

	<mat-toolbar>
		<mat-toolbar-row>
			<button mat-raised-button color="link" (click)="setSearchForm()">
				<mat-icon>search</mat-icon>
				{{ 'search' | i18n }}
			</button>
			<span class="spacer"></span>
			<h2 class="title"> {{ 'issues' | i18n }} </h2>
		</mat-toolbar-row>
	</mat-toolbar>

	<mat-card *ngIf="searchForm">
		<div class="form-row">

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'store' | i18n }}</mat-label>
				<mat-select placeholder="{{ 'store' | i18n }}" [(ngModel)]="filter.store_id" (selectionChange)="get()">
					<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
					<mat-option *ngFor="let c of stores" [value]="c._id">
						{{c.name[config.lang]}}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'supplier' | i18n }}</mat-label>
				<mat-select placeholder="{{ 'supplier' | i18n }}" [(ngModel)]="filter.supplier_id"
					(selectionChange)="get()">
					<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
					<mat-option *ngFor="let c of suppliers" [value]="c._id">
						{{c.name[config.lang]}}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'fullname' | i18n }}</mat-label>
				<input matInput [(ngModel)]="filter.fullname" placeholder="{{ 'fullname' | i18n }}" (keyup)="get()" />
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'from_date' | i18n }}</mat-label>
				<input matInput [matDatepicker]="from_picker" [(ngModel)]="filter.from_date"
					placeholder="{{ 'from_date' | i18n }}" (keyup)="get()">
				<mat-datepicker-toggle matSuffix [for]="from_picker"></mat-datepicker-toggle>
				<mat-datepicker #from_picker></mat-datepicker>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'to_date' | i18n }}</mat-label>
				<input matInput [matDatepicker]="to_picker" [(ngModel)]="filter.to_date"
					placeholder="{{ 'to_date' | i18n }}" (keyup)="get()">
				<mat-datepicker-toggle matSuffix [for]="to_picker"></mat-datepicker-toggle>
				<mat-datepicker #to_picker></mat-datepicker>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'mobile' | i18n }}</mat-label>
				<input matInput [(ngModel)]="filter.mobile" placeholder="{{ 'mobile' | i18n }}" (keyup)="get()" />
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'customer_no' | i18n }}</mat-label>
				<input matInput [(ngModel)]="filter.customer_no" placeholder="{{ 'customer_no' | i18n }}" (keyup)="get()" />
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'employee_no' | i18n }}</mat-label>
				<input matInput [(ngModel)]="filter.employee_no" placeholder="{{ 'employee_no' | i18n }}" (keyup)="get()" />
			</mat-form-field>

		</div>
	</mat-card>

	<mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
		<mat-progress-spinner color="primary" mode="indeterminate">
		</mat-progress-spinner>
	</mat-card>

	<table mat-table [dataSource]="items">

		<!-- Checkbox Column -->
		<ng-container matColumnDef="select">
			<th mat-header-cell *matHeaderCellDef>
				<mat-checkbox (change)="$event ? masterToggle() : null;"
					[checked]="selection.hasValue() && isAllSelected()"
					[indeterminate]="selection.hasValue() && !isAllSelected()">
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row">
				<mat-checkbox (click)="$event.stopPropagation()"
					(change)="$event ? selection.toggle(row) : null;updateSelectionIds()"
					[checked]="selection.isSelected(row)">
				</mat-checkbox>
			</td>
		</ng-container>

		<!-- _id Column -->
		<ng-container matColumnDef="_id">
			<th mat-header-cell *matHeaderCellDef> # </th>
			<td mat-cell *matCellDef="let i"> <b [outerHTML]="i._id | _id"></b> </td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef> {{ 'name' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ i['user_data'] && i['user_data']['fullname'] }} </td>
		</ng-container>

		<!-- Delivery time Column -->
		<ng-container matColumnDef="solved">
			<th mat-header-cell *matHeaderCellDef> {{ 'solved' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ i.issues.solved }} </td>
		</ng-container>

		<!-- Created Column -->
		<ng-container matColumnDef="created">
			<th mat-header-cell *matHeaderCellDef> {{ 'created' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ i.issues && i.issues.history && i.issues.history[0] && i.issues.history[0]['created'] }} </td>
		</ng-container>

		<!-- Status Column -->
		<ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef> {{ 'status' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ ('order_status_list' | i18n)[i.status] }} </td>
		</ng-container>

		<!-- Tools Column -->
		<ng-container matColumnDef="tools">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let i">
				<div class="btn-tools">

					<a mat-mini-fab color="warn" [routerLink]="'/order/' + i._id + '/issues'"
						title="{{ 'details' | i18n }}">
						<mat-icon>info</mat-icon>
					</a>

					<a mat-mini-fab color="primary" [routerLink]="'/order/' + i._id"
						title="{{ 'bill' | i18n }}">
						<mat-icon>format_list_numbered</mat-icon>
					</a>

					<a href="javascript:void(0)" mat-mini-fab color="info" *ngIf="i.payment_method.id == 'knet'">
						<mat-icon class="icon">ballot</mat-icon>
					</a>

				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="status_{{row.status}}"></tr>
	</table>

	<mat-paginator showFirstLastButtons [length]="pagination.length" [pageSize]="pagination.pageSize"
		[pageSizeOptions]="pagination.pageSizeOptions" (page)="next($event)">
	</mat-paginator>

</mat-card>
