import { Component } from '@angular/core';
import { Feature, FeatureError } from '../feature.model';
import { BaseForm } from 'src/app/@core/libraries/pages/base-form';
import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';
import { Supplier } from '../../supplier/supplier.model';
import { LanguagesObject } from 'src/app/@core/data/languages-object';

@Component({
	selector: 'app-form',
	templateUrl: './form.component.html',
	styleUrls: ['./form.component.scss']
})
export class FormComponent extends BaseForm {

	model = 'feature';
	item: Feature = new Feature();
	errors: FeatureError = new FeatureError();
	validate: FeatureError = new FeatureError();
	suppliers: Supplier[] = [];
	minDate: Date = new Date();
	valid_until_time: { hours: Number, minutes: Number } = { hours: 0, minutes: 0 };
	enableExpirationDate: boolean = false;
	parents: Feature[] = [];

	initialize() {
		this.loadSuppliers();
		this.loadFeatures();
	}

	afterInit() {
		if (this.item.expiration_date) {
			this.valid_until_time.hours = new Date(this.item.expiration_date).getHours();
			this.valid_until_time.minutes = new Date(this.item.expiration_date).getMinutes();
			this.enableExpirationDate = true;
		}
	}

	loadSuppliers() {
		this.http.get(`${environment.apiUrl}/supplier`, {
			params: new HttpParams()
				.set('filter', 'status=true')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.suppliers = response['results']['data'];
			});
	}

	loadFeatures() {
		this.http.get(`${environment.apiUrl}/feature`, {
			params: new HttpParams()
				.set('filter', 'status=true&parent_id=null')
				.set('sort', `name.${this.config.lang}=asc`)
				.set('limit', '999')
		})
			.subscribe((response) => {
				this.parents = response['results']['data'];
			});
	}

	getEmptyErrors() {
		return new FeatureError();
	}

	setValidUntilTime() {
			this.valid_until_time.hours = new Date().getHours();
			this.valid_until_time.minutes = new Date().getMinutes();
			this.item.expiration_date.setHours(this.valid_until_time.hours as number, this.valid_until_time.minutes as number);
	}

	timeChangedHandeler() {
		this.item.expiration_date.setHours(this.valid_until_time.hours as number, this.valid_until_time.minutes as number);
	}


	resetExpirationDate() {
		if (this.enableExpirationDate) {
			this.item.expiration_date = this.item.expiration_date || new Date();
			this.valid_until_time = { hours: new Date(this.item.expiration_date).getHours(), minutes: new Date(this.item.expiration_date).getMinutes() };
		} else {
			this.item.expiration_date = null;
			this.valid_until_time = { hours: 0, minutes: 0 };
		}
	}

}
