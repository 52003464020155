import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Issue, IssueError } from './issue.model';
import { MatSnackBar } from '@angular/material';
import { MessageComponent } from 'src/app/@theme/message/message.component';
import { i18n } from 'src/app/@core/libraries/i18n';

@Component({
  selector: 'app-issue',
  templateUrl: './issue.component.html',
  styleUrls: ['./issue.component.scss']
})
export class IssueComponent implements OnInit {

  order: any;
  item: Issue = new Issue();
  errors: IssueError = new IssueError();
  validate: IssueError = new IssueError();
  isLoading: boolean;
  createForm: boolean = false;

  constructor(public http: HttpClient, private route: ActivatedRoute, public _snackBar: MatSnackBar, public router: Router) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.http.get(`${environment.apiUrl}/order/${this.route.snapshot.params['id']}`).subscribe((response: any) => {
      this.order = response.results;
      this.isLoading = false;
    });
  }

  addForm() {
    this.createForm = !this.createForm;
  }

  save() {
    this.item.order_id = this.route.snapshot.params['id'];
    this.http.post(`${environment.apiUrl}/order/issue`, this.item).subscribe((response: any) => {
      if (response['success']) {
        this.showMessage(response['results']['message']);
        this.addForm();
        this.item = new Issue();
        this.ngOnInit();
      } else {
        this.showMessage(i18n.all_fields_required);
      }
    });
  }

  closeIssue() {
		let user = localStorage.getItem('auth.user');
		try {
			user = user = user ? JSON.parse(user) : {};
		} catch (error) {
			this.router.navigate(['login']);
    }
    const id = this.route.snapshot.params['id'];
		const issue = {
			order_id: id,
			solved: true,
			employee_name: user['fullname'],
			issue_type: 'Close Issue',
			details: 'Issue has been closed',
		};
		this.http.post(`${environment.apiUrl}/order/issue`, issue).subscribe((response: any) => {
			this.showMessage(response['results']['message']);
			this.ngOnInit();
		});
   }

  showMessage(message) {
    this._snackBar.openFromComponent(MessageComponent, {
      duration: 5 * 1000,
      data: message
    });
  }

}
