import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseModelService } from 'src/app/@core/services/base-model.service';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root',
})


export class OfferService extends BaseModelService {

	model: String = 'offer';
	/* id: String;

	constructor(private http: HttpClient, private route: ActivatedRoute) {
		super(http, route);
		this.route.paramMap.subscribe(params => {
		  this.id = params.get('id');
		  console.log('id: ', this.id);
		});
	  } */

	public getGiveawayEntries(id: String, filter = {}, skip = 0, limit = 10): any {
		let params = new HttpParams()
			.set('skip', (skip + 1).toString())
			.set('limit', limit.toString());
		const keys = Object.keys(filter);
		for (const k of keys) {
			if (!filter[k]) {
				continue;
			}
			params = params.append(`filter[${k}]`, filter[k]);
		}
		return this.http.get(`${environment.apiUrl}/${this.model}/${id}/giveaway_entries`, {
			params: params
		});
	}

	public getRandomEntry(id: String): any {
		return this.http.get(`${environment.apiUrl}/${this.model}/${id}/random_entry`);
	}

	public makeWinner(id: String, entry_id: String): any {
		return this.http.post(`${environment.apiUrl}/${this.model}/${id}/make_winner`, { entry_id });
	}

}
