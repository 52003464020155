import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppComponent } from '../../app.component';
import { LoginService } from './login.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	item = {
		'email': '',
		'password': '',
	};
	errors = {
		message: null,
	};
	messages = {
		message: null,
	};

	environment = {
		logoUrl: environment.logoUrl
	}

	constructor(private parent: AppComponent, private service: LoginService, private router: Router) {
		parent.hasMainStyle = false;
	}

	ngOnInit() {
	}

	checkLogin() {
		this.service.checkLogin(this.item).subscribe((response: any) => {
			this.messages.message = null;
			this.errors.message = null;
			if (response.success === true) {
				localStorage.setItem('token', response.results.token);
				localStorage.setItem('auth.user', JSON.stringify(response.results.user));
				this.parent.user = response.results.user;
				this.parent.pages = this.parent.get_pages();
				this.router.navigate(['']);
			} else {
				this.errors.message = response.errors.error;
			}
		});
	}

}
