import { OnInit, Inject, Directive } from '@angular/core';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { MatDialogRef, MAT_DIALOG_DATA, ErrorStateMatcher } from '@angular/material';
import { FormControl } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { LanguagesService } from '../../data/languages.service';
import { IError } from '../interfaces/error';
import { ITable } from '../interfaces/table';
import { i18n } from '../i18n';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageComponent } from 'src/app/@theme/message/message.component';
import { getCircularReplacer, getCircularReplacerImproved } from '../helper';

@Directive()
export abstract class BaseForm implements OnInit {

	languages: any;
	id: string;
	httpOptions: any;
	model: String;
	item: ITable;
	errors: IError;
	validate: IError;
	public readonly environment: Object = environment;
	public readonly config = {
		lang: localStorage.getItem('lang') || 'en',
	};

	percentDone: Number = 0;
	uploadSuccess: boolean;

	constructor(
		private languagesService: LanguagesService,
		public http: HttpClient,
		public _snackBar: MatSnackBar,
		public dialogRef: MatDialogRef<BaseForm>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		this.id = data.id;
		this.languages = this.languagesService.get();
	}

	initialize() { }

	close(): void {
		this.dialogRef.close();
	}

	ngOnInit() {
		this.initialize();
		if (this.id) {
			this.http.get(`${environment.apiUrl}/${this.model}/${this.id}`).subscribe((response: any) => {
				this.item = <ITable>response.results;
				this.afterInit();
			});
		} else {
			this.afterInit();
		}
	}

	afterInit() { }

	getBeforeSave(): ITable { return this.item; }
	save() {
		const data = this.getBeforeSave().hasOwnProperty("variants") && this.getBeforeSave()['variants'].length > 0 ? JSON.parse(
			JSON.stringify(
				this.getBeforeSave(),
				getCircularReplacerImproved()
			)
		) : JSON.parse(
			JSON.stringify(
				this.getBeforeSave(),
				getCircularReplacer(
					this.getBeforeSave().hasOwnProperty("available_delivery_times")
				)
			)
		);
		delete data._id;
		if (this.data.action === 'edit') {
			this.http.put(`${environment.apiUrl}/${this.model}/${this.id}`, data).subscribe((response: any) => {
				this.clearErrors();
				if (response.errors) {
					this.showMessage(i18n.validation_errors);
					this.doValidation(response.errors);
				} else {
					this.showMessage(i18n.saved_done);
					this.close();
				}
			}, (e) => {
				this.showMessage(i18n.unexpected_error);
				this.close();
			});
		} else {
			this.http.post(`${environment.apiUrl}/${this.model}`, data).subscribe((response: any) => {
				this.clearErrors();
				if (response.errors) {
					this.showMessage(i18n.validation_errors);
					this.doValidation(response.errors);
				} else {
					this.showMessage(i18n.saved_done);
					this.close();
				}
			}, (e) => {
				this.showMessage(i18n.unexpected_error);
				this.close();
			});
		}
	}

	abstract getEmptyErrors();

	showMessage(message) {
		this._snackBar.openFromComponent(MessageComponent, {
			duration: 5 * 1000,
			data: message
		});
	}

	doValidation(response_error) {
		const error_keys = Object.keys(response_error);
		const error_obj = this.getEmptyErrors();
		if (error_keys.length > 0) {
			for (const k of error_keys) {
				if (typeof error_obj[k] === 'object') {
					if (!this.validate[k]) {
						this.validate[k] = {};
					}
					for (const lk of Object.keys(response_error[k])) {
						this.validate[k][lk] = <ErrorStateMatcher>{
							isErrorState: (control: FormControl) => {
								this.errors[k][lk] = response_error[k][lk];
								return true;
							}
						};
					}
				} else {
					this.validate[k] = <ErrorStateMatcher>{
						isErrorState: (control: FormControl) => {
							this.errors[k] = response_error[k];
							return true;
						}
					};
				}
			}
		}
	}

	clearErrors() {
		const keys = Object.keys(this.item);
		for (const k of keys) {
			this.validate[k] = <ErrorStateMatcher>{
				isErrorState: (control: FormControl) => {
					return false;
				}
			};
		}
		const errors = this.getEmptyErrors();
		this.errors = errors;
	}

	upload(files: File[], picture = 'picture') {
		// pick from one of the 4 styles of file uploads below
		this.uploadAndProgress(files, picture);
	}

	uploadAndProgress(files: File[], picture = 'picture') {
		this.uploadImagesAndProgress(files, picture).then((res: any) => {
			if (picture.includes('.')) {
				const arr = picture.split('.');
				if (!this.item[arr[0]]) {
					this.item[arr[0]] = {};
				}
				if (arr.length < 3) {
					this.item[arr[0]][arr[1]] = res.path;
				} else {
					console.log('ELSE: ', arr, this.item[arr[0]][arr[1]][arr[2]]);
					this.item[arr[0]][arr[1]][arr[2]] = res.path;
				}
			} else {
				this.item[picture] = res.path;
			}
		});
	}

	uploadImagesAndProgress(files: File[], elm: string) {
		return new Promise((resolve, reject) => {
			const formData = new FormData();
			Array.from(files).forEach(f => formData.append('picture', f));
			this.http.post(`${environment.apiUrl}/upload`, formData, { reportProgress: true, observe: 'events' })
				.subscribe((event: any) => {
					if (event.type === HttpEventType.UploadProgress) {
						this.percentDone = Math.round(100 * event.loaded / event.total);
					} else if (event instanceof HttpResponse) {
						this.uploadSuccess = true;
						resolve(event.body.results);
					}
				});
		});
	}

	removeDuplicates(myArr, prop) {
		return myArr.filter((obj, pos, arr) => {
			return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
		});
	}

	deleteFromObj(arr, prop, id) {
		return arr.filter(function (obj) {
			return obj[prop] !== id;
		});
	}
}
