<mat-card
	*ngIf="isLoading"
	style="display: flex; justify-content: center; align-items: center"
>
	<mat-progress-spinner color="primary" mode="indeterminate">
	</mat-progress-spinner>
</mat-card>
<mat-card *ngIf="!isLoading">
	<mat-card-title class="hide-print">{{ "Invoice" | i18n }}</mat-card-title>
	<mat-card-content>
		<mat-card class="hide-print">
			<mat-card-subtitle>{{ "Order number" | i18n }}</mat-card-subtitle>
			<mat-card-content>
				<div class="row">
					<mat-form-field
						appearance="outline"
						class="form-group col-md-12"
					>
						<mat-label>{{ "order_number" | i18n }}</mat-label>
						<input
							matInput
							[(ngModel)]="orderNumber"
							placeholder="{{ 'order_number' | i18n }}"
							(keyup.enter)="save()"
						/>
						<!-- <mat-error>{{ errors.email }}</mat-error> -->
					</mat-form-field>
					<!-- <div mat-dialog-actions class="col-md-4">
						<button mat-flat-button color="primary" class="btn-block" (click)="save()">{{ 'save' | i18n }}</button>
					</div> -->
				</div>
			</mat-card-content>
		</mat-card>
		<mat-divider></mat-divider>

		<mat-card
			*ngIf="order"
			dir="rtl"
			class="text-center invoice-container"
			style="width: 800px; margin: auto"
		>
			<mat-card-subtitle class="hide-print">{{
				"invoice" | i18n
			}}</mat-card-subtitle>
			<mat-card-content>
				<table class="haed">
					<tbody>
						<tr>
							<td class="text-center"><span>ﺭﻗﻢ ﺍﻟﻤﻨﻄﻘﺔ - No.Zone</span></td>
							<td class="text-center"><span>ﺭﻗﻢ ﺍﻟﻔﺎﺗﻮﺭﺓ - No.Invoice</span></td>
						</tr>
						<tr>
							<td class="text-center">
								<span>{{
									order.user_data.address.city
										? order.user_data.address.city
												.city_number
										: ""
								}}</span>
							</td>
							<td class="text-center">
								<span>{{ order.order_number }}</span>
							</td>
						</tr>
					</tbody>
				</table>

				<table class="no-border">
					<tbody>
						<tr>
							<td style="width: 50%; margin: 0;" class="text-center">
								<div class="text-center" style="padding: 0; margin: 0;">
									<img [src]="qrCodeDataUrl" alt="QR Code" width="150" />
								</div>
								<div class="text-center">
									<div>
										<span>ﻧﻮﻉ ﺍﻟﻄﻠﺐ - Order Type</span>
									</div>
									<div><span>Mart</span></div>
								</div>
							</td>
							<td style="width: 50%; margin: 0;" class="text-center">
								<div class="text-center" style="padding: 0; margin: 0;">
									<img
										src="{{ logoUrl }}"
										width="150"
										alt=""
									/>
								</div>
								<div class="text-center">
									<div><span>ﺟﻤﻌﻴﺔ ﺩﻭﺕ ﻛﻮﻡ</span></div>
									<div><span>Jm3eia.com</span></div>
								</div>
								<div>
									<span>{{ order.user_data.mobile }}</span>
								</div>
							</td>
						</tr>
					</tbody>
				</table>

				<table>
					<tbody>
						<tr>
							<td><span>ﻭﻗﺖ ﺍﻟﺘﻮﺻﻴﻞ - Delivery Time</span></td>
							<td><span>ﺗﺎﺭﻳﺦ ﺍﻟﻔﺎﺗﻮﺭﺓ - Invoice Date</span></td>
						</tr>
						<tr>
							<td>
								<span dir="ltr">{{
									getDeliveryTime(order.delivery_time)
								}}</span>
							</td>
							<td>
								<span dir="ltr">{{ getInvoiceDate() }}</span>
							</td>
						</tr>
					</tbody>
				</table>

				<table>
					<tbody>
						<tr>
							<td colspan="4"><span>ﺍﻻﺳﻢ</span></td>
							<td colspan="4">
								<span>{{ order.user_data.fullname }}</span>
							</td>
							<td colspan="4"><span>Name</span></td>
						</tr>
						<tr>
							<td colspan="3"><span>ﺍﻟﻤﻨﻄﻘﺔ</span></td>
							<td colspan="3">
								<span>{{
									order.user_data.address.city.name.ar
								}}</span>
							</td>
							<td colspan="3">
								<span>{{
									order.user_data.address.city.name.en
								}}</span>
							</td>
							<td colspan="3"><span>Area</span></td>
						</tr>
					</tbody>
				</table>

				<table>
					<tbody>
						<tr>
							<td colspan="2"><span>ﺍﻟﻘﻄﻌﺔ - block</span></td>
							<td colspan="2">
								<span>{{
									order.user_data.address.widget
								}}</span>
							</td>
							<td colspan="2"><span>ﻣﻨﺰﻝ - Home</span></td>
							<td colspan="2">
								<span>{{ order.user_data.address.house }}</span>
							</td>
						</tr>
						<tr>
							<td colspan="2"><span>ﺍﻟﺸﺎﺭﻉ - St</span></td>
							<td colspan="2">
								<span>{{
									order.user_data.address.street
								}}</span>
							</td>
							<td colspan="2"><span>ﺟﺎﺩﺓ - Gada</span></td>
							<td colspan="2">
								<span>{{ order.user_data.address.gada }}</span>
							</td>
						</tr>
						<tr>
							<td colspan="2"><span>ﺍﻟﺪﻭﺭ-floor</span></td>
							<td colspan="2">
								<span>{{ order.user_data.address.floor }}</span>
							</td>
							<td colspan="2"><span>ﺍﻟﺸﻘﺔ - Flat</span></td>
							<td colspan="2">
								<span>{{
									order.user_data.address.apartment_number
								}}</span>
							</td>
						</tr>
						<tr>
							<td colspan="2"><span>ﻣﻼﺣﻈﺎﺕ</span></td>
							<td colspan="6">
								<span>{{
									order.notes != "null" ? order.notes : ""
								}}</span>
							</td>
						</tr>
					</tbody>
				</table>

				<table
					*ngIf="
						jm3eiaData &&
						jm3eiaData.products &&
						jm3eiaData.products.length > 0
					"
					class="products-table"
				>
					<tbody>
						<tr>
							<td><span>#</span></td>
							<td><span>ﺍﻟﺼﻨﻒ</span></td>
							<td><span>ﺍﻟﺴﻌﺮ</span></td>
							<td><span>ﻛﻤﻴﺔ</span></td>
							<td><span>ﺍﺟﻤﺎﻟﻲ</span></td>
						</tr>
						<tr *ngFor="let p of jm3eiaProducts; let i = index">
							<td>
								<span>{{ i + 1 }}</span>
							</td>
							<td>
								<span>{{ p.name.ar }}</span>
							</td>
							<td>
								<span>{{ p.price }}</span>
							</td>
							<td>
								<span>{{ p.quantity }}</span>
							</td>
							<td>
								<span>{{
									getFixedPrice(p.price * p.quantity)
								}}</span>
							</td>
						</tr>
					</tbody>
				</table>

				<table class="prices-table">
					<tbody>
						<tr>
							<td colspan="2"><span>ﻋﺪﺩ</span></td>
							<td colspan="2">
								<span>{{ jm3eiaData.products.length }}</span>
							</td>
							<td colspan="4">
								<span>ﺍﺟﻤﺎﻟﻲ ﺍﻟﻤﺸﺘﺮﻳﺎﺕ - Total purchase</span>
							</td>
							<td colspan="2">
								<span>{{ getFixedPrice(order.subtotal) }}</span>
							</td>
						</tr>
						<tr>
							<td colspan="6">
								<span>ﺭﺳﻮﻡﺍﻟﺘﻮﺻﻴﻞ - Delivery Charge</span>
							</td>
							<td colspan="4">
								<span>{{
									getFixedPrice(order.shipping_cost)
								}}</span>
							</td>
						</tr>
						<tr>
							<td colspan="6">
								<span>ﺇﺟﻤﺎﻟﻲﺍﻟﻔﺎﺗﻮﺭﺓ - Total Invoice</span>
							</td>
							<td colspan="4">
								<span>{{ getFixedPrice(order.total) }}</span>
							</td>
						</tr>
						<tr>
							<td colspan="6"><span>خصم المحفظة - Wallet Discount</span></td>
							<td colspan="4">
								<span>{{
									getFixedPrice(discount_by_wallet_value)
								}}</span>
							</td>
						</tr>
						<tr>
							<td colspan="6"><span>خصم كوبون - Coupon Discount</span></td>
							<td colspan="4">
								<span>{{
									getFixedPrice(order.coupon.value)
								}}</span>
							</td>
						</tr>
						<tr>
							<td colspan="6"><span>ﺍﻟﻤﺪﻓﻮﻉ - knet</span></td>
							<td colspan="4">
								<span>{{
									getFixedPrice(order.payment_details.amt)
								}}</span>
							</td>
						</tr>
						<tr *ngIf="order.payment_details.amt > order.total">
							<td style="width: 50%;" colspan="6">
								<span
									>ﻣﺒﻠﻎ ﺗﻢ ﺭﺩﻩ ﺑﺎﻟﻤﺤﻔﻈﻪ - Amount refunded to
									wallet</span
								>
							</td>
							<td style="width: 25%;" colspan="2"><span>ﺩ.ﻙ - kwd</span></td>
							<td style="width: 25%;" colspan="2">
								<span>{{
									getFixedPrice(
										order.payment_details.amt - order.total
									)
								}}</span>
							</td>
						</tr>
						<tr *ngIf="(order.total - order.payment_details.amt) > 0">
							<td colspan="2"><span>*</span></td>
							<td colspan="4">
								<span>المبلغ المطلوب - Required Amount</span>
							</td>
							<td colspan="2"><span>ﺩ.ﻙ - kwd</span></td>
							<td colspan="2">
								<span>{{
									getFixedPrice(
										order.total - order.payment_details.amt
									)
								}}</span>
							</td>
						</tr>
					</tbody>
				</table>

				<hr style="border-width: 5px" />

				<table *ngIf="jm3eiaData && outOfStockProducts.length > 0" class="products-table">
					<tbody>
						<tr>
							<td colspan="2" style="width: 50%">
								<span>ﺍﺻﻨﺎﻑ ﻧﻔﺬﺕ ﻣﻦ ﺍﻟﻤﺨﺰﻭﻥ</span>
							</td>
							<td colspan="2" style="width: 50%">
								<span>Out of Stock Items</span>
							</td>
						</tr>
						<tr>
							<td><span>#</span></td>
							<td><span>ﺍﻟﺼﻨﻒ</span></td>
							<td><span>ﺍﻟﻜﻤﻴﺔ</span></td>
							<td><span>ﺍﻻﺟﻤﺎﻟﻲ</span></td>
						</tr>
						<tr *ngFor="let p of outOfStockProducts; let i = index">
							<td>
								<span>{{ i + 1 }}</span>
							</td>
							<td>
								<span>{{ p.name.ar }}</span>
							</td>
							<td>
								<span>{{ p.cart_quantity - p.quantity }}</span>
							</td>
							<td>
								<span>{{
									getFixedPrice(
										p.price * (p.cart_quantity - p.quantity)
									)
								}}</span>
							</td>
						</tr>
					</tbody>
				</table>

				<table *ngIf="jm3eiaData && missingQuantityProducts.length > 0" class="products-table">
					<tbody>
						<tr>
							<td colspan="2" style="width: 50%">
								<span>ﺇﺻﻨﺎﻑ ﻏﻴﺮ ﻣﺘﻮﻓﺮﺓ ﺑﺎﻟﻜﺎﻣﻞ</span>
							</td>
							<td colspan="2" style="width: 50%">
								<span>Missing Quantities</span>
							</td>
						</tr>
						<tr>
							<td><span>#</span></td>
							<td><span>ﺍﻟﺼﻨﻒ</span></td>
							<td><span>ﺍﻟﻜﻤﻴﺔ</span></td>
							<td><span>ﺍﻻﺟﻤﺎﻟﻲ</span></td>
						</tr>
						<tr
							*ngFor="
								let p of missingQuantityProducts;
								let i = index
							"
						>
							<td>
								<span>{{ i + 1 }}</span>
							</td>
							<td>
								<span>{{ p.name.ar }}</span>
							</td>
							<td>
								<span>{{ p.cart_quantity - p.quantity }}</span>
							</td>
							<td>
								<span>{{
									getFixedPrice(
										p.price * (p.cart_quantity - p.quantity)
									)
								}}</span>
							</td>
						</tr>
					</tbody>
				</table>

				<table class="haed">
					<tbody>
						<tr>
							<td>
								<span
									>* ﻟﻠﺈﺳﺘﻔﺴﺎﺭ ﻋﻦ ﺍﻟﻤﺤﻔﻈﺔ - To inquire about
									the wallet *</span
								>
							</td>
						</tr>
						<tr>
							<td>
								<span
									>* ﺧﺪﻣﺔ ﺍﻟﻌﻤﻼﺀ 22274222 Customer Service
									*</span
								>
							</td>
						</tr>
					</tbody>
				</table>

				<table class="no-border">
					<tbody>
						<tr>
							<td class="text-center"><span>ﺷﻜﺮﺍ ﻟﻜﻢ</span></td>
							<td class="text-center"><span>Thank You</span></td>
						</tr>
						<tr>
							<td class="text-center">
								<span>{{ order.payment_method.id }}</span>
							</td>
							<td class="text-center">
								<span>{{ order.driver_username }}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</mat-card-content>
			<mat-card-footer>
				<div mat-dialog-actions class="col-6 col-offset-3">
					<button
						mat-flat-button
						color="primary"
						class="btn-block"
						(click)="print()"
						[disabled]="isPrinted"
					>
						{{ "print" | i18n }}
					</button>
				</div>
				<br class="my-5" />
			</mat-card-footer>
		</mat-card>
		<mat-divider></mat-divider>
	</mat-card-content>
</mat-card>
