<mat-card class="mat-elevation-z8">

	<mat-toolbar>
		<mat-toolbar-row>
			<button mat-raised-button color="warn" (click)="openDelete()">
				<mat-icon>delete_box</mat-icon>
				{{ 'delete' | i18n }}
			</button>
			<button mat-raised-button color="link" (click)="selectAll()">
				<mat-icon>check_box</mat-icon>
				{{ 'select_all' | i18n }}
			</button>
			<button mat-raised-button color="link" (click)="deselectAll()">
				<mat-icon>check_box_outline_blank</mat-icon>
				{{ 'deselect_all' | i18n }}
			</button>
			<button mat-raised-button color="link" (click)="setSearchForm()">
				<mat-icon>search</mat-icon>
				{{ 'search' | i18n }}
			</button>
			<button mat-raised-button color="link" (click)="exportCSV()">
				<mat-icon>cloud_download</mat-icon>
				{{ 'export' | i18n }}
			</button>
			<!-- <a href="{{ today_csv }}" mat-raised-button color="link">
				<mat-icon>cloud_download</mat-icon>
				{{ 'export_today' | i18n }}
			</a> -->
			<span class="spacer"></span>
			<h2 class="title"> {{ 'orders' | i18n }} </h2>
		</mat-toolbar-row>
	</mat-toolbar>

	<mat-card *ngIf="searchForm">
		<div class="form-row">

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'store' | i18n }}</mat-label>
				<mat-select placeholder="{{ 'store' | i18n }}" [(ngModel)]="filter.store_id" (selectionChange)="get()">
					<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
					<mat-option *ngFor="let c of stores" [value]="c._id">
						{{ c.name[config.lang] }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'supplier' | i18n }}</mat-label>
				<mat-select placeholder="{{ 'supplier' | i18n }}" [(ngModel)]="filter.supplier_id" (selectionChange)="get()">
					<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
					<mat-option *ngFor="let c of suppliers" [value]="c._id">
						{{ c.name[config.lang] }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-select placeholder="{{ 'status' | i18n }}" (selectionChange)="get()" [(ngModel)]="filter.status">
					<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
					<mat-option *ngFor="let c of order_status_list" [value]="c.key">
						{{ c.value }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'product_id_or_sku' | i18n }}</mat-label>
				<input matInput [(ngModel)]="filter.product_id" placeholder="{{ 'product' | i18n }}" (keyup)="get()" />
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'fullname' | i18n }}</mat-label>
				<input matInput [(ngModel)]="filter.fullname" placeholder="{{ 'fullname' | i18n }}" (keyup)="get()" />
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'from_date' | i18n }}</mat-label>
				<input matInput [matDatepicker]="from_picker" [(ngModel)]="filter.from_date"
					placeholder="{{ 'from_date' | i18n }}" (keyup)="get()">
				<mat-datepicker-toggle matSuffix [for]="from_picker"></mat-datepicker-toggle>
				<mat-datepicker #from_picker></mat-datepicker>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'to_date' | i18n }}</mat-label>
				<input matInput [matDatepicker]="to_picker" [(ngModel)]="filter.to_date" placeholder="{{ 'to_date' | i18n }}"
					(keyup)="get()">
				<mat-datepicker-toggle matSuffix [for]="to_picker"></mat-datepicker-toggle>
				<mat-datepicker #to_picker></mat-datepicker>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'from_orders_total' | i18n }}</mat-label>
				<input matInput [(ngModel)]="filter.from_orders_total" placeholder="{{ 'from_orders_total' | i18n }}" (keyup)="get()" />
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'to_orders_total' | i18n }}</mat-label>
				<input matInput [(ngModel)]="filter.to_orders_total" placeholder="{{ 'to_orders_total' | i18n }}" (keyup)="get()" />
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'category_id' | i18n }}</mat-label>
				<input matInput [(ngModel)]="filter.category_id" placeholder="{{ 'category_id' | i18n }}" (keyup)="get()" />
			</mat-form-field>

			<!-- <mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'category' | i18n }}</mat-label>
				<mat-select placeholder="{{ 'category' | i18n }}" [(ngModel)]="filter.category_id" (selectionChange)="get()">
					<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
					<mat-option *ngFor="let c of categories" [value]="c._id">
						{{ c.name[config.lang] }}
					</mat-option>
				</mat-select>
			</mat-form-field> -->

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'mobile' | i18n }}</mat-label>
				<input matInput [(ngModel)]="filter.mobile" placeholder="{{ 'mobile' | i18n }}" (keyup)="get()" />
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'coupon' | i18n }}</mat-label>
				<input matInput [(ngModel)]="filter.coupon" placeholder="{{ 'coupon' | i18n }}" (keyup)="get()" />
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'discount_by_wallet' | i18n }}</mat-label>
				<mat-select placeholder="{{ 'discount_by_wallet' | i18n }}" [(ngModel)]='filter.discount_by_wallet'
					(selectionChange)="get()">
					<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
					<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'ref' | i18n }}</mat-label>
				<input matInput [(ngModel)]="filter.ref" placeholder="{{ 'ref' | i18n }}" (keyup)="get()" />
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-select placeholder="{{ 'payment_method' | i18n }}" (selectionChange)="get()" [(ngModel)]="filter.payment_method">
					<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
					<mat-option value="cod">COD</mat-option>
					<mat-option value="knet">Knet</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'has_offer' | i18n }}</mat-label>
				<mat-select placeholder="{{ 'has_offer' | i18n }}" [(ngModel)]='filter.has_offer'
					(selectionChange)="get()">
					<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
					<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
					<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
				</mat-select>
			</mat-form-field>

			<mat-form-field appearance="outline" class="form-group col-md-4">
				<mat-label>{{ 'offer_sku' | i18n }}</mat-label>
				<input matInput [(ngModel)]="filter.offer_sku" placeholder="{{ 'offer_sku' | i18n }}" (keyup)="get()" />
			</mat-form-field>

		</div>
	</mat-card>

	<mat-card *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center">
		<mat-progress-spinner color="primary" mode="indeterminate">
		</mat-progress-spinner>
	</mat-card>

	<table mat-table [dataSource]="items" matSort class="mat-elevation-z8" (matSortChange)="sortData($event)">

		<!-- Checkbox Column -->
		<ng-container matColumnDef="select">
			<th mat-header-cell *matHeaderCellDef>
				<mat-checkbox (change)="$event ? masterToggle() : null;" [checked]="selection.hasValue() && isAllSelected()"
					[indeterminate]="selection.hasValue() && !isAllSelected()">
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row">
				<mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null;updateSelectionIds()"
					[checked]="selection.isSelected(row)">
				</mat-checkbox>
			</td>
		</ng-container>

		<!-- _id Column -->
		<ng-container matColumnDef="_id">
			<th mat-header-cell *matHeaderCellDef> # </th>
			<td mat-cell *matCellDef="let i"> {{ i._id }} </td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="name"> {{ 'name' | i18n }}
			</th>
			<td mat-cell *matCellDef="let i">
				{{ i['user_data'] && i['user_data']['fullname'] }}
			</td>
		</ng-container>

		<!-- Cart total Column -->
		<ng-container matColumnDef="total">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="total"> {{ 'total' | i18n }}
			</th>
			<td mat-cell *matCellDef="let i"> {{ i.total }} </td>
		</ng-container>

		<!-- Delivery time Column -->
		<ng-container matColumnDef="delivery_time">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="delivery_time">
				{{ 'delivery_time' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ i.delivery_time }} </td>
		</ng-container>

		<!-- Created Column -->
		<ng-container matColumnDef="created">
			<th mat-header-cell *matHeaderCellDef mat-sort-header="created">
				{{ 'created' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ i.created }} </td>
		</ng-container>

		<!-- Status Column -->
		<ng-container matColumnDef="status">
			<th mat-header-cell *matHeaderCellDef> {{ 'status' | i18n }} </th>
			<td mat-cell *matCellDef="let i"> {{ ('order_status_list' | i18n)[i.status] }}
			</td>
		</ng-container>

		<!-- Tools Column -->
		<ng-container matColumnDef="tools">
			<th mat-header-cell *matHeaderCellDef></th>
			<td mat-cell *matCellDef="let i">
				<div class="btn-tools">

					<button mat-mini-fab color="primary" (click)="openDetails(i._id)" title="{{ 'details' | i18n }}">
						<mat-icon>info</mat-icon>
					</button>

					<button mat-mini-fab color="warn" (click)="openDelete(i._id)" title="{{ 'delete' | i18n }}">
						<mat-icon>delete</mat-icon>
					</button>

					<a href="javascript:void(0)" mat-mini-fab color="info" *ngIf="i.payment_method.id == 'knet'">
						<mat-icon class="icon">ballot</mat-icon>
					</a>

					<a href="javascript:void(0)" mat-mini-fab color="info" *ngIf="i.discount_by_wallet">
						<mat-icon class="icon">account_balance_wallet</mat-icon>
					</a>
					<a href="javascript:void(0)" mat-mini-fab color="info" *ngIf="i.payment_method.id == 'wallet'">
						<span class="font-weight-bold" style="font-size: 16px;">J</span>
					</a>
					<a href="javascript:void(0)" mat-mini-fab color="info" *ngIf="i.platform && i.platform != 'unknown'">
						<span class="font-weight-bold" style="font-size: 12px;">{{ i.platform }}</span>
					</a>
					<!-- <a mat-mini-fab [href]="'/log/order/' + i._id">
						<mat-icon>receipt</mat-icon>
					</a> -->

				</div>
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;" class="status_{{ row.status }}"></tr>
	</table>

	<mat-paginator showFirstLastButtons [length]="pagination.length" [pageSize]="pagination.pageSize" [pageSizeOptions]="pagination.pageSizeOptions"
		(page)="next($event)">
	</mat-paginator>

</mat-card>
