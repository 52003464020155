import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material";
import { ReviewService } from "./review.service";
import { DetailsComponent } from "./details/details.component";
import { FormComponent } from "./form/form.component";
import { BaseIndex } from "src/app/@core/libraries/pages/base-index";
import { SupplierFilter } from "./review.model";
import { Supplier } from "../supplier/supplier.model";
import { environment } from "src/environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";

@Component({
	selector: "app-review",
	templateUrl: "./review.component.html",
	styleUrls: ["./review.component.scss"],
})
export class ReviewComponent extends BaseIndex {
	model = "review";
	displayedColumns: string[] = [
		"select",
		"_id",
		"name",
		"rating",
		"comment",
		"created",
		"status",
		"tools",
	];
	theDetailsComponent = DetailsComponent;
	theFormComponent = FormComponent;

	searchForm: Boolean = false;
	filter: SupplierFilter = new SupplierFilter();
	suppliers: Supplier[] = [];

	constructor(
		protected theService: ReviewService,
		protected router: Router,
		public http: HttpClient,
		protected dialog: MatDialog
	) {
		super(theService, router, dialog);
		this.loadSuppliers();
	}


	setSearchForm() {
		this.searchForm = !this.searchForm;
	}

	loadSuppliers() {
		this.http
			.get(`${environment.apiUrl}/supplier`, {
				params: new HttpParams()
					.set("filter", "status=true")
					.set("sort", `name.${this.config.lang}=asc`)
					.set("limit", "999"),
			})
			.subscribe((response) => {
				this.suppliers = response["results"]["data"];
			});
	}
}
