import { LanguagesObject } from '../../@core/data/languages-object';
import { ITable } from 'src/app/@core/libraries/interfaces/table';
import { IError } from 'src/app/@core/libraries/interfaces/error';

export class FeatureRank implements ITable {

	_id: String = '';
	name: LanguagesObject = new LanguagesObject();
	feature_id: String = '';
	picture: String = '';
	sorting: Number = 0;
	status: Boolean = false;

}

export class FeatureRankError implements IError {

	name: LanguagesObject = new LanguagesObject();
	feature_id: String = '';
	picture: String = '';
	sorting: String = '';
	status: String = '';

}
