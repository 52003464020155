import { OnInit, Inject, Directive } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { environment } from '../../../../environments/environment';
import { LanguagesService } from '../../../@core/data/languages.service';

@Directive()
export class BaseDetails implements OnInit {

	model: String;

	languages: any;
	id: string;
	httpOptions: any;
	item: any;

	constructor(
		protected languagesService: LanguagesService,
		public http: HttpClient,
		public dialogRef: MatDialogRef<BaseDetails>,
		@Inject(MAT_DIALOG_DATA) public data: any) {
		this.id = data.id;
		this.languages = this.languagesService.get();
	}

	close(): void {
		this.dialogRef.close();
	}

	ngOnInit() {
		this.http.get(`${environment.apiUrl}/${this.model}/${this.id}`).subscribe((response: any) => {
			this.item = response.results;
			this.afterInit();
		});
	}

	afterInit() { }
}
