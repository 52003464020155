import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { OfferService } from './offer.service';
import { DetailsComponent } from './details/details.component';
import { FormComponent } from './form/form.component';
import { BaseIndex } from 'src/app/@core/libraries/pages/base-index';

@Component({
	selector: 'app-offer',
	templateUrl: './offer.component.html',
	styleUrls: ['./offer.component.scss']
})
export class OfferComponent extends BaseIndex {

	model = 'offer';
	displayedColumns: string[] = ['select', '_id', 'name', 'target_amount', 'created', 'status', 'tools'];
	theDetailsComponent = DetailsComponent;
	theFormComponent = FormComponent;

	constructor(protected theService: OfferService,
		protected router: Router, protected dialog: MatDialog) {
		super(theService, router, dialog);
	}

}
