<h1 mat-dialog-title>{{ data.action | i18n }}</h1>
<div mat-dialog-content>
	<mat-card>
		<mat-card-subtitle>{{ 'translated_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<mat-tab-group>
				<mat-tab *ngFor="let l of languages">
					<ng-template mat-tab-label>
						<i class="flag-icon flag-icon-{{ l.image }}"></i>
						{{ l.name }}
					</ng-template>

					<div class="col-12" dir="{{ l.direction }}">

						<mat-form-field appearance="outline" class="form-group col-md-12">
							<mat-label>{{ 'name' | i18n }} ( {{ l.name }} )</mat-label>
							<input matInput [(ngModel)]="item.name[l.code]" [errorStateMatcher]='validate.name[l.code]'
								placeholder="{{ 'name' | i18n }}" />
							<mat-error>{{ errors.name[l.code] }}</mat-error>
						</mat-form-field>

					</div>

				</mat-tab>
			</mat-tab-group>
		</mat-card-content>
	</mat-card>
	<mat-divider></mat-divider>

	<mat-card>
		<mat-card-subtitle>{{ 'other_details' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<div class="row">

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'country' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'country' | i18n }}"
						[(ngModel)]="item.country_id" [errorStateMatcher]='validate.country_id'>
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of countries" [value]="c._id">
							{{ c.name[config.lang] }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'parent_city_id' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'parent_city_id' | i18n }}"
						[(ngModel)]="item.parent_id" [errorStateMatcher]='validate.parent_id'>
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of parents" [value]="c._id">
							{{ c.name[config.lang] }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'store' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'store' | i18n }}" [(ngModel)]="item.store_id"
						[errorStateMatcher]='validate.store_id'>
						<mat-option>-- {{ 'remove' | i18n }} --</mat-option>
						<mat-option *ngFor="let c of stores" [value]="c._id">
							{{ c.name[config.lang] }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'shipping_cost' | i18n }}</mat-label>
					<input type="number" matInput [(ngModel)]="item.shipping_cost"
						[errorStateMatcher]='validate.shipping_cost'
						placeholder="{{ 'shipping_cost' | i18n }}" />
					<mat-icon matSuffix class="flag-icon flag-icon-{{ item.shipping_cost }}"></mat-icon>
					<mat-error>{{ errors.shipping_cost }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'preparation_time' | i18n }}</mat-label>
					<input type="number" matInput [(ngModel)]="item.preparation_time"
						[errorStateMatcher]='validate.preparation_time'
						placeholder="{{ 'preparation_time' | i18n }}" />
					<mat-error>{{ errors.preparation_time }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'enable_immediate_delivery' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'enable_immediate_delivery' | i18n }}"
						[(ngModel)]='item.enable_immediate_delivery'
						[errorStateMatcher]='validate.enable_immediate_delivery' name="enable_immediate_delivery">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.enable_immediate_delivery }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'sorting' | i18n }}</mat-label>
					<input type="number" matInput [(ngModel)]="item.sorting" [errorStateMatcher]='validate.sorting'
						placeholder="{{ 'sorting' | i18n }}" />
					<mat-error>{{ errors.sorting }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'city_number' | i18n }}</mat-label>
					<input type="number" matInput [(ngModel)]="item.city_number" [errorStateMatcher]='validate.city_number'
						placeholder="{{ 'city_number' | i18n }}" min="1" max="7" />
					<mat-error>{{ errors.city_number }}</mat-error>
				</mat-form-field>

				<mat-form-field appearance="outline" class="form-group col-md-6">
					<mat-label>{{ 'status' | i18n }}</mat-label>
					<mat-select placeholder="{{ 'status' | i18n }}" [(ngModel)]='item.status'
						[errorStateMatcher]='validate.status' name="status">
						<mat-option [value]="true">{{ 'yes' | i18n }}</mat-option>
						<mat-option [value]="false">{{ 'no' | i18n }}</mat-option>
					</mat-select>
					<mat-error>{{ errors.status }}</mat-error>
				</mat-form-field>

			</div>
		</mat-card-content>
	</mat-card>

	<mat-divider class="my-3"></mat-divider>

	<div appearance="outline" class="form-group">
		<mat-label>{{ 'enable_custom_delivery_times' | i18n }} </mat-label>
		<mat-slide-toggle [(ngModel)]="item.enable_custom_delivery_times" [checked]="item.enable_custom_delivery_times"></mat-slide-toggle>
	</div>
	<mat-card *ngIf="item.enable_custom_delivery_times">
		<mat-card-subtitle>{{ 'delivery_times' | i18n }}</mat-card-subtitle>
		<mat-card-content>
			<button mat-raised-button color="link" (click)="enableAllDeliveryTimes()">
				<mat-icon>check_box</mat-icon>
				{{ 'enable_all' | i18n }}
			</button>
			<button mat-raised-button color="link" (click)="disableAllDeliveryTimes()">
				<mat-icon>check_box_outline_blank</mat-icon>
				{{ 'disable_all' | i18n }}
			</button>

			<mat-accordion *ngFor="let d of days">
				<mat-expansion-panel>
					<mat-expansion-panel-header>
						<mat-panel-title>
							{{ d.text }}
						</mat-panel-title>
						<mat-panel-description>
						</mat-panel-description>
					</mat-expansion-panel-header>

					<div class="container">
						<div class="row">
							<div class="col-md-12" *ngFor="let p of times">
								<div class="row">
									<div class="col-md-3">
										<div appearance="outline" class="form-group">
											<mat-label>{{ 'enable' | i18n }} </mat-label>
											<mat-slide-toggle [(ngModel)]="item.available_delivery_times[d.id][p.id]['is_enabled']" [checked]="item.available_delivery_times[d.id][p.id]['is_enabled']"></mat-slide-toggle>
										</div>
									</div>
									<div class="col-md-3">
										{{ p.text }}
									</div>
									<div class="col-md-3">
										<mat-checkbox [(ngModel)]="item.available_delivery_times[d.id][p.id]['is_available']">
											{{ 'is_available' | i18n }}</mat-checkbox>
									</div>
									<div class="col-md-3">
										<mat-form-field appearance="outline" class="form-group">
											<mat-label>{{ 'max_orders' | i18n }}</mat-label>
											<input type="number" matInput
												[(ngModel)]="item.available_delivery_times[d.id][p.id]['max_orders']"
												placeholder="{{ 'max_orders' | i18n }}" />
										</mat-form-field>
									</div>
								</div>
							</div>
						</div>
					</div>


				</mat-expansion-panel>
			</mat-accordion>

		</mat-card-content>
	</mat-card>

</div>
<div mat-dialog-actions>
	<button mat-flat-button color="primary" (click)="save()">{{ 'save' | i18n }}</button>
	<button mat-flat-button color="warn" (click)="close()">{{ 'close' | i18n }}</button>
</div>
